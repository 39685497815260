import Api from 'utils/resourcesApi';
import pick from "lodash/pick";
import getConstantsByFeatureName from "utils/redux/getConstantsByFeatureName";
import getActionCreators from "utils/redux/getActionCreators";
import getActionHandlers from "utils/redux/getActionHandlers";
import {handleActions} from "redux-actions";
import {initialState} from "utils/redux/commonActionHandlers";
import {call, put, select, takeEvery} from "redux-saga/effects";

const camelize = text => text
  .replace(/\s(.)/g, $1 => $1.toUpperCase())
  .replace(/\s/g, '')
  .replace(/^(.)/, $1 => $1.toLowerCase());


const getSagas = (getDataFunction, constants, actions, featureName) => {
  function* fetchDictionary({payload} = {}) {
    try {
      if(!payload) return;
      const data = yield call(getDataFunction, {country_id: payload});

      yield put(actions.addItems(data));
    } catch (e) {
      yield put(actions.loadItemsError(e));
    }
  }

  function* displayError() {
    const name = camelize(featureName.toLowerCase());
    const error = yield select(state => state[name].error);
    // yield call(toast, { type: 'error', title: `Error loading ${name}`, description: error.message })
  }

  function getDictionarySagas() {
    return [
      takeEvery(constants.LOAD_ITEMS_ERROR, displayError),
      takeEvery(constants.LOAD_ITEMS_REQUEST, fetchDictionary),
    ];
  }

  return {
    fetchDictionary,
    getSagas: getDictionarySagas
  }
}


const getDictionaryReduxModule = (featureName, getDataFunction) => {
  const constants = pick(
    getConstantsByFeatureName(featureName),
    ['LOAD_ITEMS_REQUEST', 'ITEMS_LOADED_SUCCESSFULLY', 'LOAD_ITEMS_ERROR']
  );
  const actions = getActionCreators(constants);
  const actionHandlers = getActionHandlers(constants);
  const reducer = handleActions(actionHandlers, pick(initialState, ['data', 'didLoad', 'isLoading','error']));
  const sagas = getSagas(getDataFunction, constants, actions, featureName);

  return { constants, actions, reducer, sagas }
}


const { constants, actions, reducer, sagas } = getDictionaryReduxModule('CITIES', Api.cities.index);

export { constants, actions, reducer, sagas };
export default { constants, actions, reducer, sagas };

