import React from 'react';
import { Table, Popup, Icon } from 'semantic-ui-react'
import orderBy from 'lodash/orderBy';

import styled from 'styled-components';

import { formatSumUsd, formatSum } from 'utils/fieldsConversion';

import { color } from './utils';

const PercentRow = styled(Table.Row)`
  background: ${({ percent }) => `-webkit-linear-gradient(left, ${color(percent)} ${percent}%, white ${percent}%)`};
  background: ${({ percent }) => `-moz-linear-gradient(left, ${color(percent)} ${percent}%, white ${percent}%)`};
  background: ${({ percent }) => `-ms-linear-gradient(left, ${color(percent)} ${percent}%, white ${percent}%)`};
  background: ${({ percent }) => `linear-gradient(left, ${color(percent)} ${percent}%, white ${percent}%)`};
`

const Tooltip = ({sum, budgeted, percent}) => (
  <Table compact size="small">
    <Table.Body>
      <Table.Row>
        <Table.Cell>Budgeted:</Table.Cell>
        <Table.Cell textAlign="right">{ formatSumUsd(budgeted) }</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>{ sum < budgeted ? 'Underutilized budget' : 'Budget overrun' }:</Table.Cell>
        <Table.Cell textAlign="right">{ formatSumUsd(Math.abs( budgeted - sum )) }</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Budget execution:</Table.Cell>
        <Table.Cell textAlign="right">{ formatSum(percent) }%</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);


const DetailsTable = ({ item: { actual: { vessels: actualData }, budgeted } }) =>{

  const { keys, values } = Object;
  const vessels = keys(actualData);
  
  const data = vessels.reduce((acc, vessel) => [ ...acc, {
      vessel,
      sum: values(actualData[vessel]).reduce((sum, el) => sum + el, 0),
      budgeted: budgeted.vessels[vessel],
      percent: (values(actualData[vessel]).reduce((sum, el) => sum + el, 0) / budgeted.vessels[vessel]) * 100
    }], []);

  return (
    <Table compact celled size="small">
      <Table.Body>
        {
          orderBy(data, 'sum', 'desc').map(({vessel, sum, percent, budgeted}) => ( sum > 0 &&
            <PercentRow key={ vessel } percent={ percent / 2 } className="progress">
              <Table.Cell width="8">
                <Popup
                  content={ <Tooltip sum={ sum } budgeted={ budgeted } percent={ percent } /> }
                  trigger={<Icon name="info circle" />}
                />
                { vessel }
              </Table.Cell>
              <Table.Cell width="8" textAlign="right">
                { formatSumUsd(sum) }
              </Table.Cell>
            </PercentRow>
          ))
        }
      </Table.Body>
    </Table>

  )
};

export default DetailsTable;
