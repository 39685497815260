import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/delivery_notes';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-date' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (pagination, sort, search, filter, { global }) => api.read({ type }, {
    query: {
      sort: sortOptions(sort),
      filter: {
        ...filter,
        ...searchOptions(search),
        vessel_id: (global.dnCompanies || []).join(','),
        // period_filter: { ...paymentInstructions },
      },
      page: { size: pagination.itemsPerPage, number: pagination.activePage }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

  add_order: (id, orderId) => api.update({ id, type, action: 'add_order' }, {}, {orderId}),
  remove_order: (id, orderId) => api.update({ id, type, action: 'remove_order' }, {}, {orderId}),

}
