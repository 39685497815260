import * as api from '../api/adapters/json_api';

const type = 'api_resources/invoice_items';

export default {
  index: (filter) => api.read({ type }, { query: { filter } }),
  show: (id) => api.read({ type, id }),
  linkedPO: (id) => api.read({ type, id, action: 'purchase_order_items' }),
  unlinkPO: (id, override) => api.update({ type, id, action: 'unlink_po'}, override),
}
