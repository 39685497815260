import { handleActions, createAction } from 'redux-actions';

const resource = 'assignOwnerCompany'

export const constants = {
  LOAD_ITEMS_REQUEST: '@ASSIGN_OWNER_COMPANY/LOAD_ITEMS_REQUEST',
  SET_LOADING: '@ASSIGN_OWNER_COMPANY/SET_LOADING',
  SET_ERRORS: '@ASSIGN_OWNER_COMPANY/SET_ERRORS',
  CLEAR_ERRORS: '@ASSIGN_OWNER_COMPANY/CLEAR_ERRORS',
  SET_ITEMS_DATA: '@ASSIGN_OWNER_COMPANY/SET_ITEMS_DATA',
  LOAD_SELECTED_ITEMS_REQUEST: '@ASSIGN_OWNER_COMPANY/LOAD_SELECTED_ITEMS_REQUEST',
  SET_SELECTED_ITEMS: '@ASSIGN_OWNER_COMPANY/SET_SELECTED_ITEMS',
  SELECT_ITEM_REQUEST: '@ASSIGN_OWNER_COMPANY/SELECT_ITEM_REQUEST',
  DELETE_ITEM_REQUEST: '@ASSIGN_OWNER_COMPANY/DELETE_ITEM_REQUEST'
};

export const actions = {
  loadItemsRequest: createAction(constants.LOAD_ITEMS_REQUEST),
  setLoading: createAction(constants.SET_LOADING),
  setErrors: createAction(constants.SET_ERRORS),
  clearErrors: createAction(constants.CLEAR_ERRORS),
  setItemsData: createAction(constants.SET_ITEMS_DATA),
  loadSelectedItemsRequest: createAction(constants.LOAD_SELECTED_ITEMS_REQUEST),
  setSelectedItems: createAction(constants.SET_SELECTED_ITEMS),
  selectItemRequest: createAction(constants.SELECT_ITEM_REQUEST),
  deleteItemRequest: createAction(constants.DELETE_ITEM_REQUEST),
};

export const initialState = {
  data: [],
  selectedData: [],
  errors: [],
  loading: false,
}

export const actionHandlers = {
  [constants.SET_LOADING]: (state, action) => ({ ...state, loading: action.payload }),
  [constants.SET_ERRORS]: (state, action) => ({ ...state, errors: [ ...state.errors, action.payload ] }),
  [constants.CLEAR_ERRORS]: (state) => ({ ...state, errors: [] }),
  [constants.SET_ITEMS_DATA]: (state, action) => ({ ...state, data: action.payload }),
  [constants.SET_SELECTED_ITEMS]: (state, action) => ({ ...state, selectedData: action.payload }),

}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state[resource].data,
  selectedData: state[resource].selectedData,
  errors: state[resource].errors,
  loading: state[resource].loading,
});
