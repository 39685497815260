import * as api from '../api/adapters/json_api';


const type = 'api_resources/budget_items';

export default {
  index: (pagination, sort, id) => api.read({ type, id }, {
    query: {
      sort: 'item'
    }
  }),

}
