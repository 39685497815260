const goBackPatterns = [{
  pattern: /^\/invoices\/\w+/i,
  path: '/'
}];


/**
 * HOF for goBack
 * @param {object} history routes's history object
 * @param {string} pathname current path
 * @return {function(): undefined} function, which calls goBack
 */
export const createGoBack = (history, pathname) => () => goBack(history, pathname);

/**
 * Calls history's methods to go back
 * @param {object} history routes's history object
 * @param {string} pathname current path
 *
 * @return {undefined}
 */
export const goBack = ({ action, goBack, push }, pathname) => {
  push(getRealBackPath(pathname));
};

/**
 * Returns the real back path based on the passed pathname argument.
 *
 * @param {string} pathname - The pathname to test.
 * @returns {string} - Returns the real back path.
 */
const getRealBackPath = (pathname) => {
  const { oneLevel, twoLevels, threeLevels } = testPath();
  if (oneLevel(pathname)) return getPathFromPathnameWithLevels(pathname, 1);
  if (twoLevels(pathname)) return getPathFromPathnameWithLevels(pathname, 2);
  if (threeLevels(pathname)) return getPathFromPathnameWithLevels(pathname, 3);
}

/**
 * Returns 3 test functions, that help to determine how many levels deep the path is.
 *
 * @returns {Object} - An object containing 3 test functions: oneLevel, twoLevels, and threeLevels.
 */
const testPath = () => ({
  /**
   * Tests if a path is one level deep.
   *
   * @param {string} path - The path to test.
   * @returns {Boolean} - Returns true if the path is one level deep, false otherwise.
   */
  oneLevel(path) { return /^\/\w+$/.test(path) },
  /**
   * Tests if a path is two levels deep.
   *
   * @param {string} path - The path to test.
   * @returns {Boolean} - Returns true if the path is two levels deep, false otherwise.
   */
  twoLevels(path) { return /^\/\w+\/(\w+|\d+)$/.test(path) },
  /**
   * Tests if a path is three levels deep.
   *
   * @param {string} path - The path to test.
   * @returns {Boolean} - Returns true if the path is three levels deep, false otherwise.
   */
  threeLevels(path) { return /^\/\w+\/(copy|pay|\d+)\/(edit|pay|\d+)$/.test(path) },
})

/**
 * This function returns the path extracted from the pathname with the number of levels.
 *
 * @param {string} pathname - The pathname to test.
 * @param {number} levels - The number of levels expected in the pathname.
 * @returns {string} - Returns the extracted path.
 */
const getPathFromPathnameWithLevels = (pathname, levels) => {
  const paths = pathname.split('/');
  if (levels >= 2) paths.pop();
  if (levels >= 3) paths.pop();
  return paths.join('/');
};

/**
 * Loops through patters to find a path to go back from current pathname
 * Returns false if no patterns matching current pathname
 * @param {String} pathname current
 * @return {String|Boolean} pathname to go back
 */
const getPathFromPatterns = (pathname) => {
  return goBackPatterns.reduce((path, pattern) => {
    return pathname.match(pattern.pattern) ? pattern.path : path;
  }, false);
};

/**
 * Cuts all after last slash in pathname
 * @param {String} pathname current
 * @return {String} pathname to go back
 */
const getPathFromPathname = (pathname) => {
  const paths = pathname.split('/');
  paths.pop();
  return paths.join('/');
};

/**
 * Calls history's methods to redirect after log in
 * @param {object} history routes's history object
 *
 * @return {undefined}
 */
export const redirectAfterLogin = (history) => {
  history.goBack();
};
