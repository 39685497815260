import { createAction } from "redux-actions";

/**
 * === CONSTANTS
 **/

/**
 * Generates a set of constants for request actions (request, success, failure) based on a given type and name.
 * @param {string} type - The type of the entity.
 * @param {string} name - The name of the action.
 * @returns {object} An object containing the constants for request, success and failure actions.
 */
export const buildRequestConstants = (type, name) => ({
  [`${name}_REQUEST`]: `${type}/${name}_REQUEST`,
  [`${name}_SUCCESS`]: `${type}/${name}_SUCCESS`,
  [`${name}_FAILURE`]: `${type}/${name}_FAILURE`,
});

/**
 * Generates a set of constants for filter actions (set filter, add filter, remove filter, reset filter, apply filter) based on a given type.
 * @param {string} type - The type of the entity.
 * @returns {object} An object containing the constants for filter actions.
 */
export const buildFilterConstants = (type) => ({
  SET_FILTER: `${type}/SET_FILTER`,
  ADD_FILTER: `${type}/ADD_FILTER`,
  REMOVE_FILTER: `${type}/REMOVE_FILTER`,
  RESET_FILTER: `${type}/RESET_FILTER`,
  APPLY_FILTER: `${type}/APPLY_FILTER`,
});

/**
 * Generates an object of pagination constants prefixed with a given type.
 *
 * @param {string} type - Prefix for the pagination constants
 * @return {object} An object containing pagination constants
 */
export const buildPaginationConstants = (type) => ({
  SET_PAGES_STAT: `${type}/SET_PAGES_STAT`,
  SET_ITEMS_PER_PAGE: `${type}/SET_ITEMS_PER_PAGE`,
  SET_ACTIVE_PAGE: `${type}/SET_ACTIVE_PAGE`,
  RESET_PAGES: `${type}/RESET_PAGES`,
});

/**
 * Generates a set of constants for CRUD actions (load list, create item, update item, delete item) based on a given type.
 * @param {string} type - The type of the entity.
 * @returns {object} An object containing the constants for CRUD actions.
 */
export const buildCrudConstants = (type) => ({
  ...buildRequestConstants(type, "LOAD_LIST"),
  ...buildRequestConstants(type, "CREATE_ITEM"),
  ...buildRequestConstants(type, "UPDATE_ITEM"),
  ...buildRequestConstants(type, "DELETE_ITEM"),
});

/**
 * Constructs a comprehensive set of constants for the provided type.
 *
 * @param {string} type - the type to build constants for
 * @return {object} an object containing CRUD, filter, and pagination constants
 */
export const buildFullPageConstants = (type) => ({
  ...buildCrudConstants(type),
  ...buildFilterConstants(type),
  ...buildPaginationConstants(type),
});

/**
 * === ACTIONS
 **/

/**
 * Generates a set of action creators for request actions (request, success, failure) based on a given action name and constant name.
 * @param {object} constants - An object containing the constants for request, success and failure actions.
 * @param {string} actionName - The name of the action.
 * @param {string} constName - The name of the constant.
 * @returns {object} An object containing the action creators for request, success and failure actions.
 */
export const buildRequestActions = (constants, actionName, constName) => ({
  [`${actionName}Request`]: createAction(constants[`${constName}_REQUEST`]),
  [`${actionName}Success`]: createAction(constants[`${constName}_SUCCESS`]),
  [`${actionName}Failure`]: createAction(constants[`${constName}_FAILURE`]),
});

/**
 * This function builds a set of filter related actions.
 *
 * @param {Object} constants - An object that contains the action types.
 * @return {Object} Returns an object of action creators.
 */
export const buildFilterActions = (constants) => ({
  setFilter: createAction(constants.SET_FILTER),
  addFilter: createAction(constants.ADD_FILTER),
  removeFilter: createAction(constants.REMOVE_FILTER),
  resetFilter: createAction(constants.RESET_FILTER),
  applyFilter: createAction(constants.APPLY_FILTER),
});

/**
 * Generates an object containing page-related action creators.
 *
 * @param {Object} constants - An object containing various action type constants
 * @return {Object} An object containing functions to create actions for setting
 *                  page stats, items per page, active page, and resetting pages.
 */
export const buildPaginationActions = (constants) => ({
  setPagesStat: createAction(constants.SET_PAGES_STAT),
  setItemsPerPage: createAction(constants.SET_ITEMS_PER_PAGE),
  setActivePage: createAction(constants.SET_ACTIVE_PAGE),
  resetPages: createAction(constants.RESET_PAGES),
});

/**
 * Generates a set of action creators for CRUD actions (load list, create item, update item, delete item).
 * @returns {object} An object containing the action creators for CRUD actions.
 */
export const buildCrudActions = (constants) => ({
  ...buildRequestActions(constants, "loadList", "LOAD_LIST"),
  ...buildRequestActions(constants, "createItem", "CREATE_ITEM"),
  ...buildRequestActions(constants, "updateItem", "UPDATE_ITEM"),
  ...buildRequestActions(constants, "deleteItem", "DELETE_ITEM"),
});

/**
 * Builds the full page actions by combining CRUD, filter, and
 * pagination actions based on the provided constants.
 *
 * @param {Object} constants - constants to be used for action building
 * @return {Object} the combined CRUD, filter, and pagination actions
 */
export const buildFullPageActions = (constants) => ({
  ...buildCrudActions(constants),
  ...buildFilterActions(constants),
  ...buildPaginationActions(constants),
});

/**
 * === ACTION HANDLERS
 **/

/**
 * Builds handlers for list loading actions using provided constants.
 *
 * @param {Object} constants - An object with LOAD_LIST_REQUEST,
 * LOAD_LIST_SUCCESS, and LOAD_LIST_FAILURE properties.
 * @return {Object} An object with handlers for load list request,
 * success, and failure actions.
 */
export const buildLoadListActionHandlers = (constants) => ({
  [constants.LOAD_LIST_REQUEST]: (state) => ({ ...state, loading: true }),
  [constants.LOAD_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    loading: false,
  }),
  [constants.LOAD_LIST_FAILURE]: (state, { payload }) => ({
    ...state,
    errors: payload,
    loading: false,
  }),
});

/**
 * Builds handlers for creating an item using the provided constants.
 *
 * @param {Object} constants - An object containing the constants for item creation requests, successes, and failures.
 * @return {Object} A set of action handlers for handling item creation requests, successes, and failures.
 */
export const buildCreateItemActionHandlers = (constants) => ({
  [constants.CREATE_ITEM_REQUEST]: (state) => ({ ...state, loadingItem: true }),
  [constants.CREATE_ITEM_SUCCESS]: (state, { payload }) => ({
    ...state,
    item: payload,
    loadingItem: false,
  }),
  [constants.CREATE_ITEM_FAILURE]: (state, { payload }) => ({
    ...state,
    errors: payload,
    loadingItem: false,
  }),
});

/**
 * Builds action handlers for updating an item using provided constants.
 *
 * @param {Object} constants - An object containing action type constants
 * @return {Object} Action handlers for updating an item
 */
export const buildUpdateItemActionHandlers = (constants) => ({
  [constants.UPDATE_ITEM_REQUEST]: (state) => ({ ...state, loadingItem: true }),
  [constants.UPDATE_ITEM_SUCCESS]: (state, { payload }) => ({
    ...state,
    item: payload,
    loadingItem: false,
  }),
  [constants.UPDATE_ITEM_FAILURE]: (state, { payload }) => ({
    ...state,
    errors: payload,
    loadingItem: false,
  }),
});

/**
 * Constructs action handlers for deleting an item.
 *
 * @param {Object} constants - An object containing action type constants
 * @return {Object} An object with action handlers for delete item request,
 * success, and failure.
 */
export const buildDeleteItemActionHandlers = (constants) => ({
  [constants.DELETE_ITEM_REQUEST]: (state) => ({ ...state, loadingItem: true }),
  [constants.DELETE_ITEM_SUCCESS]: (state) => ({
    ...state,
    loadingItem: false,
  }),
  [constants.DELETE_ITEM_FAILURE]: (state, { payload }) => ({
    ...state,
    errors: payload,
    loadingItem: false,
  }),
});

/**
 * Builds CRUD (Create, Read, Update, Delete) action handlers for a given set of constants.
 *
 * @param {Object} constants - An object containing constants used in CRUD operations.
 * @return {Object} An object containing action handlers for CRUD operations.
 */
export const buildCrudActionHandlers = (constants) => ({
  ...buildLoadListActionHandlers(constants),
  ...buildCreateItemActionHandlers(constants),
  ...buildUpdateItemActionHandlers(constants),
  ...buildDeleteItemActionHandlers(constants),
});

/**
 * Builds an object containing handlers for filter actions.
 *
 * @param {Object} constants - An object containing action constants.
 * @return {Object} An object with action handlers for setting, adding,
 * removing, and resetting filters in the state.
 */
export const buildFilterActionHandlers = (constants) => ({
  [constants.SET_FILTER]: (state, { payload }) => ({
    ...state,
    filter: payload,
  }),
  [constants.ADD_FILTER]: (state, { payload }) => ({
    ...state,
    filter: {
      ...state.filter,
      ...payload,
    },
  }),
  [constants.REMOVE_FILTER]: (state, { payload }) => {
    const { [payload]: removed, ...rest } = state.filter;
    return {
      ...state,
      filter: rest,
    };
  },
  [constants.RESET_FILTER]: (state) => ({
    ...state,
    filter: {},
  }),
});

/**
 * This function generates an object with action handlers for
 * pagination. Each action handler is a function that takes the
 * current state and payload, and returns a new state with updated
 * pagination values.
 *
 * @param {Object} constants - An object containing action constants.
 * @return {Object} An object with functions that handle different
 * pagination actions.
 */
export const buildPaginationActionHandlers = (constants) => ({
  [constants.SET_PAGES_STAT]: (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      totalPages: payload.page_count,
      recordCount: payload.record_count,
    },
  }),
  [constants.SET_ACTIVE_PAGE]: (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      activePage: payload,
    },
  }),
  [constants.SET_ITEMS_PER_PAGE]: (state, { payload }) => ({
    ...state,
    pagination: {
      ...state.pagination,
      itemsPerPage: payload,
    },
  }),
  [constants.RESET_PAGES]: (state) => ({
    ...state,
    pagination: {
      ...state.pagination,
      activePage: 1,
      totalPages: 1,
      recordCount: 0,
    },
  }),
});

/**
 * Generates a set of full page action handlers by combining list loading,
 * filtering, and pagination action handlers.
 *
 * @param {Object} constants - An object containing action type constants.
 * @return {Object} Returns an object with combined action handlers.
 */
export const buildFullPageActionHandlers = (constants) => ({
  ...buildLoadListActionHandlers(constants),
  ...buildFilterActionHandlers(constants),
  ...buildPaginationActionHandlers(constants),
});

/**
 * === STATE
 **/

export const baseInitialState = {
  // list
  data: [],
  loading: false,
  pagination: {
    itemsPerPage: 25,
    activePage: 1,
    totalPages: 1,
    recordCount: 0,
  },
  sort: "",
  search: "",
  filter: {},
  // item
  item: {},
  loadingItem: false,
  // errors
  errors: [],
};

/**
 * Constructs a selector function for a particular feature's reducer.
 * The returned selector can be used to access various properties of
 * the state managed by the reducer.
 *
 * @param {string} featureReducerName - The name of the feature reducer.
 * @return {function} Returns a function that takes the state and
 * returns an object containing various properties of the state
 * (data, loading, pagination, etc) managed by the reducer.
 */
export const buildSelector = (featureReducerName) => (state) => ({
  // list
  data: state[featureReducerName].data,
  loading: state[featureReducerName].loading,
  pagination: state[featureReducerName].pagination,
  sort: state[featureReducerName].sort,
  search: state[featureReducerName].search,
  filter: state[featureReducerName].filter,
  // errors
  errors: state[featureReducerName].errors,
  // item
  item: state[featureReducerName].item,
  loadingItem: state[featureReducerName].loadingItem,
});
