import React from 'react'
import { Button, Icon, Popup, Loader } from 'semantic-ui-react'

const WiredStatusCell = (
  {
    setPaid, addChargesOpen, invoiceId, payments,
    wiredStatusCell: { loading, invoiceId: currentInvoiceId }
  }) => {

  const handlePaidClick = () => setPaid({ invoiceId });
  const handleChargesClick = () => addChargesOpen({ invoiceId, payments });

  return (
    loading && currentInvoiceId === invoiceId ?
      <Loader active inline /> :
      <Button.Group icon size="mini">

        <Button disabled color="black">
          WIRED
        </Button>
        <Popup content="Set status PAID without bank charges." wide="very" trigger={
          <Button basic color="green" onClick={ handlePaidClick }>
            <Icon name='check' />
          </Button>
        } />
        <Popup content='Add bank charges.' trigger={
          <Button basic color="orange" onClick={ handleChargesClick } >
            <Icon name='money' />
          </Button>
        } />
      </Button.Group>
  )
}

export default WiredStatusCell;