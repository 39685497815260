import { put, call, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-semantic-toasts';
import { actions, constants } from './redux';
import get from 'lodash/get';

import * as api from 'utils/resources';

const apiHandlers = {
  'Payment': api.payments,
  'PurchaseOrder': api.purchaseOrders,
  'PaymentInstruction': api.paymentInstructions,
  'Invoice': api.invoices,
  'Approval::Request': api.approvalRequests,
};

export function* deleteItem({ payload: { type, pk, afterDestroy } }) {
  try {
    const apiHandler = apiHandlers[type];

    yield call(apiHandler.delete, pk);
    yield call(toast, { type: 'success', title: 'Successfully deleted!' })
    yield put(actions.deleteSuccess());
    if (afterDestroy) {
      yield call(afterDestroy)
    }
  } catch (error) {
    console.error({error})
    const message = (get(error, 'response.data.errors') || []).map(e => e.title).join("\n");
    yield call(toast, { type: 'error', title: 'Something went wrong', description: message })
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.DELETE_ITEM
    ], deleteItem),
  ];
}
