import React, { useCallback} from 'react';
import { Icon, Label } from 'semantic-ui-react';

const AttachmentIcon = ({count, open, setOpen}) => {

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen])

  return(
    <span className="attach-trigger" onClick={handleClick}>
      <Icon name="attach" />
      <Label
        circular
        className="attachments-count"
        size="mini"
      >{ count }
      </Label>
    </span>
  )
}

export default AttachmentIcon;
