import React, { useEffect } from "react";

import { Button, Grid, Statistic, Icon } from "semantic-ui-react";

const formatSum = (sum) =>
  sum?.toLocaleString("en-US", { style: "currency", currency: "USD" });

const MoreTotals = ({
  budgetAaeToDate,
  overBudget,
  totals,
  trigTotalsType,
}) => (
  <Grid>
    <Grid.Row centered columns={3}>
      <Grid.Column>
        <Statistic size="mini">
          <Statistic.Value>{formatSum(budgetAaeToDate)}</Statistic.Value>
          <Statistic.Label>Budget+AAE</Statistic.Label>
        </Statistic>
      </Grid.Column>
      <Grid.Column>
        <Statistic size="mini">
          <Statistic.Value>{formatSum(totals.actualBudget)}</Statistic.Value>
          <Statistic.Label>Actual Budget</Statistic.Label>
        </Statistic>
      </Grid.Column>
      <Grid.Column>
        <Statistic size="mini" color={overBudget ? "red" : undefined}>
          <Statistic.Value>
            {formatSum(budgetAaeToDate - totals.actualBudget)}
          </Statistic.Value>
          <Statistic.Label>Deviation</Statistic.Label>
        </Statistic>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row columns={3}>
      <Grid.Column width={7} textAlign="right">
        <Statistic size="mini">
          <Statistic.Value>
            {formatSum(budgetAaeToDate / totals.currentDay)}
          </Statistic.Value>
          <Statistic.Label>Planned Daily rate</Statistic.Label>
        </Statistic>
      </Grid.Column>
      <Grid.Column width={2}>
        <Button
          basic
          circular
          size="mini"
          animated="vertical"
          onClick={trigTotalsType}
        >
          <Button.Content hidden>less</Button.Content>
          <Button.Content visible>
            <Icon name="arrows alternate horizontal" />
          </Button.Content>
        </Button>
      </Grid.Column>
      <Grid.Column width={7} textAlign="left">
        <Statistic size="mini" color={overBudget ? "red" : undefined}>
          <Statistic.Value>
            {formatSum(totals.actualBudget / totals.currentDay)}
          </Statistic.Value>
          <Statistic.Label>Actual Daily Rate</Statistic.Label>
        </Statistic>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const LessTotals = ({ totals: { less } = {}, trigTotalsType }) => (
  <Grid textAlign="center">
    <Grid.Row>
      <Button
        basic
        circular
        size="mini"
        animated="vertical"
        onClick={trigTotalsType}
      >
        <Button.Content hidden>more</Button.Content>
        <Button.Content visible>
          <Icon name="arrows alternate vertical" />
        </Button.Content>
      </Button>
    </Grid.Row>
    <Grid.Row columns={3}>
        <Grid.Column>

        <Statistic size="mini">
          <Statistic.Value>{formatSum(less?.total)}</Statistic.Value>
          <Statistic.Label>Total</Statistic.Label>
        </Statistic>
        </Grid.Column>
        <Grid.Column>
        <Statistic size="mini">
          <Statistic.Value>{formatSum(less?.paid)}</Statistic.Value>
          <Statistic.Label>Paid</Statistic.Label>
        </Statistic>
        </Grid.Column>
        <Grid.Column>
        <Statistic
          size="mini"
          color={less?.paid < less?.total ? "red" : undefined}
        >
          <Statistic.Value>
            {formatSum(less?.total - less?.paid)}
          </Statistic.Value>
          <Statistic.Label>Unpaid</Statistic.Label>
        </Statistic>
        </Grid.Column>
    </Grid.Row>
  </Grid>
);

const POTotals = ({
  totals,
  fetchTotals,
  loading,
  totalsType,
  trigTotalsType,
}) => {
  useEffect(() => {
    fetchTotals();
  }, [fetchTotals]);

  const budgetAaeToDate =
    (totals.budgetAae * totals.currentDay) / totals.yearDays;
  const overBudget = budgetAaeToDate < totals.actualBudget;

  return (
    <div>
      {loading && "loading..."}
      {!loading && totalsType === "more" && (
        <MoreTotals
          budgetAaeToDate={budgetAaeToDate}
          overBudget={overBudget}
          totals={totals}
          trigTotalsType={trigTotalsType}
        />
      )}
      {!loading && totalsType === "less" && (
        <LessTotals totals={totals} trigTotalsType={trigTotalsType} />
      )}
    </div>
  );
};

export default POTotals;
