
export const initialState = {
  data: [],
  item: {},
  included: [],
  didLoad: false,
  isPushing: false,
  isLoading: false,
  deletingItem: null,
  pagination: {
    first: 1,
    activePage: 1,
    last: undefined,
    totalPages: undefined,
    itemsPerPage: 25,
  },
  filter: {},
  sort: {
    field: '',
    direction: 'asc',
  },
  search: '',
  error: null,
};

export const init = () => ({ ...initialState })

export const startLoading = state => ({
  ...state,
  isLoading: true,
});

export const loadingError = (state, action) => ({
  ...state,
  error: action.payload,
  isLoading: false,
  isPushing: false,
});

export const startPushing = state => ({
  ...state,
  isPushing: true,
});

export const pushingError = (state, action) => ({
  ...state,
  error: action.payload,
  isPushing: false,
});

export const clearError = (state) => ({
  ...state,
  error: null,
  isPushing: false,
});


export const setItemsAsData = (state, action) => ({
  ...state,
  isLoading: false,
  didLoad: true,
  data: action.payload.data.data,
  included: action.payload.data.included,
  deletingItem: null,
  pagination: {
    ...state.pagination,
    totalPages: action.payload.data.meta.page_count,
  },
});

export const setItem = (state, { payload: { data: { data } } }) => ({
  ...state,
  isLoading: false,
  isPushing: false,
  item: data,
});

export const clearItem = (state) => ({
  ...state,
  isLoading: false,
  isPushing: false,
  item: {},
});

export const addItemToData = (state, action) => ({
  ...state,
  isLoading: false,
  isPushing: false,
  data: [...state.data, action.payload],
});

export const updateItem = (state) => {
  // const data = [...state.data];
  // const targetIndex = findIndex(data, it => it.id === action.payload.id);
  //
  // data.splice(targetIndex, 1, action.payload);

  return { ...state, isPushing: false };
};

export const deleteItem = (state, action) => ({
  ...state,
    isPushing: false,
    deletingItem: action.payload,
    // data: state.data.filter(item => item.id !== action.payload),
  }
);

export const changePage = (state, action) => ({
  ...state,
  isLoading: true,
  pagination: {
    ...state.pagination,
    activePage: action.payload,
  },
});

export const changeItemsCountPerPage = (state, action) => ({
  ...state,
  isLoading: true,
  pagination: {
    ...state.pagination,
    itemsPerPage: action.payload,
  },
});

export const changeFilter = (state, action) => ({
  ...state,
  filter: {
    ...state.filter,
    [action.payload.field]: action.payload.value,
  },
});

export const removeFilter = (state, action) => {
  const filter = state.filter;
  const key = action.payload;
  delete filter[key];

  return {
    ...state,
    filter
  }

}

export const clearFilter = state => ({
  ...state,
  filter: {},
});

export const applyNewRequestParams = state => ({
  ...state,
  isLoading: true,
  pagination: {
    ...state.pagination,
    activePage: 1,
  },
});

export const changeSearch = (state, action) => ({
  ...state,
  search: action.payload,
});

export const clearSearch = state => ({
  ...state,
  search: '',
});

export const sortItems = (state, action) => {
  if (state.sort.field === action.payload) {
    const oldDirection = state.sort.direction;
    const nextDirection = oldDirection === 'asc' ? 'desc' : 'asc';

    return {
      ...state,
      isLoading: true,
      sort: { ...state.sort, direction: nextDirection },
    };
  }

  return {
    ...state,
    isLoading: true,
    sort: { ...state.sort, field: action.payload },
  };
};
