import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { actions, constants } from './index';
import attachmentsApi from 'utils/resources/attachments';

export function* loadAttachments({ payload }) {
  try {
    yield put(actions.setLoading(true));
    const { record_id, record_type } = payload;
    const {data} = yield call(attachmentsApi.index, { record_id, record_type });
    yield put(actions.setAttachments(data.data));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setLoading(false));
    yield put(actions.addChargesError(error))
  }
}



export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.LOAD_ATTACHMENTS_REQUEST
    ], loadAttachments),
  ];
}
