import {createAction, handleActions} from 'redux-actions';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';

import Api from '../../utils/resourcesApi';

import {
  actionHandlers as approveActionHandlers,
  initialState as approveInitialState
} from './approve/redux';

import {
  actionHandlers as fillFromInvoiceActionHandlers,
  initialState as fillFromInvoiceInitialState
} from './fill-from-invoice/redux';

import {
  actionHandlers as linkedInvoicesActionHandlers,
  initialState as linkedInvoicesInitialState
} from './linked-invoices/redux';

import {
  actionHandlers as mailerActionHandlers,
  initialState as mailerInitialState
} from '../components/mailers/purchase_order/redux'
import {call, put, takeEvery} from "@redux-saga/core/effects";

export const constants = {
  ...getConstantsByFeatureName('PURCHASE_ORDERS'),
  SET_LOADING_ITEM_ATTACHMENTS: '@PURCHASE_ORDERS/SET_LOADING_ITEM_ATTACHMENTS',
  LOAD_ITEM_ATTACHMENTS_REQUEST: '@PURCHASE_ORDERS/LOAD_ITEM_ATTACHMENTS_REQUEST',
  LOAD_ITEM_ATTACHMENTS_SUCCESSFUL: '@PURCHASE_ORDERS/LOAD_ITEM_ATTACHMENTS_SUCCESSFUL',
  CLEAR_ITEM_ATTACHMENTS: '@PURCHASE_ORDERS/CLEAR_ITEM_ATTACHMENTS',
  DELETE_ATTACHMENT_REQUEST: '@PURCHASE_ORDERS/DELETE_ATTACHMENT_REQUEST',
};

const baseInitialState = {
  ...commonActionHandlers.initialState,
  itemAttachments: [],
  itemAttachmentsLoading: false,
};

const baseActionHandlers = {
  ...getActionHandlers(constants),
  [constants.LOAD_ITEM_ATTACHMENTS_REQUEST]: (state) => ({
    ...state,
    itemAttachments: []
  }),
  [constants.SET_LOADING_ITEM_ATTACHMENTS]: (state) => ({
    ...state,
    itemAttachmentsLoading: true
  }),
  [constants.LOAD_ITEM_ATTACHMENTS_SUCCESSFUL]: (state, { payload }) => ({
    ...state,
    itemAttachmentsLoading: false,
    itemAttachments: payload
  }),
  [constants.CLEAR_ITEM_ATTACHMENTS]: (state) => ({
    ...state,
    itemAttachments: []
  }),
}


export const actions = {
  ...getActionCreators(constants),
  setLoadingItemAttachments: createAction(constants.SET_LOADING_ITEM_ATTACHMENTS),
  loadItemAttachmentsRequest: createAction(constants.LOAD_ITEM_ATTACHMENTS_REQUEST),
  loadItemAttachments: createAction(constants.LOAD_ITEM_ATTACHMENTS_SUCCESSFUL),
  clearItemAttachments: createAction(constants.CLEAR_ITEM_ATTACHMENTS),
  deleteAttachmentRequest: createAction(constants.DELETE_ATTACHMENT_REQUEST),
}


export const actionHandlers = {
  ...baseActionHandlers,
  ...approveActionHandlers,
  ...fillFromInvoiceActionHandlers,
  ...linkedInvoicesActionHandlers,
  ...mailerActionHandlers,
};

export const initialState = {
  ...baseInitialState,
  approve: approveInitialState,
  fillFromInvoice: fillFromInvoiceInitialState,
  linkedInvoices: linkedInvoicesInitialState,
  mailer: mailerInitialState,
};

export const reducer = handleActions(actionHandlers, initialState);

export const selector = getSelectorByFeatureName('purchaseOrders');

const baseSagas = getCRUDSagas('/purchase_orders', Api.purchaseOrders, actions, constants, 'purchaseOrders', 'purchaseOrders');

const extSagas = () => {

  function* loadItemAttachments({payload: {id, model, withoutLoading = false}}) {
    try {
      if (!id) return;
      if (!withoutLoading) yield put(actions.setLoadingItemAttachments(true));
      const data = yield call(Api.attachments.index, {record_id: id, record_type: model})
      yield put(actions.loadItemAttachments(data.data))
    } catch (e) {
      console.error(e);
    }
  }

  function* deleteAttachment({payload: {itemId, id, model}}) {
    try {
      if (!itemId) return;
      yield call(Api.attachments.delete, itemId)
      yield loadItemAttachments({payload: {id, model}})
    } catch (e) {
      console.error(e);
    }
  }

  return [
    takeEvery(constants.LOAD_ITEM_ATTACHMENTS_REQUEST, loadItemAttachments),
    takeEvery(constants.DELETE_ATTACHMENT_REQUEST, deleteAttachment),
  ]
}

export const sagas = () => [
  ...baseSagas(),
  ...extSagas(),
]
