import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from 'utils/resources/tags';

import { downloadStatementOfAccountReport } from 'utils/api/attachments';
import {fetchCompanyStatement, fetchTotalStatement} from "../../companies/statement/sagas";


export function* loadTagList() {
  try {

    const { data: { data } } = yield call(Api.index);

    yield put(actions.loadTagListSuccess(data));
  } catch (error) {
    yield put(actions.loadTagListFailure(error))
  }
}

export function* assignTag({payload: { itemType: item_type, itemId: item_id, tag: tag_name, color, cb }}) {
  try {

    yield call(Api.assignTag, { item_type, item_id, tag_name, color });

    yield loadTagList();
    if (cb) yield call(cb);

    yield put(actions.assignTagSuccess());

  } catch (error) {
    yield put(actions.assignTagFailure(error))
  }
}

export function* removeTag({payload: { itemType: item_type, itemId: item_id, tag: tag_name, cb }}) {
  try {

    yield call(Api.removeTag, { item_type, item_id, tag_name });

    if (cb) yield call(cb);

    yield put(actions.removeTagSuccess());

  } catch (error) {
    yield put(actions.removeTagFailure(error))
  }
}


export default function getFeatureSagas() {
  return [
    takeEvery([constants.LOAD_TAG_LIST_REQUEST], loadTagList),
    takeEvery([constants.ASSIGN_TAG_REQUEST], assignTag),
    takeEvery([constants.REMOVE_TAG_REQUEST], removeTag),
  ];
}
