import React, { useState, useCallback } from 'react';
import { Card, Icon, Accordion, Statistic, Popup, Table } from 'semantic-ui-react'

import CardHeader from '../components/Header';
import Content from './Content';
import DetailTable from './DetailTable';

const CardElement = ({item: { attributes }}) => {

  const [expanded, setExpanded] = useState(false);

  const handleExpanded = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded]);

  return (
    <Card className="dashboard-card">
      <CardHeader item={ attributes } />
      <Card.Content className="header">
        <Card.Header>{ attributes.resource }</Card.Header>
      </Card.Content>
      <Card.Content 
        textAlign="center" 
        description={ <Content values={ attributes.actual.values }/> } 
      />
      <Card.Content extra className="footer">
        <Accordion>
          <Accordion.Title active={ expanded } onClick={ handleExpanded }>
            <Icon name='dropdown' />
            Detail
          </Accordion.Title>
          <Accordion.Content active={ expanded }>
            <DetailTable vessels={ attributes.actual.vessels } />
          </Accordion.Content>
        </Accordion>
      </Card.Content>
    </Card>
  )
}

export default CardElement;
