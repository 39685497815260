import PropTypes from "prop-types"
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Dimmer, Icon, Loader, Modal, Popup, Segment, Table} from 'semantic-ui-react'

import './styles.scss'
import InvoiceFilter from "./invoiceFilter";
import InvoiceRow from "./InvoiceRow";
import InvoiceItemRow from "./InvoiceItemRow";
import TableHeader from "./TableHeader";

const getBudgetItem = (attributes, direction) => {
  const budget_item = direction === 'incoming' ? attributes.budget_item_id : attributes.cross_item_id;

  return budget_item === null ? '' : budget_item.toString();
}

const prepareRow = (id, attributes, direction) => ({
  attributes: {
    aae: attributes.aae,
    budget_item_id: getBudgetItem(attributes, direction),
    description: attributes.name,
    price: attributes.price,
    quantity: attributes.quantity,
    invoice_item_ids: [id]
  }
});

function FillFromInvoice(
  {
    showForm,
    showSelectForm,
    setFilter,
    changeFilter,
    applyFilter,
    setCurrentInvoice,
    loadingData,
    loadingDataItems,
    vessel_id,
    customer_id,
    budget_year,
    filters,
    data,
    dataItems,
    currentInvoice,
    values,
    addRow,
  }) {

  const [showFiler, setShowFilter] = useState(false);

  const hideDialog = useCallback(() => showSelectForm(false), []);
  const openDialog = useCallback(() => showSelectForm(true), []);

  const hideFilter = useCallback(() => setShowFilter(false), []);
  const openFilter = useCallback(() => setShowFilter(true), []);



  const currentInvoiceData = useMemo(() => data.find((r) => r.id === currentInvoice), [data, currentInvoice]);

  const addItem = useCallback(({id, attributes}) => {
    const {direction} = currentInvoiceData.attributes;
    const newRow = prepareRow(id, attributes, direction);

    addRow(newRow);
  }, [currentInvoiceData])

  const addAllItems = useCallback(() => {
    const {direction} = currentInvoiceData.attributes;

    const newRows = dataItems.map(({id, attributes}) => prepareRow(id, attributes, direction))

    newRows.forEach((row) => addRow(row));
    showSelectForm(false);
  }, [values, dataItems, currentInvoiceData])

  useEffect(() => {
    if (!showForm) return;
    setFilter({
      vessel_id,
      budget_year
    });
    applyFilter();
  }, [showForm, vessel_id, customer_id, budget_year])

  return (
    <Modal
      onClose={hideDialog}
      onOpen={openDialog}
      open={showForm}
      size="fullscreen"
      trigger={<Button basic size="mini">Fill from invoice</Button>}
    >
      <Modal.Header>
        Select Invoices/Invoice Items to fill in Purchase Order
        <Popup
          open={showFiler}
          content={<InvoiceFilter filters={filters} changeFilter={changeFilter} applyFilter={applyFilter}/>}
          on='click'
          position='bottom center'
          onClose={hideFilter}
          onOpen={openFilter}
          trigger={<Button size="mini" icon='filter' />}
        />
      </Modal.Header>
      <Modal.Content>
        <Segment className="ffpo-invoice-list">
          <Dimmer active={loadingData} inverted>
            <Loader />
          </Dimmer>

          <Table compact celled size="small">
            <TableHeader columns={['Date', 'Number', 'Customer', 'Company', 'Currency', 'Sum', 'Sum USD', 'Action']} />
            <Table.Body>
              {data.map((row) =>
                <InvoiceRow
                  key={row.id}
                  row={row}
                  currentInvoice={currentInvoice}
                  setCurrentInvoice={setCurrentInvoice}
                />
              )}
            </Table.Body>
          </Table>
        </Segment>
        <Segment className="ffpo-invoice-item-list">
          <Dimmer active={loadingDataItems} inverted>
            <Loader />
          </Dimmer>
          {dataItems.length > 0 &&
            <Table compact celled size="small">
              <TableHeader columns={[
                'Article',
                'Description',
                'Price',
                'Quantity',
                'Sum',
                'AAE',
                <Button positive basic circular key='add-all' size="mini" content="All" icon='plus' onClick={addAllItems}/>
              ]} />
              <Table.Body>
                {dataItems.map(row =>
                  <InvoiceItemRow
                    key={row.id}
                    row={row}
                    addItem={addItem}
                  />
                )}
              </Table.Body>
            </Table>
          }
        </Segment>

      </Modal.Content>
      <Modal.Actions>
       <Button
          content="Ok"
          labelPosition='right'
          icon='checkmark'
          onClick={hideDialog}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

FillFromInvoice.propTypes = {
  addRow: PropTypes.func,
  applyFilter: PropTypes.func,
  budget_year: PropTypes.any,
  changeFilter: PropTypes.any,
  currentInvoice: PropTypes.any,
  customer_id: PropTypes.any,
  data: PropTypes.shape({
    find: PropTypes.func,
    map: PropTypes.func
  }),
  dataItems: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func
  }),
  filters: PropTypes.any,
  loadingData: PropTypes.any,
  loadingDataItems: PropTypes.any,
  setCurrentInvoice: PropTypes.any,
  setFilter: PropTypes.func,
  showForm: PropTypes.any,
  showSelectForm: PropTypes.func,
  values: PropTypes.any,
  vessel_id: PropTypes.any
}

export default FillFromInvoice
