import React, {useCallback, useState} from 'react'
import {Button, Table} from "semantic-ui-react";

const InvoiceItemRow = ({ row, addItem }) => {

  const [rowAdded, setRowAdded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddRow = useCallback(() => {
    setLoading(true);
    setRowAdded(true);
    addItem(row);
    setLoading(false);
  }, [row]);

  return (
    <Table.Row key={row.id}>
      <Table.Cell>
        {row.attributes.budget_item_name}
        {row.attributes.cross_item_id &&
          <>
            <br /> {row.attributes.cross_item_name}
          </>
        }
      </Table.Cell>
      <Table.Cell>{row.attributes.name}</Table.Cell>
      <Table.Cell>{row.attributes.price}</Table.Cell>
      <Table.Cell>{row.attributes.quantity}</Table.Cell>
      <Table.Cell>{row.attributes.sum}</Table.Cell>
      <Table.Cell>{row.attributes.aae ? '✔' : ''}</Table.Cell>
      <Table.Cell width="1">
        <Button
          positive
          basic
          circular
          loading={loading}
          disabled={rowAdded}
          size="mini"
          content="Add"
          icon='plus'
          onClick={handleAddRow}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default InvoiceItemRow;
