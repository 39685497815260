/**
 * @module reduxSagas
 */
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from 'utils/resources/companies';

/**
 * The loadList function fetches data from the API based on current state values of
 * pagination, search, and filter parameters from the company mentions state.
 * On successful API call, it dispatches loadListSuccess and setPagesStat actions with the fetched data.
 * On failure, it dispatches loadListFailure action with the error.
 * @function loadList
 * @generator
 * @yields {select} - A Redux saga select effect to retrieve state values.
 * @yields {call} - A Redux saga call effect to call an API.
 * @yields {put} - A Redux saga put effect to dispatch an action to the Redux store.
 */
export function* loadLink({ payload: { id } }) {
  try {
    const { data } = yield call(Api.invitationLink, id);
    yield put(actions.loadInviteLinkSuccess(data));
  } catch (error) {
    yield put(actions.loadInviteLinkFailure(error))
  }
}

export function* createLink({ payload: { id } }) {
  try {
    yield call(Api.invitationLinkCreate, id);
    yield put(actions.generateInviteLinkSuccess());
    yield loadLink({ payload: { id } });
  } catch (error) {
    yield put(actions.generateInviteLinkFailure(error))
  }
}

export function* loadInvitedUsers({ payload: { id } }) {
  try {
    const { data } = yield call(Api.invitedUsers, id);
    yield put(actions.loadInvitedUsersSuccess(data));
  } catch (error) {
    yield put(actions.loadInvitedUsersFailure(error))
  }
}

export function* deactivateInvitedUser({ payload: { id, userId } }) {
  try {
    yield call(Api.deactivateInvitedUser, id, userId);
    yield put(actions.deactivateInvitedUserSuccess());
    yield loadInvitedUsers({ payload: { id } });
  } catch (error) {
    yield put(actions.deactivateInvitedUserFailure(error))
  }
}

/**
 * getFeatureSagas hooks the loadList saga to LOAD_LIST_REQUEST action using takeEvery helper from redux-saga.
 * @function getFeatureSagas
 * @returns {IterableIterator<ForkEffect<never>>} Iterator for ForkEffect.
 */
export default function getFeatureSagas() {
  return [
    takeEvery([constants.LOAD_INVITE_LINK_REQUEST], loadLink),
    takeEvery([constants.GENERATE_INVITE_LINK_REQUEST], createLink),
    takeEvery([constants.LOAD_INVITED_USERS_REQUEST], loadInvitedUsers),
    takeEvery([constants.DEACTIVATE_INVITED_USER_REQUEST], deactivateInvitedUser),
  ];
}
