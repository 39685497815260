import React,{ useState, useMemo } from 'react'
import { Dropdown, Button, Input, Popup, List, Image, Segment, Grid } from 'semantic-ui-react'

import connect from '../reduxConnect'
import { useCallback } from 'react'

const UsersSelect = ({users, selectedUsers, setSelectedUsers}) => {

  const [search, setSearch] = useState('');

  const handleClick = (e, { data: value }) => {
    const index = selectedUsers.findIndex(e => e === value);
    if (index === -1) {
      setSelectedUsers([...selectedUsers, value]);
    } else {
      setSelectedUsers([...selectedUsers.filter(e => e !== value)]);
    }
  }

  const selected = useCallback(
    (value) => selectedUsers.includes(value),
    [selectedUsers]
  );

  const handleSearch = (e, { value }) => setSearch(value);
  const handleClearSearch = () => setSearch("");
  const handleSelectAll = () => setSelectedUsers(users.map(u => u.name))
  const handleUnselectAll = () => setSelectedUsers([])

  const inputAction = useMemo(() => ({ icon: 'delete', onClick: handleClearSearch }), []);

  return(
    <Popup
      content='Hello'
      position='bottom center'
      on='click'
      trigger={
        <Button 
          content={ selectedUsers.length === 0 ? 'Add user' : `Selected ${selectedUsers.length} users` }
          icon="add user" />
      }
    >
      <Popup.Content style={ { height: '380px' } }>
        <Segment.Group>
          <Segment attached>
            <Grid.Row>
              <Input 
                action={ inputAction }
                icon='search' 
                iconPosition='left' 
                className='search' 
                value={ search } 
                onChange={ handleSearch } 
              />
            </Grid.Row>
            <Grid.Row>
              <Button.Group basic icon size="mini">
                <Button onClick={ handleSelectAll } icon="check square outline" />
                <Button onClick={ handleUnselectAll } icon="square outline" />
              </Button.Group>
            </Grid.Row>
          </Segment>
          <Segment clearing style={ { overflowY: 'scroll', height: '300px' } }>
            <List selection animated verticalAlign='middle'>
              {
                users.map(user=>(
                  search === '' || user.name.toUpperCase().includes(search.toUpperCase()) ?
                    <List.Item data={user.name} onClick={ handleClick }>
                      { selected(user.name) ? '✔️ ' : '' }
                      <Image avatar src={ user.avatar } />
                      <List.Content>
                        <List.Header>{ user.name }</List.Header>
                      </List.Content>
                    </List.Item> :
                    null
                ))
              }
            </List>
          </Segment>
        </Segment.Group>
      
      </Popup.Content>
    </Popup>
)}

export default connect(UsersSelect);
