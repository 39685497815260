import {
  COMPANY, USER, DIRECTION, SEARCH_QUERY, INVOICES_PAGE, INVOICES_YEAR, INVOICES_SORT, PO_SORT
} from 'constants/storageKeys';

const getJsonItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key)) || undefined;
  } catch (e) {
    return undefined;
  }
};

const store = {
  get user() {
    return getJsonItem(USER);
  },

  set user(user) {
    localStorage.setItem(USER, JSON.stringify(user));
  },

  get company() {
    return getJsonItem(COMPANY);
  },

  set company(company) {
    localStorage.setItem(COMPANY, JSON.stringify(company));
  },

  get invoicesDirection() {
    return localStorage.getItem(DIRECTION) || 'incoming';
  },

  set invoicesDirection(direction) {
    localStorage.setItem(DIRECTION, direction);
  },

  get invoicesSorting() {
    return getJsonItem(INVOICES_SORT);
  },

  set invoicesSorting(value) {
    localStorage.setItem(INVOICES_SORT, JSON.stringify(value));
  },

  get poSorting() {
    return getJsonItem(PO_SORT);
  },

  set poSorting(value) {
    localStorage.setItem(PO_SORT, JSON.stringify(value));
  },

  get searchQuery() {
    return localStorage.getItem(SEARCH_QUERY) || '';
  },

  set searchQuery(searchQuery) {
    localStorage.setItem(SEARCH_QUERY, searchQuery);
  },

  get invoicesPage() {
    return localStorage.getItem(INVOICES_PAGE) || 1; // eslint-disable-line no-magic-numbers
  },

  set invoicesPage(invoicesPage) {
    localStorage.setItem(INVOICES_PAGE, invoicesPage);
  },

  get invoicesYear() {
    return localStorage.getItem(INVOICES_YEAR) || (new Date()).getFullYear();
  },

  set invoicesYear(invoicesYear) {
    localStorage.setItem(INVOICES_YEAR, invoicesYear);
  }
};

export default store;
