import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/payrolls';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-period' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, { }),

  index: (pagination, sort, search, filter, { payrolls }) => api.read({ type }, {
    query: {
      sort: sortOptions(sort),
      filter: { ...filter, year: payrolls.budgetYear },
      page: { size: pagination.itemsPerPage, number: pagination.activePage }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

  crewList: (id, filter = {}) => api.read(
    { type: 'api_resources/payroll_employees' },
    { query: {
        filter: {
          payroll_id: id,
        },
        page: { size: 999 }
      }
    }
  ),

  bankDetails: (id, filter = {}) => api.read(
    { type: 'api_resources/payroll_bank_details' },
    { query: {
        filter: {
          payroll_id: id,
        },
        page: { size: 999 }
      }
    }
  ),

  addEmployee: (id, payload) => api.update({ type, id, action: 'add_employee' }, {}, payload),

  updateEmployee: (id, options) => api.update({ type, id, action: 'update_employee' }, { query: options }, {}),

  createInvoice: (id, bank_type) => api.read({ type, id, action: 'create_invoice' }, { query: { type: bank_type } }),
}
