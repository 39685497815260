import ApolloClient from 'apollo-boost/lib/index';

import { getApiBase, authHeaders }  from 'utils/api/api';

const uri = `${ getApiBase() }/graphql`;


export default new ApolloClient({ uri,
  request: async (operation) => {
    operation.setContext({
      headers: authHeaders()
    });
  }
});
