export default featureName => state => ({
  data: state[featureName].data,
  item: state[featureName].item,
  included: state[featureName].included,
  didLoad: state[featureName].didLoad,
  isPushing: state[featureName].isPushing,
  isLoading: state[featureName].isLoading,
  deletingItem: state[featureName].deletingItem,
  pagination: state[featureName].pagination,
  filter: state[featureName].filter,
  sort: state[featureName].sort,
  search: state[featureName].search,
  error: state[featureName].error,
});
