import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  deleteRequest: actions.deleteRequest,
  deleteItem: actions.deleteItem,
  cancelDelete: () => actions.cancelDelete(),
};

export default connect(selector, mapDispatchToProps);
