import React, {useCallback} from 'react'
import {Button, Table} from "semantic-ui-react";
import {formatSum} from "../../../../utils/fieldsConversion";


const InvoiceRow = ({row, currentInvoice, setCurrentInvoice}) => {

  const handleSetCurrentInvoice = useCallback(() => {
    setCurrentInvoice(row.id)
  }, [setCurrentInvoice, row.id])

  return (
    <Table.Row key={row.id} positive={row.id === currentInvoice}>
      <Table.Cell>{row.attributes.issued_at}</Table.Cell>
      <Table.Cell>{row.attributes.full_number}</Table.Cell>
      <Table.Cell>{row.attributes.customer_name}</Table.Cell>
      <Table.Cell>{row.attributes.company_name}</Table.Cell>
      <Table.Cell>{row.attributes.currency}</Table.Cell>
      <Table.Cell textAlign="right">{formatSum(row.attributes.sum)}</Table.Cell>
      <Table.Cell textAlign="right">{formatSum(row.attributes.sum_usd)}</Table.Cell>
      <Table.Cell collapsing>
        <Button primary basic circular  size="mini" content="Details" icon='search' onClick={handleSetCurrentInvoice}/>
        {/*<Button positive basic circular  size="mini" content="Add items" icon='plus' />*/}
      </Table.Cell>
    </Table.Row>
  )

}

export default InvoiceRow;
