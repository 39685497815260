import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './index';
import Api from 'utils/resources/purchaseOrders';

import { store } from 'components/App/Context';

export function* fetchTotals() {
  try {
    const companyIds = yield select(state => state.settings.global.poCompanies);
    const options = { companyId: companyIds, year: store.invoicesYear }
    const { data } = yield call(Api.totals, options);

    yield put(actions.setTotals(data));
  } catch (error) {
    yield put(actions.setErrors(error))
  }
};

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.FETCH_TOTALS_REQUEST
    ], fetchTotals)
  ];
}
