import {
  init,
  startLoading,
  startPushing,
  clearError,
  pushingError,
  loadingError,
  setItemsAsData,
  setItem,
  clearItem,
  addItemToData,
  updateItem,
  deleteItem,
  changePage,
  changeItemsCountPerPage,
  changeFilter,
  removeFilter,
  clearFilter,
  changeSearch,
  clearSearch,
  sortItems,
  applyNewRequestParams,
} from './commonActionHandlers';

/**
 * getActionHandlers
 * That function created for writing code using DRY principe
 * You will have action handlers only for action types what you passed
 * -----------------------------------------------------------------------------------
 * You can customize actionHandlers
 * const customizedActionHandlers = {
 *   ...getActionHandlers(yourFeatureTypes)
 *   [types.YOUR_]: yourCustomActionHandler
 * }
 * -----------------------------------------------------------------------------------
 * @param {Object} types
 * @returns {Object} ActionHandlers for handleActions function (redux-actions library)
 */
const getActionHandlers = (types = {}) => {
  const actionHandlers = {};

  if (types.INIT) actionHandlers[types.INIT] = init;

  if (types.LOAD_ITEMS_REQUEST) actionHandlers[types.LOAD_ITEMS_REQUEST] = startLoading;
  if (types.ITEMS_LOADED_SUCCESSFULLY) actionHandlers[types.ITEMS_LOADED_SUCCESSFULLY] = setItemsAsData;
  if (types.LOAD_ITEMS_ERROR) actionHandlers[types.LOAD_ITEMS_ERROR] = loadingError;

  if (types.CLEAR_ITEM) actionHandlers[types.CLEAR_ITEM] = clearItem;

  if (types.LOAD_ITEM_REQUEST) actionHandlers[types.LOAD_ITEM_REQUEST] = startLoading;
  if (types.ADD_LOADED_ITEM) actionHandlers[types.ADD_LOADED_ITEM] = setItem;
  if (types.LOAD_ITEM_ERROR) actionHandlers[types.LOAD_ITEM_ERROR] = loadingError;

  if (types.CREATE_ITEM_REQUEST) actionHandlers[types.CREATE_ITEM_REQUEST] = startPushing;
  if (types.ADD_CREATED_ITEM) actionHandlers[types.ADD_CREATED_ITEM] = addItemToData;
  if (types.CREATE_ITEM_ERROR) actionHandlers[types.CREATE_ITEM_ERROR] = pushingError;

  if (types.UPDATE_ITEM_REQUEST) actionHandlers[types.UPDATE_ITEM_REQUEST] = startPushing;
  if (types.ITEM_UPDATED_SUCCESSFULLY) actionHandlers[types.ITEM_UPDATED_SUCCESSFULLY] = updateItem;
  if (types.UPDATE_ITEM_ERROR) actionHandlers[types.UPDATE_ITEM_ERROR] = pushingError;

  if (types.DELETE_ITEM_REQUEST) actionHandlers[types.DELETE_ITEM_REQUEST] = startPushing;
  if (types.ITEM_DELETED_SUCCESSFULLY) actionHandlers[types.ITEM_DELETED_SUCCESSFULLY] = deleteItem;
  if (types.DELETE_ITEM_ERROR) actionHandlers[types.DELETE_ITEM_ERROR] = clearError;

  if (types.CHANGE_PAGE_NUMBER) actionHandlers[types.CHANGE_PAGE_NUMBER] = changePage;
  if (types.CHANGE_PAGE_ITEMS_COUNT) actionHandlers[types.CHANGE_PAGE_ITEMS_COUNT] = changeItemsCountPerPage;

  if (types.CHANGE_FILTER) actionHandlers[types.CHANGE_FILTER] = changeFilter;
  if (types.REMOVE_FILTER) actionHandlers[types.REMOVE_FILTER] = removeFilter;
  if (types.CLEAR_FILTER) actionHandlers[types.CLEAR_FILTER] = clearFilter;
  if (types.APPLY_FILTER) actionHandlers[types.APPLY_FILTER] = applyNewRequestParams;

  if (types.CHANGE_SEARCH) actionHandlers[types.CHANGE_SEARCH] = changeSearch;
  if (types.CLEAR_SEARCH) actionHandlers[types.CLEAR_SEARCH] = clearSearch;
  if (types.APPLY_SEARCH) actionHandlers[types.APPLY_SEARCH] = applyNewRequestParams;

  if (types.SORT_ITEMS) actionHandlers[types.SORT_ITEMS] = sortItems;

  return actionHandlers;
};

export default getActionHandlers;
