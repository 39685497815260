import { handleActions } from 'redux-actions';

import getConstantsByFeatureName from 'utils/redux/getConstantsByFeatureName';
import getActionCreators from 'utils/redux/getActionCreators';
import getActionHandlers from 'utils/redux/getActionHandlers';
import getSelectorByFeatureName from 'utils/redux/getSelectorByFeatureName';
import getCRUDSagas from 'utils/redux/getCRUDSagas';

import extendsOptions from './extends'

import Api from 'utils/resourcesApi';

const baseConstants = getConstantsByFeatureName('COMPANIES/BALANCE');
export const constants = extendsOptions.extendedConstants(baseConstants);

const baseActions = getActionCreators(constants);
export const actions = extendsOptions.extendedActions(baseActions, constants);

const initialState = extendsOptions.extendedInitialState;

const baseActionHandlers = getActionHandlers(constants);
const actionHandlers = extendsOptions.extendedActionHandlers(baseActionHandlers, constants);

export const reducer = handleActions(actionHandlers, initialState);

const baseSelectors = getSelectorByFeatureName('companyBalance');
export const selector = extendsOptions.extendedSelectors(baseSelectors);
