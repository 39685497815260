import { createAction } from 'redux-actions';
import {  put, takeEvery } from '@redux-saga/core/effects';
import get from 'lodash/get';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import {LOCATION_CHANGE} from "connected-react-router";

const baseName = 'COMPANIES_PAGE'

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  SET_LOADING: `@${baseName}/SET_LOADING`,
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  setLoading: createAction(constants.SET_LOADING),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  loading: false,
  search: localStorage.getItem(`@${baseName}/CHANGE_SEARCH`) || '',
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.CHANGE_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, action.payload);
    return commonActionHandlers.changeSearch(state, action);
  },
  [constants.CLEAR_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, '');
    return commonActionHandlers.clearSearch(state, action);
  },
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state,
    loading: payload
  }),
});

const newSagas = (baseSagas, constants, actions) => {

  function* clearFilters() {
    yield put(actions.clearFilter());
  }

  return [
    takeEvery(LOCATION_CHANGE, clearFilters),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
