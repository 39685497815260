import React from 'react';
import { Statistic } from 'semantic-ui-react'
import styled from 'styled-components';


const StyledLabel = styled(Statistic.Label)`
  font-size: 0.9em !important;
`;

const Content = ({ values }) => (
  <Statistic.Group size="tiny">
    {
      Object.keys(values).map(key => 
        <Statistic>
          <Statistic.Value>{ values[key] }</Statistic.Value>
          <StyledLabel>{ key.toUpperCase() }</StyledLabel>
        </Statistic>
      )
    }
  </Statistic.Group>
);

export default Content;
