import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  loadLinkedPO: actions.loadLinkedPO,
  unlinkPO: actions.unlinkPO,
};


export default connect(selector, mapDispatchToProps);
