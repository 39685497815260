import React  from "react";
import {Table} from "semantic-ui-react";


const TableHeader = ({columns}) => (
  <Table.Header>
    <Table.Row>
      {columns.map((column) => <Table.HeaderCell key={column}>{column}</Table.HeaderCell>)}
    </Table.Row>
  </Table.Header>
)

export default TableHeader;
