
import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import {createAction} from "redux-actions";
import {call, put, takeEvery, select} from "@redux-saga/core/effects";
import { actions as statementsActions } from '../../Statements/utils/redux';
import { actions as detailsActions } from '../../Details/utils/redux';
import { actions as excludedInvoicesActions } from '../../ExcludedInvoices/utils/redux';
import Api from "../../../../../utils/resourcesApi";


const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  SET_CURRENT_PERIOD: '@PARTNER_SCHEMES_PERIODS/SET_CURRENT_PERIOD',
  SET_DETAILS_FILTER: '@PARTNER_SCHEMES_PERIODS/SET_DETAILS_FILTER',
  UPDATE_DATA: '@PARTNER_SCHEMES_PERIODS/UPDATE_DATA',
  UPDATE_ITEM_ATTRIBUTES: '@PARTNER_SCHEMES_PERIODS/UPDATE_ITEM_ATTRIBUTES',
  DELETE_ITEM: '@PARTNER_SCHEMES_PERIODS/DELETE_ITEM',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  setCurrentPeriod: createAction(constants.SET_CURRENT_PERIOD),
  setDetailsFilter: createAction(constants.SET_DETAILS_FILTER),
  updateData: createAction(constants.UPDATE_DATA),
  updateItemAttributes: createAction(constants.UPDATE_ITEM_ATTRIBUTES),
  deleteItem: createAction(constants.DELETE_ITEM),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  currentPeriod: {
    id: null,
  }
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.SET_CURRENT_PERIOD]: (state, { payload }) => ({
    ...state,
    currentPeriod: payload
  }),

});

const newSagas = (baseSagas, constants, actions) => {

  function* setDetailsFilter({ payload: id }) {
    try {
      yield put(detailsActions.changeFilter('period_id', id));
      yield put(detailsActions.applyFilter());

      yield put(excludedInvoicesActions.changeFilter('period_id', id));
      yield put(excludedInvoicesActions.applyFilter());

    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* updateData({ payload: {id, cb} }) {
    try {
      const currentPeriod = yield select(state => state.partnerSchemesPeriods.currentPeriod);
      yield put(actions.setCurrentPeriod({id: null}));
      yield call(Api.partnerSchemesPeriods.updateData, id);
      yield put(actions.loadItemsRequest());
      yield put(statementsActions.loadItemsRequest());
      if (currentPeriod) yield put(actions.setCurrentPeriod(currentPeriod));
      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* updateItemAttributes({ payload: { id, payload, cb } }) {
    try {
      const currentPeriod = yield select(state => state.partnerSchemesPeriods.currentPeriod);

      yield call(Api.partnerSchemesPeriods.update, id, payload);

      yield put(actions.loadItemsRequest());
      yield put(statementsActions.loadItemsRequest());
      if (currentPeriod) yield put(actions.setCurrentPeriod(currentPeriod));
      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));
    }
  }

  function* deleteItem({ payload: { id, cb } }) {
    try {
      const currentPeriod = yield select(state => state.partnerSchemesPeriods.currentPeriod);
      const newPeriod = currentPeriod.id === id ? {id: null} : currentPeriod;

      yield call(Api.partnerSchemesPeriods.delete, id);

      yield put(actions.loadItemsRequest());
      yield put(statementsActions.loadItemsRequest());
      yield put(actions.setCurrentPeriod(newPeriod));
      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));
    }
  }

  return [
    takeEvery(constants.SET_DETAILS_FILTER, setDetailsFilter),
    takeEvery(constants.UPDATE_DATA, updateData),
    takeEvery(constants.UPDATE_ITEM_ATTRIBUTES, updateItemAttributes),
    takeEvery(constants.DELETE_ITEM, deleteItem),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  currentStatement: state.partnerSchemesStatements.currentStatement,
  currentPeriod: state.partnerSchemesPeriods.currentPeriod
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
