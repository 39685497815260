import {call, put, takeEvery, delay} from 'redux-saga/effects';
import {actions, constants} from './index';
import invoicesApi from 'utils/resources/invoices';
import paymentsApi from 'utils/resources/payments';

import client from 'apiClient';
import refetch from 'apollo-magic-refetch'

export function* setPaid({payload}) {
  try {
    const {invoiceId} = payload;
    yield call(invoicesApi.update, invoiceId, {status: 'paid'});
    yield call(refetch, client, 'Invoice');
    yield delay(500);
    yield put(actions.setPaidSuccess());
  } catch (error) {
    console.error(error)
    yield put(actions.setPaidError(error))
  }
}

export function* addBankCharges({payload}) {
  try {
    const {paymentId, invoiceId, ...attributes} = payload;
    yield call(paymentsApi.update, paymentId, attributes);
    yield call(invoicesApi.update, invoiceId, {status: 'paid'});
    yield call(refetch, client, 'Invoice');
    yield delay(500);
    yield put(actions.addChargesSuccess());
  } catch (error) {
    yield put(actions.addChargesError(error))
  }
}


export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.SET_PAID_REQUEST
    ], setPaid),
    takeEvery([
      constants.ADD_CHARGES_REQUEST
    ], addBankCharges)
  ];
}
