import { createAction } from 'redux-actions';
import { call, put, takeEvery, delay, select } from '@redux-saga/core/effects';
import get from 'lodash/get';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import Api from 'utils/resourcesApi';

import { newStep } from "./form/component/NewStatementWizard/utils";
import {blobRequest, saveXlsxAttachment, savePdfAttachment} from "../../utils/api/attachments";
import {serviceFeeStatementDetails, serviceFeeStatementVessel} from "../../utils/resources";
import {toast} from "react-semantic-toasts";
import { push } from  'connected-react-router';
import {serviceFees} from "utils/paths";

const basePageUrl = '/service_fees';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  SET_LOADING: '@SERVICE_FEE/SET_LOADING',
  CREATE_STATEMENT: '@SERVICE_FEE/CREATE_STATEMENT',
  UPDATE_ITEM_STEP: '@SERVICE_FEE/UPDATE_ITEM_STEP',
  CREATE_INVOICES: '@SERVICE_FEE/CREATE_INVOICES',
  DOWNLOAD_FULL_EXCEL: '@SERVICE_FEE/DOWNLOAD_FULL_EXCEL',

  UPDATE_FROM_CW: '@SERVICE_FEE/UPDATE_FROM_CW',

  // OWNERS
  LOAD_SELECTED_OWNERS: '@SERVICE_FEE/LOAD_SELECTED_OWNERS',
  SET_SELECTED_OWNERS: '@SERVICE_FEE/SET_SELECTED_OWNERS',
  ADD_OWNER: '@SERVICE_FEE/ADD_OWNER',
  REMOVE_OWNER: '@SERVICE_FEE/REMOVE_OWNER',
  // VESSELS
  LOAD_VESSELS: '@SERVICE_FEE/LOAD_VESSELS',
  SET_VESSELS: '@SERVICE_FEE/SET_VESSELS',
  LOAD_SELECTED_VESSELS: '@SERVICE_FEE/LOAD_SELECTED_VESSELS',
  SET_SELECTED_VESSELS: '@SERVICE_FEE/SET_SELECTED_VESSELS',
  ADD_VESSEL: '@SERVICE_FEE/ADD_VESSEL',
  REMOVE_VESSEL: '@SERVICE_FEE/REMOVE_VESSEL',
  CREATE_INVOICE: '@SERVICE_FEE/CREATE_INVOICE',
  DOWNLOAD_EXCEL: '@SERVICE_FEE/DOWNLOAD_EXCEL',
  DOWNLOAD_PDF: '@SERVICE_FEE/DOWNLOAD_PDF',
  // DETAILS
  LOAD_VESSEL_DETAILS: '@SERVICE_FEE/LOAD_VESSEL_DETAILS',
  SET_VESSEL_DETAILS: '@SERVICE_FEE/SET_VESSEL_DETAILS',
  UPDATE_DETAIL_SUM: '@SERVICE_FEE/UPDATE_DETAIL_SUM'

});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  setLoading: createAction(constants.SET_LOADING),
  createStatement: createAction(constants.CREATE_STATEMENT),
  updateItemStep: createAction(constants.UPDATE_ITEM_STEP),
  createInvoices: createAction(constants.CREATE_INVOICES),
  downloadFullExcel: createAction(constants.DOWNLOAD_FULL_EXCEL),

  updateFromCW: createAction(constants.UPDATE_FROM_CW),
  // OWNERS
  loadSelectedOwners: createAction(constants.LOAD_SELECTED_OWNERS),
  setSelectedOwners: createAction(constants.SET_SELECTED_OWNERS),
  addOwner: createAction(constants.ADD_OWNER),
  removeOwner: createAction(constants.REMOVE_OWNER),
  // VESSELS
  loadVessels: createAction(constants.LOAD_VESSELS),
  setVessels: createAction(constants.SET_VESSELS),
  loadSelectedVessels: createAction(constants.LOAD_SELECTED_VESSELS),
  setSelectedVessels: createAction(constants.SET_SELECTED_VESSELS),
  addVessel: createAction(constants.ADD_VESSEL),
  removeVessel: createAction(constants.REMOVE_VESSEL),
  createInvoice: createAction(constants.CREATE_INVOICE),
  downloadExcel: createAction(constants.DOWNLOAD_EXCEL),
  downloadPdf: createAction(constants.DOWNLOAD_PDF),
  // DETAILS
  loadVesselDetails: createAction(constants.LOAD_VESSEL_DETAILS),
  setVesselDetails: createAction(constants.SET_VESSEL_DETAILS),
  updateDetailSum: createAction(constants.UPDATE_DETAIL_SUM),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  search: localStorage.getItem('@SERVICE_FEE/CHANGE_SEARCH') || '',
  // OWNERS
  selectedOwners: [],
  // VESSELS
  vessels: [],
  selectedVessels: [],
  vesselDetails: {},
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state,
    isLoading: payload,
  }),
  // OWNERS
  [constants.SET_SELECTED_OWNERS]: (state, { payload }) => ({
    ...state,
    selectedOwners: payload,
  }),
  // VESSELS
  [constants.SET_VESSELS]: (state, { payload }) => ({
    ...state,
    vessels: payload,
  }),
  [constants.SET_SELECTED_VESSELS]: (state, { payload }) => ({
    ...state,
    selectedVessels: payload,
  }),
  // DETAILS
  [constants.SET_VESSEL_DETAILS]: (state, {payload: { id, data }}) => ({
    ...state,
    vesselDetails: {
      ...state.vesselDetails,
      [id]: data,
    },
  })

});

const newSagas = (baseSagas, constants, actions) => {


  function* updateFromCW({payload: { id, type, sId, cb }}) {
    try {
      if (type === 'statement') yield call(Api.serviceFeeStatement.updateFromCW, id);
      if (type === 'vessel') yield call(Api.serviceFeeStatementVessel.updateFromCW, id);
      if (type === 'detail') yield call(Api.serviceFeeStatementDetails.updateFromCW, id);

      yield put(actions.loadItemRequest(sId));
      yield put(actions.loadSelectedVessels(sId));
      if (cb) yield call(cb);
      yield call(toast, { type: 'success', title: 'Successfully updated!' })
    } catch (e) {
      console.error(e);
      yield call(toast, { type: 'error', title: 'Something went wrong', description: e.message })
    }
  }

  // STATEMENT

  function* createStatement({payload}) {
    try {
      const {data: {data}} = yield call(Api.serviceFeeStatement.create, payload);
      yield put(actions.updateItemStep({ id: data.id, attributes: newStep(data.id, 'header') }))
      yield delay(500);
      yield put(push(serviceFees().edit(data.id)));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* updateItemStep({payload: { id, attributes: payload }}) {
    try {
      yield put(actions.setLoading(true));
      yield call(Api.serviceFeeStatement.update, id, payload);
      yield put(actions.loadItemRequest(id))
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }


  function* createInvoices({payload: {sId, setLoading}}){
    try{
      yield call(Api.serviceFeeStatement.createInvoices, sId)
      yield put(actions.loadSelectedVessels(sId))
      yield call(setLoading, false)
    } catch (e) {
      yield call(setLoading, false)
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  function* downloadFullExcel({payload: {sId, setLoading}}){
    try{
      const { item } = yield select(s => s.serviceFees)
      const title = `Service Fee Statement (${get(item, 'attributes.date')}) for ${get(item, 'attributes.company_name')} `
      const result = yield call(blobRequest, 'api_resources/service_fee/statements', sId, '/excel')
      yield call(saveXlsxAttachment, result, title)
      yield call(setLoading, false)
    } catch (e) {
      yield call(setLoading, false)
      console.error(e);
      // yield put(actions.setError(e));
    }
  }




  // OWNERS

  function* loadSelectedOwners({payload: statementId}) {
    try {
      if (!statementId) return;
      const { data: { data } } = yield call(Api.serviceFeeStatement.owners, statementId)
      yield put(actions.setSelectedOwners(data));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* addOwner({payload: {sId, attributes}}){
    try {
      yield call(Api.serviceFeeStatementOwners.create, attributes)
      yield put(actions.loadSelectedOwners(sId))
    } catch (e) {
      console.error(e);
    }
  }

  function* removeOwner({payload: {sId, soId}}){
    try{
      yield call(Api.serviceFeeStatementOwners.delete, soId)
      yield put(actions.loadSelectedOwners(sId))
    } catch (e) {
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  // VESSELS

  function* loadVessels({payload: {companyId, statementId}}) {
    try {
      if (!statementId) return;
      const { data: { data } } = yield call(Api.companies.vessels, companyId, statementId)
      yield put(actions.setVessels(data));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* loadSelectedVessels({payload: statementId}) {
    try {
      if (!statementId) return;
      const { data: { data } } = yield call(Api.serviceFeeStatement.vessels, statementId)
      yield put(actions.setSelectedVessels(data));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* addVessel({payload: {sId, attributes}}){
    try {
      yield call(Api.serviceFeeStatementVessel.create, attributes)
      yield put(actions.loadSelectedVessels(sId))
    } catch (e) {
      console.error(e);
    }
  }

  function* removeVessel({payload: {sId, svId}}){
    try{
      yield call(Api.serviceFeeStatementVessel.delete, svId)
      yield put(actions.loadSelectedVessels(sId))
    } catch (e) {
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  function* createInvoice({payload: {sId, svId, setLoading}}){
    try{
      yield call(Api.serviceFeeStatementVessel.createInvoice, svId)
      yield put(actions.loadSelectedVessels(sId))
      yield call(setLoading, false)
    } catch (e) {
      yield call(setLoading, false)
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  function* downloadExcel({payload: {svId, setLoading}}){
    try{
      const { item } = yield select(s => s.serviceFees)
      const title = `Service Fee Statement (${get(item, 'attributes.date')}) for ${get(item, 'attributes.company_name')}`
      const result = yield call(blobRequest, 'api_resources/service_fee/statement_vessels', svId, '/excel')
      yield call(saveXlsxAttachment, result, title)
      yield call(setLoading, false)
    } catch (e) {
      yield call(setLoading, false)
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  function* downloadPdf({payload: {svId, setLoading}}){
    try{
      const { item } = yield select(s => s.serviceFees)
      const title = `Service Fee Statement (${get(item, 'attributes.date')}) for ${get(item, 'attributes.company_name')}`
      const result = yield call(blobRequest, 'api_resources/service_fee/statement_vessels', svId, '/pdf')
      yield call(savePdfAttachment, result, title)
      yield call(setLoading, false)
    } catch (e) {
      yield call(setLoading, false)
      console.error(e);
      // yield put(actions.setError(e));
    }
  }


  // DETAILS

  function* loadVesselDetails({payload: id}){
    try{
      const { data: { data } } = yield call(Api.serviceFeeStatementVessel.details, id)
      yield put(actions.setVesselDetails({id, data}))
    } catch (e) {
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  function* updateDetailSum({payload: {sId, svId, id, attributes, setModified}}){
    try{
      yield call(Api.serviceFeeStatementDetails.update, id, attributes)
      yield call(setModified, false);
      yield put(actions.loadSelectedVessels(sId))
      yield put(actions.loadVesselDetails(svId))
    } catch (e) {
      console.error(e);
      // yield put(actions.setError(e));
    }
  }

  return [
    takeEvery(constants.UPDATE_FROM_CW, updateFromCW),
    // Statement
    takeEvery(constants.UPDATE_ITEM_STEP, updateItemStep),
    takeEvery(constants.CREATE_STATEMENT, createStatement),
    takeEvery(constants.CREATE_INVOICES, createInvoices),
    takeEvery(constants.DOWNLOAD_FULL_EXCEL, downloadFullExcel),
    // owners
    takeEvery(constants.LOAD_SELECTED_OWNERS, loadSelectedOwners),
    takeEvery(constants.ADD_OWNER, addOwner),
    takeEvery(constants.REMOVE_OWNER, removeOwner),
    // vessels
    takeEvery(constants.LOAD_VESSELS, loadVessels),
    takeEvery(constants.LOAD_SELECTED_VESSELS, loadSelectedVessels),
    takeEvery(constants.ADD_VESSEL, addVessel),
    takeEvery(constants.REMOVE_VESSEL, removeVessel),
    takeEvery(constants.CREATE_INVOICE, createInvoice),
    takeEvery(constants.DOWNLOAD_EXCEL, downloadExcel),
    takeEvery(constants.DOWNLOAD_PDF, downloadPdf),
    // details
    takeEvery(constants.LOAD_VESSEL_DETAILS, loadVesselDetails),
    takeEvery(constants.UPDATE_DETAIL_SUM, updateDetailSum),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
