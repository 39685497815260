import { handleActions, createAction } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';

export default (constant, resource) => {


  const constants = {
    SEND_MAIL_REQUEST: `@MAILERS/${constant}/SEND_MAIL_REQUEST`,
    SEND_MAIL_CONFIRM: `@MAILERS/${constant}/SEND_MAIL_CONFIRM`,
    GET_MAIL_CONTENT: `@MAILERS/${constant}/GET_MAIL_CONTENT`,
    SET_MAIL_CONTENT: `@MAILERS/${constant}/SET_MAIL_CONTENT`,
    SEND_MAIL: `@MAILERS/${constant}/SEND_MAIL`,
    SEND_MAIL_SUCCESS: `@MAILERS/${constant}/SEND_MAIL_SUCCESS`,
    SEND_MAIL_CANCEL: `@MAILERS/${constant}/SEND_MAIL_CANCEL`,
    SET_MAIL_DATA: `@MAILERS/${constant}/SET_MAIL_DATA`,
    SET_LOADING: `@MAILERS/${constant}/SET_LOADING`,
    SET_ERRORS: `@MAILERS/${constant}/SET_ERRORS`
  };

  const actions = {
    sendMailRequest: createAction(constants.SEND_MAIL_REQUEST),
    sendMailConfirm: createAction(constants.SEND_MAIL_CONFIRM),
    sendMailSuccess: createAction(constants.SEND_MAIL_SUCCESS),
    getMailContent: createAction(constants.GET_MAIL_CONTENT),
    setMailContent: createAction(constants.SET_MAIL_CONTENT),
    sendMail: createAction(constants.SEND_MAIL),
    cancelMail: createAction(constants.SEND_MAIL_CANCEL),
    setMailData: createAction(constants.SET_MAIL_DATA),
    setLoading: createAction(constants.SET_LOADING),
    setErrors: createAction(constants.SET_ERRORS)
  };

  const initialState = {
    documentId: undefined,
    content: undefined,
    mailerName: '',
    showConfirm: false,
    showDialog: false,
    errors: [],
    loading: false,
    financialEmail: undefined,
    shortName: undefined,
  }

  const actionHandlers = {
    [constants.SEND_MAIL_CONFIRM]: (state, { payload: documentId }) => ({ ...state, mailer: { ...initialState, documentId, showConfirm: true } }),
    [constants.SEND_MAIL_REQUEST]: (state, { payload: documentId }) => ({ ...state, mailer: { ...state.mailer, documentId, showDialog: true } }),
    [constants.SEND_MAIL]: (state) => ({
      ...state,
      mailer: { ...state.mailer, loading: true }
    }),
    [constants.GET_MAIL_CONTENT]: (state) => ({ ...state, mailer: { ...state.mailer, loading: true } }),
    [constants.SET_MAIL_CONTENT]: (state, { payload }) => ({ ...state, mailer: { ...state.mailer, loading: false, content: payload } }),
    [constants.SEND_MAIL_SUCCESS]: (state) => ({ ...state, mailer: { ...initialState } }),
    [constants.SEND_MAIL_CANCEL]: (state) => ({ ...state, mailer: { ...initialState } }),
    [constants.SET_MAIL_DATA]: (state, { payload }) => ({ ...state, mailer: { ...state.mailer, ...payload, loading: false } }),
    [constants.SET_LOADING]: (state, action) => ({ ...state, mailer: { ...state.mailer, loading: action.payload } }),
    [constants.SET_ERRORS]: (state, action) => ({ ...state, mailer: { ...state.mailer, errors: [action.payload], loading: false } }),
  }

  const reducer = handleActions(actionHandlers, initialState);

  const selector = (state) => ({
    mailerName: state[resource].mailer.mailerName,
    showDialog: state[resource].mailer.showDialog,
    showConfirm: state[resource].mailer.showConfirm,
    errors: state[resource].mailer.errors,
    content: state[resource].mailer.content,
    loading: state[resource].mailer.loading,
    documentId: state[resource].mailer.documentId,
    financialEmail: state[resource].mailer.financialEmail,
    shortName: state[resource].mailer.shortName
  });


  return { constants, actions, initialState, actionHandlers, reducer, selector };

}
