import React from "react";
import {Provider} from "react-redux";
import ReactDOM from "react-dom/client";
import {ApolloProvider} from "react-apollo";

import App from "./components/App";
import {LocalStorageProvider} from "components/App/LocalStorageContext";
import registerServiceWorker from "./registerServiceWorker";
import client from "./apiClient";

import configureStore from "./store";

import "array-flat-polyfill";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <LocalStorageProvider>
      <Provider store={store}>
        <DevSupport
          ComponentPreviews={ComponentPreviews}
          useInitialHook={useInitial}
        >
          <App/>
        </DevSupport>
      </Provider>
    </LocalStorageProvider>
  </ApolloProvider>,
);
registerServiceWorker();
