import React, {useState, useEffect} from 'react'
import {List, Modal, Button, Form} from 'semantic-ui-react'
import {shouldReload} from "utils/events/invoices";

const styles = {
  backgroundColor: '#16ab39'
}

const ModalAddCharges = (
  {
    wiredStatusCell: {dialogOpened, payments = [], invoiceId, loading},
    addChargesClose,
    addChargesRequest
  }
) => {

  const [currentPayment, setCurrentPayment] = useState(null);
  const [form, setForm] = useState({
    sum_cgs: 0,
    ref_number: '',
    received_at: null
  });

  useEffect(() => {
    if (payments.length === 1) setCurrentPayment(payments[0]);
  }, [payments]);

  const handleValueChange = (e, {name, value}) => setForm(s => ({...s, [name]: value}));

  const handleSubmit = () => addChargesRequest({
    ...form,
    invoiceId,
    paymentId: currentPayment.id
  });

  useEffect(() => {
    if (!dialogOpened) {
      setCurrentPayment(null);
      setForm({
        sum_cgs: 0,
        ref_number: '',
        received_at: null
      });
      shouldReload.dispatch();
    }
  }, [dialogOpened]);

  return (
    <Modal
      open={dialogOpened}
      onClose={addChargesClose}
    >
      <Modal.Header>
        Add charges for {currentPayment && `${currentPayment.title} [${currentPayment.sum} ${currentPayment.currency}]`}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {payments.length > 1 && !currentPayment &&
            <List divided verticalAlign='middle'>
              {
                payments.map(payment => (
                  <List.Item key={payment.id}>
                    <List.Content floated='right'>
                      <Button onClick={() => setCurrentPayment(payment)}>Select</Button>
                    </List.Content>
                    <List.Content>
                      <List.Header>{payment.title}</List.Header>
                      {`${payment.sum} ${payment.currency}`}
                    </List.Content>
                  </List.Item>
                ))
              }
            </List>
          }
          <Form>
            <Form.Group widths='equal'>
              <Form.Input
                fluid
                type="date"
                disabled={!currentPayment}
                label='Received Date'
                name="received_at"
                onChange={handleValueChange}
              />
              <Form.Input
                fluid
                disabled={!currentPayment}
                label='Ref Number'
                name="ref_number"
                onChange={handleValueChange}
              />
              <Form.Input
                fluid
                type="number"
                disabled={!currentPayment}
                label={`Charges sum [${currentPayment && currentPayment.currency}]`}
                name="sum_cgs"
                onChange={handleValueChange}
              />
            </Form.Group>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={addChargesClose}>Close</Button>
        <Button
          positive
          loading={loading}
          disabled={!currentPayment}
          style={styles}
          onClick={handleSubmit}
          labelPosition="right"
          icon="checkmark"
          content='Save'
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ModalAddCharges;
