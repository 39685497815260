import React, { useState, useMemo, useCallback, Fragment, useEffect } from 'react'
import { Button, Modal, Grid } from 'semantic-ui-react'
import EmailForm from '../../shared/EmailForm';

const styles = {
  modal: {
    height: '95%'
  }
}

const ButtonSendEmail = ({
  orderId,
  status,
  content,
  customer,
  loading,
  showDialog,
  getMailContent,
  sendMail,
  sendMailRequest,
  cancelMail,
  emailSent = false
}) => {

  const [loadingButton, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [form, setForm] = useState({
    email: customer?.email || '',
    htmlContent: content
  });

  const setHtmlContent = useCallback((htmlContent) => {
    setForm((state) => ({ ...state, htmlContent }));
  }, []);

  const handleEmailChange = useCallback(({ target: { value } }) => {
    setEmailError(false);
    setForm((state) => ({ ...state, email: value }));
  }, [])

  useEffect(() => {
    setForm(f => ({
      ...f,
      htmlContent: content,
      email: customer?.email || ''
    }))
  }, [content, customer?.email ])

  useEffect(() => {
    if (!active) return;
    getMailContent(orderId);
  }, [active, getMailContent, orderId])

  const onClick = useCallback(() => {
    sendMailRequest();
    setActive(true);
  }, [sendMailRequest]);

  const handleReset = useCallback(() => {
    setActive(false);
    setLoading(false)
  }, []);

  const handleSend = useCallback(() => {
    if (!form.email) return setEmailError(true);
    setLoading(true);
    const cb = () => {
      setActive(false);
      setTimeout(handleReset, 1000)
      setLoading(false);
    }
    sendMail({ cb, documentId: orderId, ...form });
  }, [form, sendMail, orderId, handleReset]);

  const handleCancel = useCallback(() => {
    handleReset();
    cancelMail()
  }, [cancelMail, handleReset]);

  const handleFilesChange = useCallback((files) => {
    setForm((state) => ({ ...state, files }));
  }, []);

  const { shortName, email } = customer;

  const canBeSend = useMemo(() => status === 'ordered', [status])

  if (!canBeSend) {
    return null;
  }

  return (
    <>
      <Button
        basic
        loading={loadingButton && loading}
        icon="mail"
        size="small"
        color={emailSent ? 'green' : null}
        onClick={onClick}
      />
      <Modal
        open={showDialog && active}
        size="large"
        style={styles.modal}
        onClose={handleCancel}
      >
        <Modal.Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width="12">
                E-mail to {shortName}
              </Grid.Column>
              <Grid.Column width="4" textAlign="right">
                <Button onClick={handleCancel} content="Cancel" />
                <Button
                  primary
                  loading={loading}
                  labelPosition="right"
                  icon="send"
                  content='Send'
                  onClick={handleSend}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <EmailForm
            emailError={emailError}
            email={form.email}
            handleEmailChange={handleEmailChange}
            content={content}
            setHtmlContent={setHtmlContent}
            loading={loading}
            setFiles={handleFilesChange}
          />
        </Modal.Content>
      </Modal>
    </>

  )

}

export default ButtonSendEmail;
