import { stopSubmit } from 'redux-form';
import { matchPath } from 'react-router';
import { createAction } from 'redux-actions';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import get from 'lodash/get';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import mailerRedux from '../components/mailers/shared/redux';
import mailerSagas from '../components/mailers/payment_instruction/sagas';
import Api from 'utils/resourcesApi';

import { push, getLocation } from 'connected-react-router';
import { paymentInstructions } from 'utils/paths';

const mailer = mailerRedux('PAYMENT_INSTRUCTION', 'paymentInstructions');

const basePageUrl = '/payment_instructions';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  ...mailer.constants,
  ADD_FILTER_TITLE: '@PAYMENT_INSTRUCTION/ADD_FILTER_TITLE',
  CHANGE_FILTER_MODE: '@PAYMENT_INSTRUCTION/CHANGE_FILTER_MODE',
  ITEM_PAYED_SUCCESSFULLY: '@PAYMENT_INSTRUCTION/ITEM_PAYED_SUCCESSFULLY',
  ITEM_STARRED_SUCCESSFULLY: '@PAYMENT_INSTRUCTION/ITEM_STARRED_SUCCESSFULLY',
  PAY_ITEM_ERROR: '@PAYMENT_INSTRUCTION/PAY_ITEM_ERROR',
  PAY_ITEM_REQUEST: '@PAYMENT_INSTRUCTION/PAY_ITEM_REQUEST',
  SET_NEW_NUMBER_ERROR: '@PAYMENT_INSTRUCTION/SET_NEW_NUMBER_ERROR',
  SET_NEW_NUMBER_REQUEST: '@PAYMENT_INSTRUCTION/SET_NEW_NUMBER_REQUEST',
  SET_NEW_NUMBER_SUCCESSFULLY: '@PAYMENT_INSTRUCTION/SET_NEW_NUMBER_SUCCESSFULLY',
  SET_TOTALS: '@PAYMENT_INSTRUCTION/SET_TOTALS',
  STAR_ITEM_ERROR: '@PAYMENT_INSTRUCTION/STAR_ITEM_ERROR',
  STAR_ITEM_REQUEST: '@PAYMENT_INSTRUCTION/STAR_ITEM_REQUEST',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  ...mailer.actions,
  addFilterTitle: createAction(constants.ADD_FILTER_TITLE),
  changeFilterMode: createAction(constants.CHANGE_FILTER_MODE),
  payItem: createAction(constants.ITEM_PAYED_SUCCESSFULLY),
  payItemError: createAction(constants.PAY_ITEM_ERROR),
  payItemRequest: createAction(constants.PAY_ITEM_REQUEST),
  setNewNumberError: createAction(constants.SET_NEW_NUMBER_ERROR),
  setNewNumberRequest: createAction(constants.SET_NEW_NUMBER_REQUEST),
  setNewNumberSuccessfully: createAction(constants.SET_NEW_NUMBER_SUCCESSFULLY),
  setTotals: createAction(constants.SET_TOTALS),
  starItem: createAction(constants.ITEM_STARRED_SUCCESSFULLY),
  starItemError: createAction(constants.STAR_ITEM_ERROR),
  starItemRequest: createAction(constants.STAR_ITEM_REQUEST),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  search: localStorage.getItem('@PAYMENT_INSTRUCTION/CHANGE_SEARCH') || '',
  isPaying: false,
  mailer: mailer.initialState,
  filterModeActive: false,
  filterTitles: {},
  setNewNumberLoading: false,
  totals: {
    total: 0, paid: 0, unpaid: 0
  }
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  ...mailer.actionHandlers,
  [constants.CHANGE_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, action.payload);
    return commonActionHandlers.changeSearch(state, action);
  },
  [constants.CLEAR_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, '');
    return commonActionHandlers.clearSearch(state, action);
  },
  [constants.PAY_ITEM_REQUEST]: commonActionHandlers.startPushing,
  [constants.ITEM_PAYED_SUCCESSFULLY]: (state) => ({
    ...state,
    isPushing: false,
    isPaying: false
  }),
  [constants.PAY_ITEM_ERROR]: commonActionHandlers.pushingError,
  [constants.STAR_ITEM_REQUEST]: commonActionHandlers.startPushing,
  [constants.ITEM_STARRED_SUCCESSFULLY]: (state) => ({
    ...state,
    isPushing: false
  }),
  [constants.STAR_ITEM_ERROR]: commonActionHandlers.pushingError,
  [constants.CHANGE_FILTER_MODE]: (state) => ({
    ...state,
    filterModeActive: !state.filterModeActive
  }),
  [constants.ADD_FILTER_TITLE]: (state, { payload: { field, value, title } }) => ({
    ...state,
    filterTitles: {
      ...state.filterTitles,
      [field]: {
        ...state.filterTitles[field],
        [value]: title
      }
    }
  }),
  [constants.SET_TOTALS]: (state, { payload }) => ({
    ...state,
    totals: payload
  }),
  [constants.SET_NEW_NUMBER_REQUEST]: (state) => ({ ...state, setNewNumberLoading: true }),
  [constants.SET_NEW_NUMBER_SUCCESSFULLY]: (state) => ({
    ...state,
    setNewNumberLoading: false,
  }),
  [constants.SET_NEW_NUMBER_ERROR]: (state, { payload }) => ({
    ...state,
    setNewNumberLoading: false,
    error: payload
  }),
});

const newSagas = (baseSagas, constants, actions) => {
  function* payItem({ payload }) {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/pay` });

      yield call(Api.paymentInstructions.pay, id, payload);
      yield call(baseSagas.fetchItems);
      yield put(push(paymentInstructions().index()));
      yield put(actions.sendMailConfirm(id))
    } catch (e) {
      console.error(e);
      yield put(actions.payItemError(e));

      if (e.errorByField) yield put(stopSubmit('paymentInstructionPayment', e.errorByField));
    }
  }

  function* starItem({ payload }) {
    try {
      yield call(Api.paymentInstructions.star, payload);
      yield call(baseSagas.fetchItems);
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* setNewNumber() {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/edit` });
      yield call(Api.paymentInstructions.setNewNumber, id);
      yield put(actions.loadItemRequest(id));
      yield put(actions.setNewNumberSuccessfully());
    } catch (e) {
      console.error(e);
      yield put(actions.setNewNumberError(e.message));
    }
  }



  function* changeCompanies() {
    try {
      yield put(actions.loadItemsRequest());
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* setTotals({ payload }) {
    try {
      const totals = get(payload, 'data.meta.totals');
      yield put(actions.setTotals(totals));
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }


  return [
    takeEvery(constants.PAY_ITEM_REQUEST, payItem),
    takeEvery(constants.STAR_ITEM_REQUEST, starItem),
    takeEvery("@SETTINGS/GLOBAL/SET_PI_COMPANIES", changeCompanies),
    takeEvery(constants.ITEMS_LOADED_SUCCESSFULLY, setTotals),
    takeEvery(constants.SET_NEW_NUMBER_REQUEST, setNewNumber),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
    ...mailerSagas()
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  filterModeActive: state.paymentInstructions.filterModeActive,
  filterTitles: state.paymentInstructions.filterTitles,
  totals: state.paymentInstructions.totals,
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
