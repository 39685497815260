/**
 * Module shouldReload
 * @module shouldReload
 */

/**
 * The shouldReload object is responsible for setting up an event listener
 * on the window object for a specific event and allows to dispatch an
 * event after a specified delay.
 *
 * @type {Object}
 *
 * @property {string} eventName - The name of the event to listen and dispatch.
 *
 * @property {function} subscribe - The function to set up a listener for the event.
 * The function receives a callback that is invoked whenever the dispatch function
 * triggers the event.
 *
 * @property {function} dispatch - The function to trigger the specified event.
 * It accepts a delay parameter (defaults to 1ms) which is used to delay the event dispatch.
 */

export const shouldReload = {
  eventName: 'SHOULD_INVOICES_RELOAD',

  /**
   * Sets an event listener on the window object for the event specified by eventName.
   *
   * @param {function} callback - The function to be invoked when the event is dispatched.
   */
  subscribe(callback) {
    window.addEventListener(this.eventName, () => {
      callback();
    })
  },

  /**
   * Dispatches an event specified by eventName after a given delay.
   *
   * @param {number} [delay=1] - The delay (in ms) after which the event is dispatched.
   */
  dispatch(delay = 1) {
    setTimeout(() => {
      window.dispatchEvent(new Event(this.eventName));
    }, delay);
  }
}
