import React, {useCallback, useMemo, useEffect} from "react";
import { Segment, Grid, Checkbox } from 'semantic-ui-react'

import Layout from 'components/pages/Layout';

import './styles.scss'

import Card from "./Card";

import UsersSelect from './UsersSelect';

const colors = [
  'red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'
];

const Dashboard = ({ data, fetchDashboard, loading }) => {

  const colorsSet = useMemo(() => colors, [colors])

  const randColor = useCallback(() => {
    return colorsSet[Math.floor(Math.random() * colorsSet.length)];
  }, [colorsSet])

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <Segment.Group>
      <Segment size="tini" className='dashboard-page' loading={ loading }>
        <Grid>
          <Grid.Row centered>
            { loading ?

              [1,2,3,4,5].map(index => (
                <Grid.Column key={index} width="3" className="card-column">
                  <Card color={ randColor() } type="loading_card" />
                </Grid.Column> 
              )):

              data.map(item => (
                <Grid.Column key={ item.id } width="3" className="card-column">
                  <Card 
                    key={ item.id }
                    type={ item.attributes.card_type } 
                    item={ item }
                  />
                </Grid.Column>
              ))
            }
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment.Group>
  )
}

export default Layout(Dashboard);
