import React, {useCallback, useState, useMemo, useEffect} from "react";

import {Popup, Icon} from 'semantic-ui-react';
import Content from "features/components/linked-documents-popup-content/PopupContent";

const LinkedInvoices = ({invoiceItem, loadLinkedPO, unlinkPO, data, iiId}) => {

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const trigPopup = useCallback(() => {
    setShow(!show)
  }, [show, setShow]);

  const trigger = useMemo(() => {
    return invoiceItem.poiId ?
      <span onClick={trigPopup} className="popup-linked-documents-trigger">✔</span> :
      '';
  }, [trigPopup, invoiceItem.poiId]);

  useEffect(() => {
    if (show && invoiceItem.id) {
      setLoading(true);
      loadLinkedPO({
        iiId: invoiceItem.id,
        setLoading
      });
    }
  }, [show, invoiceItem.id])

  useEffect(() => {
    if (iiId && iiId !== invoiceItem.id) {
      setShow(false);
    }
  }, [iiId, invoiceItem.id])

  return (
    <Popup
      className="popup-linked-documents"
      trigger={trigger}
      open={show}
    >
      <Popup.Header style={{textAlign: 'right'}}>
        <Icon name="close" onClick={trigPopup}/>
      </Popup.Header>
      <Popup.Content>
        <Content
          loading={loading}
          trigPopup={trigPopup}
          data={data}
          setLoading={setLoading}
          unlinkRow={unlinkPO}
          itemId={invoiceItem.id}
        />
      </Popup.Content>
    </Popup>
  )
}

export default LinkedInvoices;