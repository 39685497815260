import { connect } from 'react-redux';
import component from './component';
import { actions, selector, constants } from './redux';
import sagas from './sagas';

const mapDispatchToProps = {
  setTotals: (data) => actions.setTotals(data),
  fetchTotals: () => actions.fetchTotals(),
  trigTotalsType: () => actions.setTotalsType()
};

export { actions, constants, sagas };

export default connect(selector, mapDispatchToProps)(component);

