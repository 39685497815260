import isEmpty from "lodash/isEmpty";
import * as api from "../api/adapters/json_api";

const type = "quotations";
const path = `api_resources/${type}`;

export default {
  /**
   * getQuotation
   * @returns {Promise<*>}
   */
  show: (id) => api.read({ type, id }, {}),

  index: (attributes) => {
    const { filter } = attributes;
    return api.read(
      { type: path },
      {
        query: {
          filter: {
            ...filter,
          },
        },
      }
    );
  },
};
