import * as api from '../api/adapters/json_api';
import {update} from "../api/adapters/json_api";

const type = 'api_resources/service_fee/statement_vessels';

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (pagination, sort, search, filter, { global, paymentInstructions }) => api.read({ type }, {
    query: {
      page: { size: pagination.itemsPerPage, number: pagination.activePage }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

  details: (id) => api.read({ type, id, action: 'details' }, {}),

  createInvoice: (id) => api.update({ type, id, action: 'create_invoice' }, {}, {}),

  updateFromCW: (id) => api.update({ type, id, action: 'update_from_cw' }, {}, {}),
}
