import React from 'react';

import connect from '../reduxConnect';

import { Confirm } from 'semantic-ui-react';
import { useCallback } from 'react';


const ConfirmDelete = ({ deleteItem, cancelDelete, title, showConfirm, type, pk, afterDestroy }) => {

  const handleDelete = useCallback(() => {
    deleteItem({ type, pk, afterDestroy })
  }, [type, pk]);

  return (
    <Confirm
      open={showConfirm}
      onCancel={cancelDelete}
      onConfirm={handleDelete}
      header={`Delete ${title}`}
      size="mini"
    />
  );

};

export default connect(ConfirmDelete);
