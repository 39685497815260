import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  loadLinkedInvoices: actions.loadLinkedInvoices,
  unlinkInvoice: actions.unlinkInvoice,
};


export default connect(selector, mapDispatchToProps);
