import React, { createContext } from 'react';

import store from './store';

export { store };

export const Context = createContext();

export const withContext = (Component) => ({ context: outerContext = {}, ...props }) => (
  <Context.Consumer>
    { (context) => <Component { ...props } context={{ ...outerContext, ...context }} /> }
  </Context.Consumer>
);
