import * as api from '../api/adapters/json_api';

const type = 'reports';

export default {
  show:  (id, format = 'json', params) => api.read(
    { type, id: `${id}.${format}` },
    {query: params}
  )
}
