import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  fetchDashboard: actions.fetchDashboard,
  setBenchmark: (data) => actions.setBenchmark(data),
  setDashboard: (data) => actions.setDashboard(data),
  setSelectedUsers: (data) => actions.setSelectedUsers(data)
};


export default connect(selector, mapDispatchToProps);
