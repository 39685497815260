import * as api from '../api/adapters/json_api';

/**
 * @constant {string} type - The type of the API resource.
 */
const type = 'api_resources/status_histories';

/**
 * An arrow function that reads the API resource through the api module.
 * @function index
 * @param {object} filter - Filters to apply when reading the API resource.
 * @returns {object} An object containing the type of the API resource and an object with the filter and the sort by date.
 * @example
 * // sort and filter the status histories
 * index({ document_type: 'PurchaseOrder', document_id: '2000', status_type: 'status' })
 */
export default {
  index:  (filter) => api.read(
    { type },
    {
      query: {
        filter,
        sort: '-date'
      }
    }),
}
