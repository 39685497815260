import React, {useEffect, useState} from "react";
import {Button, Icon, List, Popup} from "semantic-ui-react";

const styles = {
  listItem: {
    textAlign: "center",
    backgroundColor: "#f4f4f4",
    paddingLeft: "5px",
    paddingRight: "5px",
    margin: "5px",
    marginRight: "10px",
    borderRadius: "10px"
  },
  listItemHeader: {
    maxWidth: "100px",
    height: "32px",
    overflow: "hidden",
  }
}

const fileNamePresentation = (fileName) => {
  if(fileName.length < 12) return `${fileName}          `;
  if(fileName.length < 20) return fileName;

  const nameArr = fileName.split('.');

  const ext = nameArr.pop();
  const name = nameArr.join('.');

  return `${name.slice(0, 20)}...${ext}`;
}

// function for file size presentation
const fileSizePresentation = (size) => {
  if(size < 1024) return `${size} bytes`;
  if(size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  if(size < 1024 * 1024 * 1024) return `${(size / 1024 / 1024).toFixed(2)} MB`;
  return `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
}

const FileAttachment = ({setFiles: setMailFile}) => {
  const [files, setFiles] = useState([]);

  const handleAddFile = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;

    input.onchange = () => {
      setFiles([...files, ...input.files]);
    };

    input.click();
  };

  const handleDeleteFile = (file_name) => {
    setFiles(files.filter((file) => file_name !== file.name));
  };

  useEffect(() => {
    setMailFile(files);
  }, [files]);

  const renderFileList = () => {
    return (
      <List horizontal>
        {files.map((file, index) => (
          <List.Item key={file.name} style={styles.listItem}>
            <Icon.Group>
              <Icon name="file outline" size="massive" />
              <Button
                color="red"
                className="corner top right"
                icon="x"
                onClick={() => handleDeleteFile(file.name)}
                size="small"
                circular
              />
            </Icon.Group>
            <List.Content>
              <List.Header style={styles.listItemHeader}>
                <Popup
                  content={file.name}
                  trigger={<span>{fileNamePresentation(file.name)}</span>}
                />
              </List.Header>
              <List.Description>{fileSizePresentation(file.size)}</List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    );
  };

  return (
    <div>
      <Button onClick={handleAddFile}>Add file</Button>
      <hr />
      {files.length > 0 ? renderFileList() : null}
    </div>
  );
};

export default FileAttachment;
