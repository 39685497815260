/**
 * @module reduxSagas
 */
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from 'utils/resources/companyMentions';

/**
 * The loadList function fetches data from the API based on current state values of
 * pagination, search, and filter parameters from the company mentions state.
 * On successful API call, it dispatches loadListSuccess and setPagesStat actions with the fetched data.
 * On failure, it dispatches loadListFailure action with the error.
 * @function loadList
 * @generator
 * @yields {select} - A Redux saga select effect to retrieve state values.
 * @yields {call} - A Redux saga call effect to call an API.
 * @yields {put} - A Redux saga put effect to dispatch an action to the Redux store.
 */
export function* loadList() {
  try {
    const { pagination, sort, search, filter } = yield select(state => state.companyMentions);
    const { data: { data, meta } } = yield call(Api.index, { pagination, sort, search, filter });
    yield put(actions.loadListSuccess(data));
    yield put(actions.setPagesStat(meta));
  } catch (error) {
    yield put(actions.loadListFailure(error))
  }
}

/**
 * getFeatureSagas hooks the loadList saga to LOAD_LIST_REQUEST action using takeEvery helper from redux-saga.
 * @function getFeatureSagas
 * @returns {IterableIterator<ForkEffect<never>>} Iterator for ForkEffect.
 */
export default function getFeatureSagas() {
  return [
    takeEvery([constants.LOAD_LIST_REQUEST], loadList),
  ];
}
