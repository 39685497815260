import {createAction} from 'redux-actions';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  LOAD_DETAILS_REQUEST: '@COMPANIES/BALANCE/LOAD_DETAILS_REQUEST',
  LOAD_DETAILS_SUCCESS: '@COMPANIES/BALANCE/LOAD_DETAILS_SUCCESS',
  LOAD_DETAILS_FAILURE: '@COMPANIES/BALANCE/LOAD_DETAILS_FAILURE',
  RECALCULATE_BALANCE_REQUEST: '@COMPANIES/BALANCE/RECALCULATE_BALANCE_REQUEST',
  RECALCULATE_BALANCE_SUCCESS: '@COMPANIES/BALANCE/RECALCULATE_BALANCE_SUCCESS',
  RECALCULATE_BALANCE_FAILURE: '@COMPANIES/BALANCE/RECALCULATE_BALANCE_FAILURE',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  loadDetails: createAction(constants.LOAD_DETAILS_REQUEST),
  loadDetailsSuccess: createAction(constants.LOAD_DETAILS_SUCCESS),
  loadDetailsFailure: createAction(constants.LOAD_DETAILS_FAILURE),
  recalculateBalance: createAction(constants.RECALCULATE_BALANCE_REQUEST),
  recalculateBalanceSuccess: createAction(constants.RECALCULATE_BALANCE_SUCCESS),
  recalculateBalanceFailure: createAction(constants.RECALCULATE_BALANCE_FAILURE),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  balanceDetails: [],
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.LOAD_DETAILS_FAILURE]: (state, action) => ({...state, error: action.payload}),
  [constants.LOAD_DETAILS_SUCCESS]: (state, action) => ({...state, error: null, balanceDetails: action.payload}),
  [constants.RECALCULATE_BALANCE_FAILURE]: (state, action) => ({...state, error: action.payload}),
});

const newSagas = (baseSagas, constants, actions) => {

  return [];
};


const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  balanceDetails: state.balanceDetails
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  newSagas,
  extendedSelectors
};
