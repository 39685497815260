import React, {useCallback, useState} from "react";
import {List, Confirm} from "semantic-ui-react";


const LinkedRowItem = ({itemRow, itemId, unlinkRow, setLoading}) => {

  const [showConfirm, setShowConfirm] = useState(false);

  const openConfirm = useCallback(() => setShowConfirm(true), []);
  const hideConfirm = useCallback(() => setShowConfirm(false), []);

  const unlinkItem = useCallback(() => {
    unlinkRow({ linkedItemId: itemRow.id, itemId, setLoading});
    hideConfirm();
  }, [unlinkRow, itemRow.id, hideConfirm]);

  return(
    <List.Item>
      <List.Icon name='unlink' onClick={openConfirm} className="linked-document-unlink-icon"/>
      <Confirm
        open={showConfirm}
        onCancel={hideConfirm}
        onConfirm={unlinkItem}
        content={`Are you sure to UNLINK ${itemRow.data} ?`}
      />
      <List.Content>
        {itemRow.data}
      </List.Content>
    </List.Item>
  )
}

export default LinkedRowItem;