import React from 'react';

import LoadingCard from './LoadingCard';
import CardWithValue from './CardWithValue';
import CardWithMultiValue from './CardWithMultiValue';
import CardTwoMultiValue from './CardTwoMultiValue';


const Card = ({ type, color, ...props }) => {
  switch(type) {
    case 'actual_with_budgeted': 
      return <CardWithValue { ...props } />
    case 'values': 
      return <CardWithMultiValue { ...props } />
    case 'double_values': 
      return <CardTwoMultiValue { ...props } />
    default: 
      return <LoadingCard { ...props } />
  }
}

export default Card;
