import {
  acBudgetItems,
  attachments,
  paymentInstructions,
  deliveryNotes,
  deliveryNoteItems,
  companies,
  companyBalances,
  countries,
  cities,
  banks,
  invoices,
  currencies,
  users,
  employees,
  payrollEmployees,
  payrolls,
  payrollBankDetails,
  payrollDetails,
  serviceFeeStatement,
  partnerSchemesStatement,
  partnerSchemesPeriods,
  partnerSchemesDetails,
  partnerSchemesExcludedInvoices,
  purchaseOrders,
  employeeBankAccounts,
  serviceFeeStatementVessel,
  serviceFeeStatementOwners,
  serviceFeeStatementDetails,
  requisitionLists,
  quotations,
} from './resources';

export default {
  acBudgetItems,
  attachments,
  paymentInstructions,
  deliveryNotes,
  deliveryNoteItems,
  invoices,
  companies,
  companyBalances,
  countries,
  cities,
  banks,
  currencies,
  users,
  employees,
  payrollEmployees,
  payrolls,
  payrollBankDetails,
  payrollDetails,
  employeeBankAccounts,
  purchaseOrders,
  partnerSchemesStatement,
  partnerSchemesPeriods,
  partnerSchemesDetails,
  partnerSchemesExcludedInvoices,
  serviceFeeStatement,
  serviceFeeStatementVessel,
  serviceFeeStatementOwners,
  serviceFeeStatementDetails,
  requisitionLists,
  quotations,
};
