import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import Api from 'utils/resourcesApi';
import {createAction} from "redux-actions";
import {stopSubmit} from "redux-form";
import {matchPath} from "react-router-dom";
import { push, getLocation } from 'connected-react-router'
import { deliveryNotes } from 'utils/paths';


const basePageUrl = '/delivery_notes';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  LOAD_ITEM_ROWS_REQUEST: '@DELIVERY_NOTE/LOAD_ITEM_ROWS_REQUEST',
  LOAD_ITEM_ROWS_SUCCESSFUL: '@DELIVERY_NOTE/LOAD_ITEM_ROWS_SUCCESSFUL',
  CLEAR_ITEM_ROWS: '@DELIVERY_NOTE/CLEAR_ITEM_ROWS',
  SET_LOADING_ITEM_ATTACHMENTS: '@DELIVERY_NOTE/SET_LOADING_ITEM_ATTACHMENTS',
  LOAD_ITEM_ATTACHMENTS_REQUEST: '@DELIVERY_NOTE/LOAD_ITEM_ATTACHMENTS_REQUEST',
  LOAD_ITEM_ATTACHMENTS_SUCCESSFUL: '@DELIVERY_NOTE/LOAD_ITEM_ATTACHMENTS_SUCCESSFUL',
  CLEAR_ITEM_ATTACHMENTS: '@DELIVERY_NOTE/CLEAR_ITEM_ATTACHMENTS',
  CREATE_ITEM_BG_REQUEST: `@DELIVERY_NOTE/CREATE_ITEM_BG_REQUEST`,
  UPDATE_ITEM_BG_REQUEST: `@DELIVERY_NOTE/UPDATE_ITEM_BG_REQUEST`,
  ADD_ORDER_REQUEST: '@DELIVERY_NOTE/ADD_ORDER_REQUEST',
  ADD_ORDER_SUCCESSFUL: '@DELIVERY_NOTE/ADD_ORDER_SUCCESSFUL',
  REMOVE_ORDER_REQUEST: '@DELIVERY_NOTE/REMOVE_ORDER_REQUEST',
  REMOVE_ORDER_SUCCESSFUL: '@DELIVERY_NOTE/REMOVE_ORDER_SUCCESSFUL',
  DELETE_ATTACHMENT_REQUEST: '@DELIVERY_NOTE/DELETE_ATTACHMENT_REQUEST',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  loadItemRowsRequest: createAction(constants.LOAD_ITEM_ROWS_REQUEST),
  loadItemRows: createAction(constants.LOAD_ITEM_ROWS_SUCCESSFUL),
  clearItemRows: createAction(constants.CLEAR_ITEM_ROWS),
  setLoadingItemAttachments: createAction(constants.SET_LOADING_ITEM_ATTACHMENTS),
  loadItemAttachmentsRequest: createAction(constants.LOAD_ITEM_ATTACHMENTS_REQUEST),
  loadItemAttachments: createAction(constants.LOAD_ITEM_ATTACHMENTS_SUCCESSFUL),
  clearItemAttachments: createAction(constants.CLEAR_ITEM_ATTACHMENTS),
  createItemBGRequest: createAction(constants.CREATE_ITEM_BG_REQUEST),
  updateItemBGRequest: createAction(constants.UPDATE_ITEM_BG_REQUEST),
  addOrderRequest: createAction(constants.ADD_ORDER_REQUEST),
  addOrder: createAction(constants.ADD_ORDER_SUCCESSFUL),
  removeOrderRequest: createAction(constants.REMOVE_ORDER_REQUEST),
  removeOrder: createAction(constants.REMOVE_ORDER_SUCCESSFUL),
  deleteAttachmentRequest: createAction(constants.DELETE_ATTACHMENT_REQUEST),

});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  search: localStorage.getItem('@DELIVERY_NOTE/CHANGE_SEARCH') || '',
  itemRows: [],
  itemAttachments: [],
  itemRowsLoading: false,
  itemAttachmentsLoading: false,
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.CHANGE_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, action.payload);
    return commonActionHandlers.changeSearch(state, action);
  },
  [constants.CLEAR_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, '');
    return commonActionHandlers.clearSearch(state, action);
  },
  [constants.LOAD_ITEM_ROWS_REQUEST]: (state) => ({
    ...state,
    itemRowsLoading: true,
    itemRows: []
  }),
  [constants.LOAD_ITEM_ROWS_SUCCESSFUL]: (state, { payload }) => ({
    ...state,
    itemRowsLoading: false,
    itemRows: payload
  }),
  [constants.CLEAR_ITEM_ROWS]: (state) => ({
    ...state,
    itemRows: []
  }),
  [constants.LOAD_ITEM_ATTACHMENTS_REQUEST]: (state) => ({
    ...state,
    itemAttachments: []
  }),
  [constants.SET_LOADING_ITEM_ATTACHMENTS]: (state) => ({
    ...state,
    itemAttachmentsLoading: true
  }),
  [constants.LOAD_ITEM_ATTACHMENTS_SUCCESSFUL]: (state, { payload }) => ({
    ...state,
    itemAttachmentsLoading: false,
    itemAttachments: payload
  }),
  [constants.CLEAR_ITEM_ATTACHMENTS]: (state) => ({
    ...state,
    itemAttachments: []
  }),
});

const newSagas = (baseSagas, constants, actions) => {

  function* loadItemRows({payload: itemId}) {
    try {
      if (!itemId) return;
      const data = yield call(Api.deliveryNoteItems.index, {delivery_note_id: itemId})
      yield put(actions.loadItemRows(data.data))
    } catch (e) {
      console.error(e);
    }
  }

  function* loadItemAttachments({payload: {id, model, withoutLoading = false}}) {
    try {
      if (!id) return;
      if (!withoutLoading) yield put(actions.setLoadingItemAttachments(true));
      const data = yield call(Api.attachments.index, {record_id: id, record_type: model})
      yield put(actions.loadItemAttachments(data.data))
    } catch (e) {
      console.error(e);
    }
  }

  function* createItem({ payload }) {
    try {
      const {data} = yield call(Api.deliveryNotes.create, payload);
      yield put(push(deliveryNotes().edit(data.data.id)));
    } catch (e) {
      console.error(e);
      yield put(actions.createItemError(e));

      if (e.errorByField) yield put(stopSubmit('deliveryNotes', e.errorByField));
    }
  }

  function* updateItem({ payload }) {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/edit` });

      yield call(Api.deliveryNotes.update, id, payload);
      yield put(actions.loadItemsRequest());
      yield put(actions.loadItemRequest(id))
      yield put(actions.loadItemRowsRequest(id));
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));

      if (e.errorByField) yield put(stopSubmit('deliveryNotes', e.errorByField));
    }
  }

  function* addOrderToDN({payload}) {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/edit` });

      yield call(Api.deliveryNotes.add_order, id, payload);
      yield put(actions.loadItemRequest(id));
      yield call(loadItemRows, {payload: id});
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));

      if (e.errorByField) yield put(stopSubmit('deliveryNotes', e.errorByField));
    }
  }

  function* removeOrderFromDN({payload}) {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/edit` });

      yield call(Api.deliveryNotes.remove_order, id, payload);
      yield put(actions.loadItemRequest(id));
      yield call(loadItemRows, {payload: id});
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));

      if (e.errorByField) yield put(stopSubmit('deliveryNotes', e.errorByField));
    }
  }



  function* deleteAttachment({payload: {itemId, id, model}}) {
    try {
      if (!itemId) return;
      yield call(Api.attachments.delete, itemId)
      yield loadItemAttachments({payload: {id, model}})
    } catch (e) {
      console.error(e);
    }
  }


  return [
    takeEvery(constants.LOAD_ITEM_ROWS_REQUEST, loadItemRows),
    takeEvery(constants.LOAD_ITEM_ATTACHMENTS_REQUEST, loadItemAttachments),
    takeEvery(constants.CREATE_ITEM_BG_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_BG_REQUEST, updateItem),
    takeEvery(constants.ADD_ORDER_REQUEST, addOrderToDN),
    takeEvery(constants.REMOVE_ORDER_REQUEST, removeOrderFromDN),
    takeEvery(constants.DELETE_ATTACHMENT_REQUEST, deleteAttachment),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  itemRows: state.deliveryNotes.itemRows
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
