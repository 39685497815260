import { handleActions, createAction } from 'redux-actions';

export const constants = {
  SHOW_STATEMENT_MODAL: '@REPORTS/STATEMENT/SHOW_STATEMENT_MODAL',
  HIDE_STATEMENT_MODAL: '@REPORTS/STATEMENT/HIDE_STATEMENT_MODAL',
  SET_LOADING: '@REPORTS/STATEMENT/SET_LOADING',
  SET_DATA: '@REPORTS/STATEMENT/SET_DATA',
  SET_PARAMS: '@REPORTS/STATEMENT/SET_PARAMS',
  SET_ERRORS: '@REPORTS/STATEMENT/SET_ERRORS',
  TOTAL_STATEMENT_REQUEST: '@REPORTS/STATEMENT/TOTAL_STATEMENT_REQUEST',
  COMPANY_STATEMENT_DOWNLOAD: '@REPORTS/STATEMENT/COMPANY_STATEMENT_DOWNLOAD'
}

export const actions = {
  showStatementModal: createAction(constants.SHOW_STATEMENT_MODAL),
  hideStatementModal: createAction(constants.HIDE_STATEMENT_MODAL),
  setLoading: createAction(constants.SET_LOADING),
  setData: createAction(constants.SET_DATA),
  setParams: createAction(constants.SET_PARAMS),
  setErrors: createAction(constants.SET_ERRORS),
  totalStatementRequest: createAction(constants.TOTAL_STATEMENT_REQUEST),
  companyStatementDownload: createAction(constants.COMPANY_STATEMENT_DOWNLOAD),
}

export const initialState = {
  data: {},
  showModal: false,
  params: {},
  loading: false,
}

export const actionHandlers = {
  [constants.SHOW_STATEMENT_MODAL]: (state) => ({...state, showModal: true}),
  [constants.HIDE_STATEMENT_MODAL]: (state) => ({...state, showModal: false, data: {}}),
  [constants.SET_LOADING]: (state, { payload }) => ({...state, loading: payload }),
  [constants.SET_DATA]: (state, { payload }) => ({...state, data: payload }),
  [constants.SET_PARAMS]: (state, { payload }) => ({...state, params: payload }),
  [constants.SET_ERRORS]: (state, { payload }) => ({...state, errors: payload }),
}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state['reports']['statement'].data,
  showModal: state['reports']['statement'].showModal,
  params: state['reports']['statement'].params,
  loading: state['reports']['statement'].loading,
  errors: state['reports']['statement'].errors,
});