import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/payroll_bank_details';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-period' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, { }),

  index: (pagination, sort, search, filter, { global }) => api.read({ type }, {
    query: {
      sort: sortOptions(sort),
      filter: {
        ...filter,
        // company_id: (global.piCompanies || []).join(','),
        // period_filter: { ...paymentInstructions },
      },
      page: { size: pagination.itemsPerPage, number: pagination.activePage }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  split: (id, attributes) => api.update({ id, type, action: 'split' }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

}
