import { constants } from './redux';
import {takeEvery, call, put} from "@redux-saga/core/effects";
import { push } from 'connected-react-router';
import requisitionListsApi from 'utils/resources/requisitionLists';
import { actions } from './redux';
import {clearSelectedRLTenders} from "../utils/hooks";
import {notifyError} from "../../../utils/toastNotify";


/**
 * Saga to create a new tender.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* createTender ({ payload }) {
  try {
    const response = yield call(requisitionListsApi.createTender, payload);
    const id = response.data.data.id;
    yield put(actions.createTenderSuccess(response.data.data));
    yield put(push(`/requisition_lists/tenders/${id}`));
    yield call(clearSelectedRLTenders);
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }
}

/**
 * Saga to create a new tender item.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* createTenderItem ({ payload }) {
  try {
    const tenderId = payload.tender_id;
    yield call(requisitionListsApi.createTenderItem, payload);
    yield loadTenderTable({ payload: { tenderId } });
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }
}


/**
 * Saga to update a tender item.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* updateTenderItem ({ payload: { id, tenderId, payload } }) {
  try {
    yield call(requisitionListsApi.updateTenderItem, id, payload);
    yield loadTenderTable({ payload: { tenderId } });
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }
}

/**
 * Saga to delete a tender item.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* deleteTenderItem ({ payload: { tenderId, tenderItemId } }) {
  try {
    yield call(requisitionListsApi.deleteTenderItem, tenderItemId);
    yield loadTenderTable({ payload: { tenderId } });
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }
}

/**
 * Saga to bulk create tender items.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* bulkCreateTenderItem ({ payload: { tenderId, data } }) {
  try {
    yield call(requisitionListsApi.bulkCreateTenderItems, { data });
    yield loadTenderTable({ payload: { tenderId } });
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }
}

/**
 * Saga to load the tender table.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* loadTenderTable({ payload: { tenderId } }){
  try {
    const response = yield call(requisitionListsApi.tenderTable, tenderId);
    yield put(actions.loadTenderTableSuccess(response.data));
  } catch (error) {
    console.error(error)
  }
}

/**
 * Saga to generate a purchase order.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* generatePO({ payload: { tenderId, data } }) {
  try {
    yield call(requisitionListsApi.generatePO, { tenderId, data });
    yield loadTenderTable({ payload: { tenderId } });
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }

}

/**
 * Saga to remove a supplier.
 *
 * @param {Object} payload - The payload object.
 * @yields {Generator} A Redux Saga generator.
 */
function* removeSupplier({ payload: { tenderId, supplierId } }) {
  try {
    yield call(requisitionListsApi.removeSupplier, { tenderId, supplierId });
    yield loadTenderTable({ payload: { tenderId } });
  } catch (error) {
    console.error(error)
    yield call(notifyError, "Something went wrong", error.message);
  }

}

/**
 * Returns an array of sagas to be run.
 *
 * @returns {Array} An array of sagas.
 */
export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.CREATE_TENDER_REQUEST
    ], createTender),
    takeEvery([
      constants.CREATE_TENDER_ITEM_REQUEST
    ], createTenderItem),
    takeEvery([
      constants.BULK_CREATE_TENDER_ITEM_REQUEST
    ], bulkCreateTenderItem),
    takeEvery([
      constants.UPDATE_TENDER_ITEM_REQUEST
    ], updateTenderItem),
    takeEvery([
      constants.DELETE_TENDER_ITEM_REQUEST
    ], deleteTenderItem),
    takeEvery([
      constants.LOAD_TENDER_TABLE_REQUEST
    ], loadTenderTable),
    takeEvery([
      constants.GENERATE_PO_REQUEST
    ], generatePO),
    takeEvery([
      constants.REMOVE_SUPPLIER_REQUEST
    ], removeSupplier),
  ];
}
