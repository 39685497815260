
import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import {createAction} from "redux-actions";
import {put, takeEvery, select, call} from "@redux-saga/core/effects";

import Api from 'utils/resourcesApi';

import {actions as psStatementActions} from 'features/partner-schemes/component/Statements/utils/redux';
import {actions as psPeriodActions} from 'features/partner-schemes/component/Periods/utils/redux';
import {actions as psExcludedInvoicesActions} from 'features/partner-schemes/component/ExcludedInvoices/utils/redux';
import get from "lodash/get";
import {blobRequest, saveXlsxAttachment} from "utils/api/attachments";

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  EXCLUDE_INVOICE: '@PARTNER_SCHEMES_DETAILS/EXCLUDE_INVOICE',
  DOWNLOAD_EXCEL: '@PARTNER_SCHEMES_DETAILS/DOWNLOAD_EXCEL',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  excludeInvoice: createAction(constants.EXCLUDE_INVOICE),
  downloadExcel: createAction(constants.DOWNLOAD_EXCEL),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,

});

const newSagas = (baseSagas, constants, actions) => {

  function* reloadData() {
    yield put(psStatementActions.loadItemsRequest());
    yield put(psPeriodActions.loadItemsRequest());
    yield put(actions.loadItemsRequest());
    yield put(psExcludedInvoicesActions.loadItemsRequest());
  }

  function* excludeInvoice({ payload:  {invoice_id, cb} }) {
    try {
      const period = yield select(s => s.partnerSchemesPeriods.currentPeriod);

      yield call(Api.partnerSchemesPeriods.exclude, period.id, invoice_id);
      if (cb) yield call(cb);
      yield reloadData();
    } catch (error) {
      console.error(error);
    }
  }

  function* downloadExcel({ payload:  {cb} }) {
    try {
      const { filter } = yield select(s => s.partnerSchemesDetails);
      const statement = yield select(s => s.partnerSchemesStatements.currentStatement);
      const period = yield select(s => s.partnerSchemesPeriods.currentPeriod);

      const title = `PS | Details | (${get(statement, 'company_name')}) | ${get(period, 'opening_date')} - ${get(period, 'closing_date')}`;
      const result = yield call(blobRequest, 'api_resources/partner_schemes/details', '', 'index_xlsx', filter);
      yield call(saveXlsxAttachment, result, title)

      if (cb) yield call(cb);
    } catch (error) {
      console.error(error);
    }
  }

  return [
    takeEvery(constants.EXCLUDE_INVOICE, excludeInvoice),
    takeEvery(constants.DOWNLOAD_EXCEL, downloadExcel),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  currentStatement: state.partnerSchemesStatements.currentStatement,
  currentPeriod: state.partnerSchemesPeriods.currentPeriod,
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
