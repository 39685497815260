import React from 'react';
import { Popup } from 'semantic-ui-react'

/**
 * Higher-order function that wraps an element with a Semantic UI Popup component.
 *
 * @param {string|React.Element} element - The React element to be wrapped with a popup.
 * @param {string} content - The content to be displayed in the popup.
 *
 * @returns {React.JSX.Element} A Popup component from Semantic UI React that wraps the provided element.
 */
export default (element, content) => (
  <Popup
    content={ content }
    position="bottom center"
    trigger={ <span>{element}</span> }
  />
);
