import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './redux';
import { selectOptions, companies, companyOwners } from 'utils/resources';

export function* loadItems() {
  try {
    yield put(actions.setLoading(true));
    const data = yield call(selectOptions.index, { type: 'owners', count: 'all' })
    yield put(actions.setItemsData(data));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setErrors(error))
  }
}

export function* loadSelectedItems({payload: companyId}) {
  try {
    yield put(actions.setLoading(true));
    const { data } = yield call(companies.owners, companyId)
    yield put(actions.setSelectedItems(data.data.map(({attributes: a}) => ({value: a.owner_id, label: a.owner_name}))));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setErrors(error))
  }
}

export function* selectItem({payload: {companyId, items}}) {
  try {
    yield put(actions.setLoading(true));
    for (let item of items) {
      let attributes = {company_id: companyId, owner_id: item.value, owner_name: item.label}
      yield call(companyOwners.create, attributes)
    }
    yield put(actions.loadSelectedItemsRequest(companyId));

  } catch (error) {
    yield put(actions.setErrors(error))
  }
}

export function* deleteItem({payload: {companyId, items}}) {
  try {
    yield put(actions.setLoading(true));
    for (let item of items) {
      let attributes = {company_id: companyId, owner_id: item.value}
      yield call(companyOwners.deleteQuery, attributes)
    }
    yield put(actions.loadSelectedItemsRequest(companyId));
  } catch (error) {
    yield put(actions.setErrors(error))
  }
}



export default function getFeatureSagas() {
  return [
    takeEvery([constants.LOAD_ITEMS_REQUEST], loadItems),
    takeEvery([constants.LOAD_SELECTED_ITEMS_REQUEST], loadSelectedItems),
    takeEvery([constants.SELECT_ITEM_REQUEST], selectItem),
    takeEvery([constants.DELETE_ITEM_REQUEST], deleteItem),

  ];
}
