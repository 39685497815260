import { handleActions, createAction } from 'redux-actions';

const storeName = (str) => str.replace('/SET_', '/');

export const constants = {
  FETCH_TOTALS_REQUEST: "@TOTALS/PURCHASE_ORDERS/FETCH_TOTALS_REQUEST",
  SET_TOTALS: "@TOTALS/PURCHASE_ORDERS/SET_TOTALS",
  SET_TOTALS_TYPE: "@TOTALS/PURCHASE_ORDERS/SET_TOTALS_TYPE",
  SET_LOADING: "@TOTALS/PURCHASE_ORDERS/SET_LOADING",
  SET_ERRORS: "@TOTALS/PURCHASE_ORDERS/SET_ERRORS"
};

export const actions = {
  fetchTotals: createAction(constants.FETCH_TOTALS_REQUEST),
  setTotals: createAction(constants.SET_TOTALS),
  setTotalsType: createAction(constants.SET_TOTALS_TYPE),
  setLoading: createAction(constants.SET_LOADING),
  setErrors: createAction(constants.SET_ERRORS)
};

export const initialState = {
  totals: {
    budgetAae: 0,
    actualBudget: 0,
    currentDay: 0,
    yearDays: 0
  },
  errors: [],
  loading: false,
  totalsType: localStorage.getItem(storeName(constants.SET_TOTALS_TYPE)) || 'less'
}

export const actionHandlers = {
  [constants.FETCH_TOTALS_REQUEST]: () => ({ ...initialState, loading: true }),
  [constants.SET_TOTALS]: (state, action) => ({ ...state, totals: action.payload, loading: false, errors: [] }),
  [constants.SET_TOTALS_TYPE]: (state, action) => {
    const totalsType = state.totalsType === 'less' ? 'more' : 'less';
    localStorage.setItem(storeName(constants.SET_TOTALS_TYPE), totalsType);
    return ({ ...state, totalsType, errors: [] });
  },
  [constants.SET_LOADING]: (state, action) => ({ ...state, loading: action.payload }),
  [constants.SET_ERRORS]: (state, action) => ({ ...state, errors: [action.payload] }),
}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  totals: state['poTotals'].totals,
  totalsType: state['poTotals'].totalsType,
  errors: state['poTotals'].errors,
  loading: state['poTotals'].loading,
});
