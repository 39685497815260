import { handleActions } from 'redux-actions';
import { reset } from 'redux-form';

import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';

import extendsOptions from './extends'

import Api from '../../utils/resourcesApi';

const baseConstants = getConstantsByFeatureName('PAYROLLS');
export const constants = extendsOptions.extendedConstants(baseConstants);

const baseActions = getActionCreators(constants);
export const actions = extendsOptions.extendedActions(baseActions, constants);

const initialState = extendsOptions.extendedInitialState;

const baseActionHandlers = getActionHandlers(constants);
const actionHandlers = extendsOptions.extendedActionHandlers(baseActionHandlers, constants);

export const reducer = handleActions(actionHandlers, initialState);

const baseSelectors = getSelectorByFeatureName('payrolls');
export const selector = extendsOptions.extendedSelectors(baseSelectors);

const baseSagas = getCRUDSagas('/payrolls', Api.payrolls, actions, constants, 'payrolls', 'payrolls');
const newSagas = extendsOptions.newSagas(baseSagas, constants, actions);
export const sagas = extendsOptions.extendedSagas(baseSagas, newSagas);

export const mapDispatchToProps = {
  onChangePageNumber: (e, { activePage }) => actions.changePageNumber(activePage),
  createPayrollRequest: actions.createPayrollRequest,
  loadPayrollRequest: actions.loadPayrollRequest,
  loadPayrollEmployeesRequest: actions.loadPayrollEmployeesRequest,
  loadBankDetailsRequest: actions.loadBankDetailsRequest,
  loadDetailsRequest: actions.loadDetailsRequest,
  setPeriod: actions.setPeriod,
  setCompany: actions.setCompany,
  setStep: actions.setStep,
  setCurrentEmployee: actions.setCurrentEmployee,
  setCurrentBankDetail: actions.setCurrentBankDetail,
  clearPayroll: actions.clearPayroll,
  setPayrollStatus: actions.setPayrollStatus,
  startAddNewAccount: actions.startAddNewAccount,
  startEditAccount: actions.startEditAccount,
  startEditDetails: actions.startEditDetails,
  clearForm: () => reset('bank_account'),
  createAccountRequest: actions.createAccountRequest,
  updateAccountRequest: actions.updateAccountRequest,
  updateBaseSalary: actions.updateBaseSalary,
  updateOnboard: actions.updateOnboard,
  updateArticleSum: actions.updateArticleSum,
  updateBankDetailRow: actions.updateBankDetailRow,
  splitBankDetailsRow: actions.splitBankDetailsRow,
  searchCrewRequest: actions.searchCrewRequest,
  addCrewRequest: actions.addCrewRequest,
  updateCrewRequest: actions.updateCrewRequest,
  deleteCrewRequest: actions.deleteCrewRequest,
  loadAccounts: actions.loadEmployeeAccountsRequest,
  createInvoiceRequest: actions.createInvoiceRequest,
  splitCrewRequest: actions.splitCrewRequest,
  splitCrew: actions.splitCrew,
  changeFilter: actions.changeFilter,
  loadData: actions.loadItemsRequest,

};

// export { constants, actions, reducer, selector, sagas };
