import { connect } from 'react-redux';
import createReduxList from './reduxList';
import createReduxGlobal from './reduxGlobal';


const createReduxAction = (type) => (
  {
    'invoices': createReduxList,
    'purchaseOrders': createReduxList,
    'requisitionLists': createReduxList,
    'paymentInstructions': createReduxList,
    'payrolls': createReduxList,
    'global': createReduxGlobal
  }[type]
)

export const redux = (type) => createReduxAction(type)(type);

export default (type) => {
  const { selector, mapDispatchToProps } = createReduxAction(type)(type);

  return connect(selector, mapDispatchToProps);
}
