import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';
import {create, update} from "../api/adapters/json_api";

const type = 'api_resources/partner_schemes/statements';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-sum_usd' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  index: (pagination, sort, search, filter, { global, paymentInstructions }) => api.read({ type }, {
    query: {
      sort: sortOptions(sort),
      page: { size: pagination.itemsPerPage, number: pagination.activePage },
      filter: {
        search,
        ...filter
      }
    }
  }
  ),

  updateAll: () => api.update({ type, action: 'update_all' }, {}, {}),

  updateData: (id) => api.update({ type, action: 'update_data', id }, {}, {data: {}})
}
