import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  approvePORequest: actions.approvePORequest,
  approvePOCancel: actions.approvePOCancel,
  startApproveRequest: actions.startApproveRequest,
  getDocumentRequest: actions.getDocumentRequest,
  setDocument: actions.setDocument,
  approveProcessRequest: actions.approveProcessRequest,
  approveProcessClose: actions.approveProcessClose,
  approvePO: actions.approvePO,
  rejectPO: actions.rejectPO
};


export default connect(selector, mapDispatchToProps);
