import React, { useState, useCallback } from 'react';
import { Card, Icon, Accordion, Statistic, Popup, Table } from 'semantic-ui-react'

import styled from 'styled-components';

import CardHeader from '../components/Header';

import { withPopup } from '../utils';

const StyledLabel = styled(Statistic.Label)`
  font-size: 0.9em !important;
`;

const StyledStatisticGroup = styled(Statistic.Group)`
  justify-content: space-between;
`;

const Content = ({ values }) => (
  <StyledStatisticGroup size="tiny">
    {
      Object.keys(values).map(doc => 
        Object.keys(values[doc]).map(type =>
          <Statistic textAlign="center">
            <Statistic.Value>{ withPopup(values[doc][type].last, 'Last 30 days') }</Statistic.Value>
            <Statistic.Value>{ withPopup(values[doc][type].year, 'This Budget Year') }</Statistic.Value>
            <Statistic.Label>{ `${doc} ${type}`.toUpperCase() }</Statistic.Label>
          </Statistic>
        )
      )
    }
  </StyledStatisticGroup>
);

const CardElement = ({ item: { attributes } }) => {
  return (
    <Card className="dashboard-card">
      <CardHeader item={ attributes } />
      <Card.Content className="header">
        <Card.Header>{ attributes.resource }</Card.Header>
      </Card.Content>
      <Card.Content 
        textAlign="center" 
        description={ <Content values={ attributes.actual }/> } 
      />
    </Card>
  )
}

export default CardElement;
