import { call, put, takeEvery, select } from 'redux-saga/effects';
import { toast } from 'react-semantic-toasts';
import { actions, constants } from './redux';
import Api from 'utils/resources/paymentInstructions';

export function* sendEmailRequest({ payload: documentId }) {
  try {
    const { data } = yield call(Api.show, documentId)
    const { customer_email: financialEmail, customer_name: shortName } = data.data.attributes;
    yield put(actions.setMailData({ financialEmail, shortName }));
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }

};


export function* getMailContent({ payload: documentId }) {
  try {
    const { data: { content } } = yield call(Api.piPaidMailContent, documentId)
    yield put(actions.setMailContent(content));
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }
}

export function* sendEmail({ payload }) {
  try {
    yield put(actions.setLoading(true));
    const { documentId, email, htmlContent: content, files = [] } = payload;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('content', content);
    files.forEach((file) => {
      formData.append('files[]', file);
    });

    yield call(Api.piPaidMailSend, documentId, formData)
    yield put(actions.sendMailSuccess());
    yield call(toast, { type: 'success', title: 'E-mail sent successfully!' })
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }

};

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.SEND_MAIL_REQUEST,
      constants.SEND_MAIL_CONFIRM
    ], sendEmailRequest),
    takeEvery([constants.SEND_MAIL], sendEmail),
    takeEvery([constants.GET_MAIL_CONTENT], getMailContent),
  ];
}
