import { connect } from 'react-redux';
import component from './component';
import { actions, selector, constants } from './redux';
import sagas from './sagas';

const mapDispatchToProps = {
  sendMailRequest: (data) => actions.sendMailRequest(data),
  cancelMail: () => actions.cancelMail(),
  getMailContent: (data) => actions.getMailContent(data),
  sendMail: (data) => actions.sendMail(data),
};

export { actions, constants, sagas };

export default connect(selector, mapDispatchToProps)(component);

