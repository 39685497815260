import { createAction } from 'redux-actions';
import {  put, takeEvery } from '@redux-saga/core/effects';
import get from 'lodash/get';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  SET_LOADING: '@INVOICES/SET_LOADING',
  CHANGE_FILTER_MODE: '@INVOICES/CHANGE_FILTER_MODE',
  ADD_FILTER_TITLE: '@INVOICES/ADD_FILTER_TITLE',
  SET_TOTALS: '@INVOICES/SET_TOTALS',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  changeFilterMode: createAction(constants.CHANGE_FILTER_MODE),
  addFilterTitle: createAction(constants.ADD_FILTER_TITLE),
  setLoading: createAction(constants.SET_LOADING),
  setTotals: createAction(constants.SET_TOTALS),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  loading: false,
  search: localStorage.getItem('@INVOICES/CHANGE_SEARCH') || '',
  direction: localStorage.getItem('@INVOICES/DIRECTION') || 'incoming',
  filterModeActive: false,
  filterTitles: {},
  totals: {
    total: 0, paid: 0, unpaid: 0
  }
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.CHANGE_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, action.payload);
    return commonActionHandlers.changeSearch(state, action);
  },
  [constants.CLEAR_SEARCH]: (state, action) => {
    localStorage.setItem(constants.CHANGE_SEARCH, '');
    return commonActionHandlers.clearSearch(state, action);
  },
  [constants.CHANGE_FILTER_MODE]: (state) => ({
    ...state,
    filterModeActive: !state.filterModeActive
  }),
  [constants.ADD_FILTER_TITLE]: (state, { payload: { field, value, title } }) => ({
    ...state,
    filterTitles: {
      ...state.filterTitles,
      [field]: {
        ...state.filterTitles[field],
        [value]: title
      }
    }
  }),
  [constants.SET_TOTALS]: (state, { payload }) => ({
    ...state,
    totals: payload
  }),
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state,
    loading: payload
  }),

  "@SETTINGS/INVOICES/SET_DIRECTION": (state) => ({...state, loading: true})
});

const newSagas = (baseSagas, constants, actions) => {
  function* changeCompanies() {
    try {
      yield put(actions.loadItemsRequest());
    } catch (e) {
      console.error(e);
      yield put(actions.setErrors(e));
    }
  }

  function* setTotals({ payload }) {
    try {
      const totals = get(payload, 'data.meta.totals');
      yield put(actions.setTotals(totals));
    } catch (e) {
      console.error(e);
      yield put(actions.setErrors(e));
    }
  }

  function* refetchItems() {
    try {
      yield put(actions.setLoading(true));
      yield put(actions.loadItemsRequest());
    } catch (e) {
      console.error(e);
      yield put(actions.setErrors(e));
    }
  }


  return [
    takeEvery("@SETTINGS/GLOBAL/SET_INV_COMPANIES", changeCompanies),
    takeEvery(constants.ITEMS_LOADED_SUCCESSFULLY, setTotals),
    takeEvery("@SETTINGS/INVOICES/SET_DIRECTION", refetchItems),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  filterModeActive: state.invoicesPage.filterModeActive,
  filterTitles: state.invoicesPage.filterTitles,
  totals: state.invoicesPage.totals,
  direction: state.settings.invoices.direction,
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
