import { createAction } from 'redux-actions';

const getActionCreators = (types = {}) => {
  const actionCreators = {};

  if (types.INIT) actionCreators['init'] = createAction(types.INIT);

  if (types.LOAD_ITEMS_REQUEST) actionCreators['loadItemsRequest'] = createAction(types.LOAD_ITEMS_REQUEST);
  if (types.ITEMS_LOADED_SUCCESSFULLY) actionCreators['addItems'] = createAction(types.ITEMS_LOADED_SUCCESSFULLY, data => data);
  if (types.LOAD_ITEMS_ERROR) actionCreators['loadItemsError'] = createAction(types.LOAD_ITEMS_ERROR, e => e);

  if (types.CLEAR_ITEM) actionCreators['clearItem'] = createAction(types.CLEAR_ITEM);

  if (types.LOAD_ITEM_REQUEST) actionCreators['loadItemRequest'] = createAction(types.LOAD_ITEM_REQUEST);
  if (types.ADD_LOADED_ITEM) actionCreators['addLoadedItem'] = createAction(types.ADD_LOADED_ITEM);
  if (types.LOAD_ITEM_ERROR) actionCreators['loadItemError'] = createAction(types.LOAD_ITEM_ERROR, e => e);

  if (types.CREATE_ITEM_REQUEST) actionCreators['createItemRequest'] = createAction(types.CREATE_ITEM_REQUEST);
  if (types.ADD_CREATED_ITEM) actionCreators['addCreatedItem'] = createAction(types.ADD_CREATED_ITEM);
  if (types.CREATE_ITEM_ERROR) actionCreators['createItemError'] = createAction(types.CREATE_ITEM_ERROR, e => e);

  if (types.UPDATE_ITEM_REQUEST) actionCreators['updateItemRequest'] = createAction(types.UPDATE_ITEM_REQUEST);
  if (types.ITEM_UPDATED_SUCCESSFULLY) actionCreators['updateItem'] = createAction(types.ITEM_UPDATED_SUCCESSFULLY);
  if (types.UPDATE_ITEM_ERROR) actionCreators['updateItemError'] = createAction(types.UPDATE_ITEM_ERROR, e => e);

  if (types.DELETE_ITEM_REQUEST) actionCreators['deleteItemRequest'] = createAction(types.DELETE_ITEM_REQUEST);
  if (types.ITEM_DELETED_SUCCESSFULLY) actionCreators['deleteItem'] = createAction(types.ITEM_DELETED_SUCCESSFULLY);
  if (types.DELETE_ITEM_ERROR) actionCreators['deleteItemError'] = createAction(types.DELETE_ITEM_ERROR, e => e);

  if (types.CHANGE_PAGE_NUMBER) actionCreators['changePageNumber'] = createAction(types.CHANGE_PAGE_NUMBER);
  if (types.CHANGE_PAGE_ITEMS_COUNT) actionCreators['changePageItemsCount'] = createAction(types.CHANGE_PAGE_ITEMS_COUNT);

  if (types.CHANGE_FILTER) actionCreators['changeFilter'] = createAction(types.CHANGE_FILTER, (field, value) => ({ field, value }));
  if (types.REMOVE_FILTER) actionCreators['removeFilter'] = createAction(types.REMOVE_FILTER);
  if (types.CLEAR_FILTER) actionCreators['clearFilter'] = createAction(types.CLEAR_FILTER);
  if (types.APPLY_FILTER) actionCreators['applyFilter'] = createAction(types.APPLY_FILTER);

  if (types.CHANGE_SEARCH) actionCreators['changeSearch'] = createAction(types.CHANGE_SEARCH);
  if (types.CLEAR_SEARCH) actionCreators['clearSearch'] = createAction(types.CLEAR_SEARCH);
  if (types.APPLY_SEARCH) actionCreators['applySearch'] = createAction(types.APPLY_SEARCH);

  if (types.SORT_ITEMS) actionCreators['sortItems'] = createAction(types.SORT_ITEMS, field => field);

  return actionCreators;
};

export default getActionCreators;
