import React, { useState, useCallback, useEffect, Fragment }  from 'react'
import {Card, Icon, Accordion, Statistic, Popup, Table, Image, Grid} from 'semantic-ui-react'

import CardHeader from '../components/Header';
import DescriptionSum from './DescriptionSum';
import DetailsTable from './DetailsTable';

const DashboardCard = ({ item: { attributes } }) => {

  const [expanded, setExpanded] = useState(false);

  const handleExpanded = useCallback(() => {
    setExpanded(!expanded)
  },[expanded]);

  return (
    <Card className="dashboard-card">
      <CardHeader item={ attributes } />
      <Card.Content className="header">
        <Card.Header>{attributes.resource}</Card.Header>
      </Card.Content>
      <Card.Content 
        textAlign="center" 
        description={ <DescriptionSum item={ attributes } /> } 
      />
      <Card.Content extra className="footer">
        <Accordion>
          <Accordion.Title active={ expanded } onClick={ handleExpanded }>
            <Icon name='dropdown' />
            Detail
          </Accordion.Title>
          <Accordion.Content active={ expanded }>
            <DetailsTable item={ attributes } />
          </Accordion.Content>
        </Accordion>
      </Card.Content>
    </Card>
  )
}

export default DashboardCard;
