import client from 'apiClient';
import refetch from 'apollo-magic-refetch'
import { toast } from 'react-semantic-toasts';
import Api from 'utils/resources/purchaseOrders';
import { put, call, takeEvery } from 'redux-saga/effects';

import { actions, constants } from './index';

export function* sendEmail({ payload }) {
  try {
    const { cb, documentId, email, htmlContent: content, files = [] } = payload;

    const formData = new FormData();
    formData.append('email', email);
    formData.append('content', content);
    files.forEach((file) => {
      formData.append('files[]', file);
    });

    yield call(Api.poMailSend, documentId, formData)
    yield put(actions.sendMailSuccess());
    yield call(toast, { type: 'success', title: 'E-mail sent successfully!' });
    if (cb) yield call(cb);
    yield call(refetch, client, 'PurchaseOrder');
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }

};

export function* getMailContent({ payload: orderId }) {
  try {
    const { data: { content } } = yield call(Api.poMailContent, orderId)
    yield put(actions.setMailContent(content));
  } catch (error) {
    yield put(actions.setErrors(error));
    yield call(toast, { type: 'error', title: 'Something went wrong!', description: error.message })
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.SEND_MAIL
    ], sendEmail),
    takeEvery([
      constants.GET_MAIL_CONTENT
    ], getMailContent),

  ];
}
