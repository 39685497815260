import { call, put, takeEvery, select } from 'redux-saga/effects';
import { toast } from 'react-semantic-toasts';
import get from "lodash/get";

const camelize = text => text
  .replace(/\s(.)/g, $1 => $1.toUpperCase())
  .replace(/\s/g, '')
  .replace(/^(.)/, $1 => $1.toLowerCase());

export default function getDictionarySagas(getDataFunction, constants, actions, featureName) {
  function* fetchDictionary() {
    try {
      const data = yield call(getDataFunction);

      yield put(actions.addItems(data));
    } catch (e) {
      yield put(actions.loadItemsError(e));
    }
  }

  function* displayError() {
    const name = camelize(featureName.toLowerCase());
    const error = yield select(state => get(state, `${name}.error`));
    // yield call(toast, { type: 'error', title: `Error loading ${name}`, description: error.message })
  }

  /**
   * getDictionarySagas (rename)
   * each saga file should return all feature sagas
   * @returns {ForkEffect[]}
   */
  function getDictionarySagas() {
    return [
      takeEvery(constants.LOAD_ITEMS_ERROR, displayError),
    ];
  }

  return {
    fetchDictionary,
    getSagas: getDictionarySagas
  }
}
