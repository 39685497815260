import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/partner_schemes/excluded_invoices';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-created_at' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (pagination, sort, search, filter) => api.read({ type }, {
    query: {
      sort: sortOptions(sort),
      includes: 'invoice',
      page: { size: 20, number: pagination.activePage },
      filter: {
        search,
        ...filter,
      }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

  owners: (id) => api.read({ type, id, action: 'owners' }, {}),

  vessels: (id) => api.read({ type, id, action: 'vessels' }, {}),

  createInvoices: (id) => api.update({ type, id, action: 'create_invoices' }, {}, {})
}
