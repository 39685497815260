import api from 'utils/api/api';
import { TOKEN_AUTH_HEADERS } from 'constants/index';

export const logIn = (credentials) => api.post('/auth/sign_in', credentials).then(saveAuthHeaders);

export const googleLogIn = (tokenObject) => api.post('/auth/google_sign_in', tokenObject).then(saveAuthHeaders);

export const logOut = () => api.delete('/auth/sign_out').then(removeAuthHeaders);

export const showQr = () => api.get('/auth/two_factors');

export const enableTwoFactor = (password, otp_attempt) => {
  return api.post('/auth/two_factors', { user: { password, otp_attempt } });
};

export const validateToken = () => {
  return api.get('/auth/validate_token')
            .catch((error) => {
              if (error.message === "Network Error")  return;
              removeAuthHeaders();
              throw error;
            });
};

const saveAuthHeaders = (response) => {
  localStorage.clear();
  TOKEN_AUTH_HEADERS.forEach((header) => localStorage.setItem(header, response.headers[header]));
  return response;
};

export const removeAuthHeaders = (response) => {
  localStorage.clear();
  TOKEN_AUTH_HEADERS.forEach((header) => localStorage.removeItem(header));
  return response;
};
