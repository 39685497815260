/**
 * @module companyMentions
 */

import { handleActions, createAction } from "redux-actions";
import {
  buildRequestConstants,
  buildFilterConstants,
  buildPaginationConstants,
  buildRequestActions,
  buildFilterActions,
  buildPaginationActions,
  buildLoadListActionHandlers,
  buildFilterActionHandlers,
  buildPaginationActionHandlers,
  baseInitialState,
  buildSelector,
} from "utils/reduxNew/reduxHelpers";

/**
 * The feature name used as prefix for state constants.
 * @const {string}
 */
const featureConstantsName = "@COMPANY_MENTIONS";

/**
 * The feature name used as key for state selectors.
 * @const {string}
 */
const featureReducerName = "companyMentions";

/**
 * An object to hold the constant values.
 * @member constants
 * @property {object} constants - holds constant values for actions.
 */
export const constants = {
  ...buildRequestConstants(featureConstantsName, "LOAD_LIST"),
  ...buildFilterConstants(featureConstantsName),
  ...buildPaginationConstants(featureConstantsName),
};

/**
 * An object to hold the action creators.
 * @member actions
 * @property {object} actions - holds action creators for actions.
 */
export const actions = {
  ...buildRequestActions(constants, "loadList", "LOAD_LIST"),
  ...buildFilterActions(constants),
  ...buildPaginationActions(constants),
};

/**
 * The initial state of the feature reducer.
 * @const {object}
 */
export const initialState = {
  ...baseInitialState,
};

/**
 * The reducer for the feature. Defines how the state should
 * be updated based on the dispatched actions.
 * @function reducer
 * @param {object} state - The current state
 * @param {object} action - The action dispatched
 * @returns {object} The updated state
 */
export const reducer = handleActions(
  {
    ...buildLoadListActionHandlers(constants),
    ...buildFilterActionHandlers(constants),
    ...buildPaginationActionHandlers(constants),
  },
  initialState,
);

/**
 * The selector for the feature. Provides easy access
 * to the part of the state managed by this feature reducer.
 * @function selector
 * @param {object} state - The state object
 * @returns {object} - The part of the state managed by this feature reducer.
 */
export const selector = buildSelector(featureReducerName);
