import { handleActions, createAction } from 'redux-actions';

const toSnakeCase = str => str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');

const storeName = (str) => str.replace('/SET_', '/');

export default (type) => {

  const constantType = toSnakeCase(type).toUpperCase();

  const constants = {
    SET_PERIOD_TYPE: `@SETTINGS/${constantType}/SET_PERIOD_TYPE`,
    SET_BUDGET_YEAR: `@SETTINGS/${constantType}/SET_BUDGET_YEAR`,
    SET_COMPANIES: `@SETTINGS/${constantType}/SET_COMPANIES`,
    SET_DIRECTION: `@SETTINGS/${constantType}/SET_DIRECTION`,
  };

  const actions = {
    setPeriodType: createAction(constants.SET_PERIOD_TYPE),
    setBudgetYear: createAction(constants.SET_BUDGET_YEAR),
    setCompanies: createAction(constants.SET_COMPANIES),
    setDirection: createAction(constants.SET_DIRECTION),
  };

  const initialState = {
    periodType: localStorage.getItem(storeName(constants.SET_PERIOD_TYPE)) || 'date',
    budgetYear: localStorage.getItem(storeName(constants.SET_BUDGET_YEAR)) || new Date().getFullYear(),
    companies: localStorage.getItem(storeName(constants.SET_COMPANIES)) || [],
    direction: localStorage.getItem(storeName(constants.SET_DIRECTION)) || 'incoming',
  }

  const actionHandlers = {
    [constants.SET_PERIOD_TYPE]: (state, action) => {
      localStorage.setItem(storeName(constants.SET_PERIOD_TYPE), action.payload);
      return { ...state, periodType: action.payload }
    },
    [constants.SET_BUDGET_YEAR]: (state, action) => {
      localStorage.setItem(storeName(constants.SET_BUDGET_YEAR), action.payload);
      return { ...state, budgetYear: action.payload }
    },
    [constants.SET_COMPANIES]: (state, action) => {
      localStorage.setItem(storeName(constants.SET_COMPANIES), action.payload);
      return { ...state, companies: action.payload }
    },
    [constants.SET_DIRECTION]: (state, action) => {
      localStorage.setItem(storeName(constants.SET_DIRECTION), action.payload);
      return { ...state, direction: action.payload }
    },

  }

  const reducer = handleActions(actionHandlers, initialState);

  const selector = (state) => ({
    periodType: state.settings[type].periodType,
    budgetYear: state.settings[type].budgetYear,
    companies: state.settings[type].companies,
    direction: state.settings[type].direction,
  });

  const mapDispatchToProps = {
    setPeriodType: (_, { type: newType }) => actions.setPeriodType(newType),
    setBudgetYear: (year) => actions.setBudgetYear(year),
    setCompanies: (companies) => actions.setCompanies(companies),
    setDirection: actions.setCompanies,
  };

  return { constants, actions, initialState, actionHandlers, reducer, selector, mapDispatchToProps };

};
