import React from 'react';
import { Card, Icon, Popup } from 'semantic-ui-react'


const MultiLineDescription = ({ description }) => 
  description.split("\n").map(row => <p>{ row }</p>)

const CardHeader = ({ item }) => (
  <Card.Header textAlign="right" className="card-header">
    <Popup
      content={ <MultiLineDescription description={ item.description } /> }
      trigger={ <Icon name="question circle outline" /> }
    />
  </Card.Header>
);

export default CardHeader;
