import { call, put, takeEvery } from 'redux-saga/effects';
import { actions, constants } from './index';
import Api from 'utils/resources/dashboard';

export function* fetchDashboard() {
  try {
    const { data: { data, meta: { users } } } = yield call(Api.index);

    yield put(actions.setDashboard(data));
    yield put(actions.setUsers(users));
  } catch (error) {
    yield put(actions.setErrors(error))
  }
};

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.FETCH_DASHBOARD_REQUEST
    ], fetchDashboard)
  ];
}
