import { sagas as acBudgetItemsSagas } from './acBudgetItems';
import { sagas as companiesSagas } from './companies';
import { sagas as banksSagas } from './banks';
import { sagas as currenciesSagas } from './currencies';
import { sagas as countriesSagas } from './countries';
import { sagas as citiesSagas } from './cities';
import { sagas as usersSagas } from './users';

export default [
  acBudgetItemsSagas,
  companiesSagas,
  banksSagas,
  currenciesSagas,
  countriesSagas,
  citiesSagas,
  usersSagas
]
