import React from 'react';
import { shape, func, string } from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import { createGoBack } from 'utils/redirects';

const defaultOptions = {
  size: 'large'
};

export const POSITIVE_ACTION_TEMPLATE_ID = 'modal-positive-action';

const _Modal = (Form, options = defaultOptions, styles = {}, goBackAction = undefined) => {
  const ModalPage = ({ history, location: { pathname }, match: { params } }) => {
    const goBack = goBackAction ? goBackAction : createGoBack(history, pathname);

    return (
      <Modal open closeOnDimmerClick={ false } size={ options.size } onClose={ goBack } style={styles.modal}>
        <Modal.Content style={styles.content}>
          <Form params={ params } />
        </Modal.Content>
        <Modal.Actions style={styles.actions}>
          <Button onClick={ goBack }>{options.cancelName || 'Cancel'}</Button>
          <span id={ POSITIVE_ACTION_TEMPLATE_ID } />
        </Modal.Actions>
      </Modal>
    );
  };

  ModalPage.propTypes = {
    history: shape({
      push: func.isRequired,
      goBack: func.isRequired
    }).isRequired,
    location: shape({
      pathname: string.isRequired
    }).isRequired,
    match: shape({
      params: shape()
    }).isRequired
  };

  return ModalPage;
};

export default _Modal;
