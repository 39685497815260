import React, { Fragment, useMemo } from "react";
import { Label, Icon } from "semantic-ui-react";
import { getApiBase } from "../../utils/api/api";
import styled from 'styled-components';



const StyledLabel = styled(Label)`
  border: none !important;
  cursor: pointer;
`;


const PointerIcon = styled(Icon)`
  cursor: pointer;
`;

const ExistAttachment = ({ attachment, remove, currentUser, allowToDelete, id }) => {

  const name = useMemo(() => {
    return attachment.filename;
  }, [attachment])

  const label = useMemo(() => {
    return name.length > 53 ? `${name.slice(0, 40)}...${name.slice(-10)}` : name;
  }, [name]);

  const canDelete = useMemo(() => {
    return allowToDelete || (currentUser && currentUser.isAdmin());
  }, [allowToDelete, currentUser]);


  return (
    <div className="exist-attachment-item">
      <StyledLabel image basic href={`${getApiBase()}${attachment.link}`}>
        <Icon name='download' />
        {label}
      </StyledLabel>
      {canDelete &&
        <PointerIcon
          name='delete'
          onClick={remove}
          data-key={id}
        />
      }
    </div>

  );
};

export default ExistAttachment;
