import { handleActions } from 'redux-actions';
import pick from 'lodash/pick';
import getConstantsByFeatureName from '../getConstantsByFeatureName';
import getActionCreators from '../getActionCreators';
import getActionHandlers from '../getActionHandlers';
import { initialState } from "../commonActionHandlers";
import getSagas from './getSagas';

/**
 * getDictionaryReduxModule
 * @param {String} featureName
 * @param {Function} getDataFunction - should return something which will be saved as state.featureName.data
 * @returns {{constants, actions, reducer, sagas: (function(): ForkEffect[])}}
 */
export default (featureName, getDataFunction) => {
  const constants = pick(
    getConstantsByFeatureName(featureName),
    ['LOAD_ITEMS_REQUEST', 'ITEMS_LOADED_SUCCESSFULLY', 'LOAD_ITEMS_ERROR']
  );
  const actions = getActionCreators(constants);
  const actionHandlers = getActionHandlers(constants);
  const reducer = handleActions(actionHandlers, pick(initialState, ['data', 'didLoad', 'isLoading','error']));
  const sagas = getSagas(getDataFunction, constants, actions, featureName);

  return { constants, actions, reducer, sagas }
}
