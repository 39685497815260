import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as form } from 'redux-form';
import { reducer as paymentInstructions } from './features/payment-instructions/redux';
import { reducer as invoices } from './features/invoices/redux';
import { reducer as deliveryNotes } from './features/delivery-notes/redux';
import { reducer as serviceFees } from './features/service-fees/redux';

import { reducer as partnerSchemesStatements } from './features/partner-schemes/component/Statements/utils/redux';
import { reducer as partnerSchemesPeriods } from './features/partner-schemes/component/Periods/utils/redux';
import { reducer as partnerSchemesDetails } from './features/partner-schemes/component/Details/utils/redux';
import { reducer as partnerSchemesExcludedInvoices } from './features/partner-schemes/component/ExcludedInvoices/utils/redux';

import { reducer as payrolls } from './features/payrolls/redux';
import { reducer as attachments } from './features/attachments/redux';
import { reducer as purchaseOrders } from './features/purchase-orders/redux';
import { reducer as dashboard } from './features/dashboard/redux';

import { reducer as poTotals } from './features/components/totals/purchase_orders/redux';
import { reducer as deleteDocument } from './features/components/related-documents/delete/redux';

import { reducer as invoicesWired } from './features/invoices/wired-status-cell/redux';
import { reducer as linkedPO } from './features/invoices/linked-po/redux';
import { reducer as invoicesAttachment } from './features/invoices/attachment-cell/redux';

import { reducer as companiesPage } from './features/companies/redux';
import { reducer as companyStatement } from './features/companies/statement/redux';
import { reducer as companyBalance } from './features/companies/balance/redux';
import { reducer as assignOwnerCompany } from './features/assign-owner-company/redux';
import { reducer as companyInvitationLinks} from './features/companies/Form/Tabs/Partnership/InvitationLinks/redux';

import { reducer as requisitionLists } from './features/requisition-lists/redux';

import { reducer as statusHistory } from './features/components/timeline-status-history/redux';

import { reducer as tags } from './features/components/tags/redux';
import { reducer as comments } from './features/components/comments/redux';
import { reducer as companyMentions } from './features/components/mentions/redux';

import { reducer as tenders } from './features/requisition-lists/tenders/redux';


import { redux as settingsRedux } from './features/settings';

import dictionaryReducers from './features/dictionaries/reducers';

import {initialState} from 'utils/redux/commonActionHandlers';

const keys = Object.keys(initialState);
const withBaseKeys = (rdc) => keys.reduce((acc, el) => ({ ...acc, [el]: rdc[el] }), {})


export default (history) => combineReducers({
  router: connectRouter(history),
  form,
  ...dictionaryReducers,
  paymentInstructions,
  deliveryNotes,
  serviceFees,
  partnerSchemesStatements,
  partnerSchemesPeriods,
  partnerSchemesDetails,
  partnerSchemesExcludedInvoices,
  payrolls,
  purchaseOrders,
  poTotals,
  attachments,
  dashboard,
  assignOwnerCompany,
  companyBalance,
  statusHistory,
  tags,
  comments,
  companyMentions,
  relatedDocuments: combineReducers({
    delete: deleteDocument
  }),
  invoicesPage: invoices,
  companiesPage,
  companyInvitationLinks,
  invoices: combineReducers({
    wiredStatusCell: invoicesWired,
    attachmentCell: invoicesAttachment,
    linkedPO
  }),
  reports: combineReducers({
    statement: companyStatement
  }),
  requisitionLists,
  requisitionListPage: combineReducers({
    tenders
  }),
  settings: combineReducers({
    invoices: settingsRedux('invoices').reducer,
    purchaseOrders: settingsRedux('purchaseOrders').reducer,
    paymentInstructions: settingsRedux('paymentInstructions').reducer,
    requisitionLists: settingsRedux('requisitionLists').reducer,
    payrolls: settingsRedux('payrolls').reducer,
    global: settingsRedux('global').reducer,
  })
});
