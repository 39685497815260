import { handleActions, createAction } from 'redux-actions';

const buildRequestConstants = (type, name) => ({
  [`${name}_REQUEST`]: `${type}/${name}_REQUEST`,
  [`${name}_SUCCESS`]: `${type}/${name}_SUCCESS`,
  [`${name}_FAILURE`]: `${type}/${name}_FAILURE`,
});
export const constants = {
  ...buildRequestConstants('@TAGS', 'LOAD_TAG_LIST'),
  ...buildRequestConstants('@TAGS', 'ASSIGN_TAG'),
  ...buildRequestConstants('@TAGS', 'REMOVE_TAG'),
};

const buildActionRequest = (actionName, constName) => ({
  [`${actionName}Request`]: createAction(constants[`${constName}_REQUEST`]),
  [`${actionName}Success`]: createAction(constants[`${constName}_SUCCESS`]),
  [`${actionName}Failure`]: createAction(constants[`${constName}_FAILURE`]),
})

export const actions = {
  ...buildActionRequest('loadTagList', 'LOAD_TAG_LIST'),
  ...buildActionRequest('assignTag', 'ASSIGN_TAG'),
  ...buildActionRequest('removeTag', 'REMOVE_TAG'),

}

export const initialState = {
  data: [],
  loading: false,
  errors: [],
}

export const reducer = handleActions({
  [constants.LOAD_TAG_LIST_REQUEST]: (state) => ({...state, loading: true}),
  [constants.LOAD_TAG_LIST_SUCCESS]: (state, { payload }) => ({...state, data: payload, loading: false}),
  [constants.LOAD_TAG_LIST_FAILURE]: (state, { payload }) => ({...state, errors: payload, loading: false}),
  [constants.ASSIGN_TAG_FAILURE]: (state, { payload }) => ({...state, errors: payload, loading: false}),
  [constants.REMOVE_TAG_FAILURE]: (state, { payload }) => ({...state, errors: payload, loading: false}),
}, initialState);


export const selector = (state) => ({
  data: state['tags'].data,
  loading: state['tags'].loading,
  errors: state['tags'].errors,
});
