import * as api from '../api/adapters/json_api';


const type = 'api_resources/banks';

export default {
  /**
   * getUsers
   * @returns {Promise<*>}
   */
  index: (pagination, sort, id) => api.read({ type, id }, {
      query: {
        sort: 'name'
      }
    }),
  /**
   * createUser
   * @param attributes
   * @returns {Promise<*>}
   */
  create: (attributes) => api.create({ type }, attributes),
  /**
   * updateUser
   * @param id
   * @param attributes
   * @returns {Promise<*>}
   */
  update: (id, attributes) => api.update({ id, type }, attributes),
  /**
   * deleteUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type }),
}
