/**
 * @module invitationLinks
 */

import {handleActions, createAction} from "redux-actions";
import {
  buildRequestConstants,
  buildFilterConstants,
  buildPaginationConstants,
  buildRequestActions,
  buildFilterActions,
  buildPaginationActions,
  buildLoadListActionHandlers,
  buildFilterActionHandlers,
  buildPaginationActionHandlers,
  baseInitialState,
  buildSelector,
} from "utils/reduxNew/reduxHelpers";
import {connect} from "react-redux";

const featureConstantsName = "@COMPANY/INVITATION_LINKS";

const featureReducerName = "companyInvitationLinks";

export const constants = {
  ...buildRequestConstants(featureConstantsName, 'LOAD_INVITE_LINK'),
  ...buildRequestConstants(featureConstantsName, 'GENERATE_INVITE_LINK'),
  ...buildRequestConstants(featureConstantsName, 'LOAD_INVITED_USERS'),
  ...buildRequestConstants(featureConstantsName, 'DEACTIVATE_INVITED_USER'),
};

export const actions = {
  ...buildRequestActions(constants, 'loadInviteLink', 'LOAD_INVITE_LINK'),
  ...buildRequestActions(constants, 'generateInviteLink', 'GENERATE_INVITE_LINK'),
  ...buildRequestActions(constants, 'loadInvitedUsers', 'LOAD_INVITED_USERS'),
  ...buildRequestActions(constants, 'deactivateInvitedUser', 'DEACTIVATE_INVITED_USER'),
};

export const initialState = {
  links: {
    link: null,
    loading: false,
    error: null
  },
  invitedUsers: {
    users: [],
    loading: false,
    error: null
  }
};

export const reducer = handleActions(
  {
    [constants.LOAD_INVITE_LINK_REQUEST]: (state) => ({
      ...state,
      links: {
        ...state.links,
        loading: true
      }
    }),
    [constants.LOAD_INVITE_LINK_SUCCESS]: (state, {payload}) => ({
      ...state,
      links: {
        ...state.links,
        link: payload,
        loading: false
      }
    }),
    [constants.LOAD_INVITE_LINK_FAILURE]: (state, {payload}) => ({
      ...state,
      links: {
        ...state.links,
        error: payload,
        loading: false
      }
    }),
    [constants.LOAD_INVITED_USERS_REQUEST]: (state) => ({
      ...state,
      invitedUsers: {
        ...state.invitedUsers,
        loading: true
      }
    }),
    [constants.LOAD_INVITED_USERS_SUCCESS]: (state, {payload}) => ({
      ...state,
      invitedUsers: {
        ...state.invitedUsers,
        users: payload,
        loading: false
      }
    }),
    [constants.LOAD_INVITED_USERS_FAILURE]: (state, {payload}) => ({
      ...state,
      invitedUsers: {
        ...state.invitedUsers,
        error: payload,
        loading: false
      }
    }),
    [constants.GENERATE_INVITE_LINK_REQUEST]: (state) => ({
      ...state,
      links: {
        ...state.links,
        loading: true
      }
    }),
    [constants.GENERATE_INVITE_LINK_SUCCESS]: (state, {payload}) => ({
      ...state,
      links: {
        ...state.links,
        loading: false
      }
    }),
    [constants.GENERATE_INVITE_LINK_FAILURE]: (state, {payload}) => ({
      ...state,
      links: {
        ...state.links,
        error: payload,
        loading: false
      }
    }),
    [constants.DEACTIVATE_INVITED_USER_REQUEST]: (state) => ({
      ...state,
      invitedUsers: {
        ...state.invitedUsers,
        loading: true
      }
    }),
    [constants.DEACTIVATE_INVITED_USER_SUCCESS]: (state, {payload}) => ({
      ...state,
      invitedUsers: {
        ...state.invitedUsers,
        loading: false
      }
    }),
    [constants.DEACTIVATE_INVITED_USER_FAILURE]: (state, {payload}) => ({
      ...state,
      invitedUsers: {
        ...state.invitedUsers,
        error: payload,
        loading: false
      }
    })
  },
  initialState
);

export const selector = (state) => ({
  links: state[featureReducerName].links,
  invitedUsers: state[featureReducerName].invitedUsers
});


const mapDispatchToProps = {
  loadInviteLink: actions.loadInviteLinkRequest,
  generateInviteLink: actions.generateInviteLinkRequest,
  loadInvitedUsers: actions.loadInvitedUsersRequest,
  deactivateInvitedUser: actions.deactivateInvitedUserRequest
}

export const reduxConnect = (component) => connect(selector, mapDispatchToProps)(component);
