import React, { useMemo, useCallback } from 'react'
import { Dropdown } from 'semantic-ui-react';

import connect from '../reduxConnect';

const UsersSelect = ({ className, onChange, group, round, users, value }) => {

  const options = useMemo(() => Object.keys(users).map(key => (
    { key, text: users[key], value: key }
  )), [users])

  const renderLabel = useCallback(({ text }, index) => (
    index > 0 ? `OR ${text}` : text
  ), [users]);

  return (
    <Dropdown
      basic
      multiple
      search
      selection
      value={value}
      options={options}
      className="select-user-approve"
      data-round={round}
      data-group={group}
      onChange={onChange}
      renderLabel={renderLabel}
    />
  )
}

export default connect(UsersSelect);
