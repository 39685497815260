import React, {useState, useCallback, useEffect} from 'react';
import { Icon, Label, Popup, List } from 'semantic-ui-react';

import ExistAtachment from  'components/TableAttachments/ExistAtachment.jsx';
import AttachmentIcon from './AttachmentIcon';

import './styles.scss'


const AttachmentContent = ({attachments = [], loading, count}) => {


  return (
    loading ?
      <Icon loading size="huge" name='spinner' />
    : <List divided>
        {
          attachments &&
            attachments.map((attachment) => (
              <List.Item>
                <ExistAtachment attachment={attachment.attributes} />
              </List.Item>
          ))
        }
      </List>
  )
}

const AttachmentsCount = ({
  count,
  invoice_id,
  loadAttachments,
  clearAttachments,
  setAttachments,
  setLoading,
  attachmentCell: { data = [], loading } = {}
}) => {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if(open) {
      loadAttachments({record_id: invoice_id, record_type: 'Invoice'})
    }
    return clearAttachments;
  }, [open, invoice_id]);

  const handleClose = useCallback(() => setOpen(false), [setOpen])
  const handleOpen  = useCallback(() => setOpen(true),  [setOpen])

  return (
    <Popup
      content={<AttachmentContent attachments={data} loading={loading} count={ count }/>}
      on='click'
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      position="bottom center"
      wide="very"
      trigger={<AttachmentIcon count={ count } open={open} setOpen={setOpen} />}
    />
  )
};

export default AttachmentsCount;
