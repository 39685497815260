import React, {useCallback, useState, useEffect} from "react";
import times from "lodash/times";
import {Button, Form, Segment} from "semantic-ui-react";
import {loadVessels} from "./utils";

const InvoiceFilter = ({filters, changeFilter, applyFilter}) => {

  const [options, setOptions] = useState({
    vessels: [],
    years: times(5, (i) => new Date().getFullYear() + 1 - i)
  })

  const handleChangeFilter = useCallback((field) => (e, {value}) => {
    changeFilter({field, value});
  })

  useEffect(() => {
    loadVessels(setOptions);
  }, [setOptions]);

  return (
    <Segment>
      <Form>
        <Form.Group inline className="po-inv-field">
          <label className="po-inv-label">Vessel</label>
          <Form.Dropdown
            fluid
            selection
            search
            options={options.vessels}
            value={filters.vessel_id}
            onChange={handleChangeFilter('vessel_id')}
            className="po-inv-filter-item"
          />
        </Form.Group>
        <Form.Group inline className="po-inv-field">
          <label className="po-inv-label">Budget Year</label>
          <div className="po-inv-filter-item">
            <Form.Input
              list="years"
              value={filters.budget_year}
              onChange={handleChangeFilter('budget_year')}
            />
            <datalist id="years">
              {options.years.map(e => <option key={e} value={e}>{e}</option>)}
            </datalist>
          </div>
        </Form.Group>
        <Button fluid primary content="Apply" className="po-inv-apply-action" onClick={applyFilter}/>
      </Form>
    </Segment>
  )
}

export default InvoiceFilter;
