import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/delivery_note_items';

const sortOptions = (sort) => isEmpty(sort.field) ?
  'id' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (filter) => api.read({ type }, {
    query: {
      sort: sortOptions(''),
      filter: {
        ...filter,
      },
      page: { size: 999, number: 1 }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

}
