import PropTypes from "prop-types";
import React from 'react';
import { Form, Label, Input, Icon, Divider } from 'semantic-ui-react'
import HTMLEditor from '../../html-editor'
import Attachments from "./Attachments";

const EmailForm = ({
  emailError,
  email,
  handleEmailChange,
  content,
  setHtmlContent,
  setFiles,
  loading
}) => {

  return (
    <Form>
      <Form.Field inline>
        <Label pointing='right' color={emailError && "red"}>
          {emailError ? "Can't be blank" : "Send E-mail to:"}
        </Label>
        <Input
          iconPosition='left'
          placeholder='Email'
          defaultValue={email}
          onChange={handleEmailChange}
          error={emailError}
        >
          <Icon name='at' />
          <input />
        </Input>
      </Form.Field>
      <Divider />
      <Form.Field>
        <HTMLEditor content={content} changeContent={setHtmlContent} loading={loading} />
      </Form.Field>
      <Form.Field>
        <Attachments setFiles={setFiles}/>
      </Form.Field>
    </Form>
  )
}

EmailForm.propTypes = {
  content: PropTypes.string,
  email: PropTypes.string,
  emailError: PropTypes.bool,
  handleEmailChange: PropTypes.func,
  loading: PropTypes.bool,
  setHtmlContent: PropTypes.func
}

export default EmailForm;
