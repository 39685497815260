import { connect } from 'react-redux';
import component from './component';

import { actions, selector, constants } from './redux';

const mapDispatchToProps = {
  setLoading: (payload) => actions.setLoading(payload),
  clearAttachments: actions.clearAttachments,
  setAttachments: (payload) => actions.setAttachments(payload),
  loadAttachments: (payload) => actions.loadAttachmentsRequest(payload)
};

export { actions, selector, constants };

export default connect(selector, mapDispatchToProps)(component);
