import { reset, change } from 'redux-form';
import { createAction } from 'redux-actions';
import { createSelector } from 'reselect'
import { call, put, takeEvery, select, all } from '@redux-saga/core/effects';
import { toast } from 'react-semantic-toasts';
import { push } from 'connected-react-router';
import { payrolls } from 'utils/paths';

import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import Api from 'utils/resourcesApi';

import {
  indexDetailsPayload,
  createPayload,
  updatePayrollPayload,
  updateEmployeePayload,
  createBankAccountPayload,
  updateBankAccountPayload,
  updateBankDetailsPayload,
  splitBankDetailsPayload,
  updateDetailsPayload,
  toHashMap
} from './utils';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  // PAYROLL
  CREATE_PAYROLL_REQUEST: '@PAYROLLS/CREATE_PAYROLL_REQUEST',
  LOAD_PAYROLL_REQUEST: '@PAYROLLS/LOAD_PAYROLL_REQUEST',
  SET_PAYROLL: '@PAYROLLS/SET_PAYROLL',
  CLEAR_PAYROLL: '@PAYROLLS/CLEAR_PAYROLL',
  SET_PAYROLL_STATUS: '@PAYROLLS/SET_PAYROLL_STATUS',
  // PAYROLL ATTRIBUTES
  SET_PERIOD: '@PAYROLLS/SET_PERIOD',
  SET_COMPANY: '@PAYROLLS/SET_COMPANY',
  SET_STEP: '@PAYROLLS/SET_STEP',
  // PAYROLL EMPLOYEE
  LOAD_PAYROLL_EMPLOYEES_REQUEST: '@PAYROLLS/LOAD_PAYROLL_EMPLOYEES_REQUEST',
  SET_PAYROLL_EMPLOYEES: '@PAYROLLS/SET_PAYROLL_EMPLOYEES',
  SET_CURRENT_EMPLOYEE: "@PAYROLLS/SET_CURRENT_EMPLOYEE",
  UPDATE_BASE_SALARY: "@PAYROLLS/UPDATE_BASE_SALARY",
  UPDATE_ONBOARD: "@PAYROLLS/UPDATE_ONBOARD",
  // PAYROLL CREW
  SET_CREW_LIST: '@PAYROLLS/SET_CREW_LIST',
  SET_CREW_ROW: '@PAYROLLS/SET_CREW_ROW',
  ADD_CREW_REQUEST: '@PAYROLLS/ADD_CREW_REQUEST',
  DELETE_CREW_REQUEST: '@PAYROLLS/DELETE_CREW_REQUEST',
  SEARCH_CREW_REQUEST: '@PAYROLLS/SEARCH_CREW_REQUEST',
  SET_SEARCH_CREW: '@PAYROLLS/SET_SEARCH_CREW',
  UPDATE_CREW_REQUEST: '@PAYROLLS/UPDATE_CREW_REQUEST',
  SPLIT_CREW_REQUEST: '@PAYROLLS/SPLIT_CREW_REQUEST',
  SPLIT_CREW: '@PAYROLLS/SPLIT_CREW',

  // PAYROLL DETAILS
  LOAD_DETAILS_REQUEST: '@PAYROLLS/LOAD_DETAILS_REQUEST',
  SET_DETAILS: '@PAYROLLS/SET_DETAILS',
  START_EDIT_DETAILS: "@PAYROLLS/START_EDIT_DETAILS",
  SET_DETAILS_ROW: '@PAYROLLS/SET_DETAILS_ROW',
  UPDATE_ARTICLE_SUM: "@PAYROLLS/UPDATE_ARTICLE_SUM",
  // PAYROLL BANK/ACCOUNTS
  LOAD_BANK_DETAILS_REQUEST: '@PAYROLLS/LOAD_BANK_DETAILS_REQUEST',
  SET_BANK_DETAILS: '@PAYROLLS/SET_BANK_DETAILS',
  SET_BANK_DETAIL_ROW: "@PAYROLLS/SET_BANK_DETAIL_ROW",
  SET_CURRENT_BANK_DETAIL: '@PAYROLLS/SET_CURRENT_BANK_DETAIL',
  UPDATE_BANK_DETAIL_ROW: "@PAYROLLS/UPDATE_BANK_DETAIL_ROW",
  SPLIT_BANK_DETAILS_ROW: '@PAYROLLS/SPLIT_BANK_DETAILS_ROW',

  LOAD_EMPLOYEE_ACCOUNTS_REQUEST: '@PAYROLLS/LOAD_EMPLOYEE_ACCOUNTS_REQUEST',
  SET_EMPLOYEE_ACCOUNTS: '@PAYROLLS/SET_EMPLOYEE_ACCOUNTS',

  START_ADD_NEW_ACCOUNT: "@PAYROLLS/START_ADD_NEW_ACCOUNT",
  START_EDIT_ACCOUNT: "@PAYROLLS/START_EDIT_ACCOUNT",

  CREATE_ACCOUNT_REQUEST: "@PAYROLLS/CREATE_ACCOUNT_REQUEST",
  UPDATE_ACCOUNT_REQUEST: "@PAYROLLS/UPDATE_ACCOUNT_REQUEST",

  CREATE_INVOICE_REQUEST: "@PAYROLLS/CREATE_INVOICE_REQUEST",

});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  createPayrollRequest: createAction(constants.CREATE_PAYROLL_REQUEST),
  loadPayrollRequest: createAction(constants.LOAD_PAYROLL_REQUEST),
  setPayroll: createAction(constants.SET_PAYROLL),
  setPayrollStatus: createAction(constants.SET_PAYROLL_STATUS),
  clearPayroll: createAction(constants.CLEAR_PAYROLL),
  loadPayrollEmployeesRequest: createAction(constants.LOAD_PAYROLL_EMPLOYEES_REQUEST),
  setPayrollEmployees: createAction(constants.SET_PAYROLL_EMPLOYEES),
  setPeriod: createAction(constants.SET_PERIOD),
  setCompany: createAction(constants.SET_COMPANY),
  setCrewList: createAction(constants.SET_CREW_LIST),
  setCrewRow: createAction(constants.SET_CREW_ROW),
  setDetailsRow: createAction(constants.SET_DETAILS_ROW),
  splitBankDetailsRow: createAction(constants.SPLIT_BANK_DETAILS_ROW),
  setStep: createAction(constants.SET_STEP),
  loadBankDetailsRequest: createAction(constants.LOAD_BANK_DETAILS_REQUEST),
  setBankDetails: createAction(constants.SET_BANK_DETAILS),
  loadDetailsRequest: createAction(constants.LOAD_DETAILS_REQUEST),
  loadEmployeeAccountsRequest: createAction(constants.LOAD_EMPLOYEE_ACCOUNTS_REQUEST),
  setDetails: createAction(constants.SET_DETAILS),
  setEmployeeAccounts: createAction(constants.SET_EMPLOYEE_ACCOUNTS),
  startAddNewAccount: createAction(constants.START_ADD_NEW_ACCOUNT),
  startEditAccount: createAction(constants.START_EDIT_ACCOUNT),
  startEditDetails: createAction(constants.START_EDIT_DETAILS),
  setCurrentEmployee: createAction(constants.SET_CURRENT_EMPLOYEE),
  setCurrentBankDetail: createAction(constants.SET_CURRENT_BANK_DETAIL),
  createAccountRequest: createAction(constants.CREATE_ACCOUNT_REQUEST),
  updateAccountRequest: createAction(constants.UPDATE_ACCOUNT_REQUEST),
  setAccountRow: createAction(constants.SET_BANK_DETAIL_ROW),
  updateBaseSalary: createAction(constants.UPDATE_BASE_SALARY),
  updateOnboard: createAction(constants.UPDATE_ONBOARD),
  updateArticleSum: createAction(constants.UPDATE_ARTICLE_SUM),
  updateBankDetailRow: createAction(constants.UPDATE_BANK_DETAIL_ROW),
  searchCrewRequest: createAction(constants.SEARCH_CREW_REQUEST),
  setSearchCrew: createAction(constants.SET_SEARCH_CREW),
  addCrewRequest: createAction(constants.ADD_CREW_REQUEST),
  updateCrewRequest: createAction(constants.UPDATE_CREW_REQUEST),
  deleteCrewRequest: createAction(constants.DELETE_CREW_REQUEST),
  createInvoiceRequest: createAction(constants.CREATE_INVOICE_REQUEST),
  splitCrewRequest: createAction(constants.SPLIT_CREW_REQUEST),
  splitCrew: createAction(constants.SPLIT_CREW),

});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  successCreated: true,
  errorMessage: "",
  crewSearch: {
    list: {},
    loading: false
  },
  crewSplit: {
    showSplitForm: false,
    splitForm: {
      role: '',
      new_salary: 0,
      new_onboard: 0
    }
  },
  item: {
    step: 'crew',
    id: undefined,
    attributes: {
      period: new Date().toISOString(),
      company_id: '',
    },
    crewList: {},
    bankDetails: {},
    payrollTable: {},
    details: {
      incomeTable: {},
      deductionTable: {},
    },
    employeeAccounts: {},
    currentEmployee: "",
    currentAction: 'New',
    currentDetail: ""
  },
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.CREATE_PAYROLL_REQUEST]: (state, { payload }) => ({
    ...state,
    isLoading: true
  }),
  [constants.SET_PAYROLL]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      ...payload.data
    }
  }),
  [constants.CLEAR_PAYROLL]: (state) => ({
    ...state,
    item: extendedInitialState.item
  }),
  [constants.SET_PERIOD]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      attributes: {
        ...state.item.attributes,
        period: payload
      }
    }
  }),
  [constants.SET_COMPANY]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      attributes: {
        ...state.item.attributes,
        company_id: payload
      }
    }
  }),
  [constants.SET_CREW_LIST]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    item: {
      ...state.item,
      crewList: toHashMap(payload)
    }
  }),
  [constants.SET_CREW_ROW]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      crewList: {
        ...state.item.crewList,
        [payload.id]: payload
      }
    }
  }),
  [constants.SET_DETAILS_ROW]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      details: {
        ...state.item.details,
        [`${payload.attributes.type}Table`]: {
          ...state.item.details[`${payload.attributes.type}Table`],
          [payload.id]: payload
        }
      }
    }
  }),
  [constants.SET_STEP]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    item: {
      ...state.item,
      step: payload
    }
  }),
  [constants.LOAD_BANK_DETAILS_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
    item: {
      ...state.item,
      bankDetails: {}
    }
  }),
  [constants.SET_BANK_DETAILS]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    item: {
      ...state.item,
      bankDetails: toHashMap(payload)
    }
  }),
  [constants.START_ADD_NEW_ACCOUNT]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      ...payload
    }
  }),
  [constants.START_EDIT_ACCOUNT]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      ...payload
    }
  }),
  [constants.SET_CURRENT_EMPLOYEE]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      currentEmployee: payload
    }
  }),
  [constants.SET_CURRENT_BANK_DETAIL]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      currentBankDetail: payload
    }
  }),
  [constants.START_EDIT_DETAILS]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      ...payload
    }
  }),
  [constants.SET_BANK_DETAIL_ROW]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      bankDetails: {
        ...state.item.bankDetails,
        [payload.id]: payload
      }
    }
  }),
  [constants.SET_DETAILS]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      details: payload
    }

  }),
  [constants.SEARCH_CREW_REQUEST]: (state) => ({
    ...state,
    crewSearch: {
      ...state.crewSearch,
      loading: true
    }

  }),
  [constants.SET_SEARCH_CREW]: (state, { payload }) => ({
    ...state,
    crewSearch: {
      loading: false,
      list: toHashMap(payload)
    }

  }),
  [constants.SET_EMPLOYEE_ACCOUNTS]: (state, { payload }) => ({
    ...state,
    item: {
      ...state.item,
      employeeAccounts: toHashMap(payload)
    }
  }),
  [constants.SPLIT_CREW_REQUEST]: (state, { payload }) => ({
    ...state,
    crewSplit: payload
  }),



});

const newSagas = (baseSagas, constants, actions) => {

  function* createPayroll({payload}) {
    try {
      const data = createPayload(payload);
      const newItem = yield call(Api.payrolls.create, data);
      yield put(push(payrolls().edit(newItem.data.data.id)));
      // yield put(actions.setPayroll(newItem.data));
      // yield* loadPayrollEmployees({payload: newItem.data})

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* loadPayroll({payload: id}) {
    try {
      const item = yield call(Api.payrolls.show, id);
      yield put(actions.setPayroll(item.data));
      yield* loadPayrollEmployees({payload: item.data})

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* setPayrollStatus({payload: {id, newStatus}}) {
    try {

      const updatePayload = updatePayrollPayload(id, { status: newStatus })
      const item = yield call(Api.payrolls.update, id, updatePayload);
      yield put(actions.setPayroll(item.data));

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }



  function* loadPayrollEmployees({payload}) {
    try {
      const crewList = yield call(Api.payrolls.crewList, payload.data.id);
      yield put(actions.setCrewList(crewList.data.data));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* loadBankDetails({payload}) {
    try {
      const bankDetails = yield call(Api.payrolls.bankDetails, payload);
      yield put(actions.setBankDetails(bankDetails.data.data));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* loadEmployeeAccounts({payload}) {
    try {
      if (!payload || !payload.employee_id) return;
      const accounts = yield call(Api.employeeBankAccounts.index, payload);
      yield put(actions.setEmployeeAccounts(accounts.data.data));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }


  function* loadDetails({payload}) {
    try {
      const payrollId = yield select(s => s.payrolls.item.id);
      const payrollEmployeeId = yield select(s => s.payrolls.item.currentPayrollEmployee);

      if (!payrollId || !payrollEmployeeId) {
        return yield put(actions.setDetails({incomeTable: {}, deductionTable: {}}));
      }
      const incomePayload = indexDetailsPayload({ type: 'income', payrollId, payrollEmployeeId});
      const deductionPayload = indexDetailsPayload({ type: 'deduction', payrollId, payrollEmployeeId});
      const { data: { data: incomeTable } } = yield call(Api.payrollDetails.index, incomePayload);
      const { data: { data: deductionTable } } = yield call(Api.payrollDetails.index, deductionPayload);
      yield put(actions.setDetails({incomeTable: toHashMap(incomeTable), deductionTable: toHashMap(deductionTable)}));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* createBankAccount() {
    try {
      const formData = yield select(s => s.form.bank_account.values);
      const item = yield select(s => s.payrolls.item);
      const bdRowId = yield select(s => s.payrolls.item.currentBankDetail);
      const formPayload = createBankAccountPayload(item, formData);
      const { data: newAccount } = yield call(Api.employeeBankAccounts.create, formPayload);
      const updatePayload = updateBankDetailsPayload(bdRowId, newAccount.data.id);
      const { data: bdRow } = yield call(Api.payrollBankDetails.update, bdRowId, updatePayload);
      yield put(actions.setAccountRow(bdRow.data));
      yield put(reset('bank_account'));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* updateBankAccount({payload: id}) {
    try {
      const formData = yield select(s => s.form.bank_account.values);
      const item = yield select(s => s.payrolls.item);
      const bdRowId = yield select(s => s.payrolls.item.currentBankDetail);
      const formPayload = updateBankAccountPayload(id, item, formData);
      const { data: account } = yield call(Api.employeeBankAccounts.update, id, formPayload);
      const { data: bdRow } = yield call(Api.payrollBankDetails.show, bdRowId);
      yield put(actions.setAccountRow(bdRow.data));
      yield put(reset('bank_account'));
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* updateBaseSalary({ payload }) {
    try {
      const { value,  setModified } = payload;
      const { currentEmployee,  currentPayrollEmployee } = yield select(s => s.payrolls.item);

      const payrollData = updateEmployeePayload('payroll_employees', currentPayrollEmployee, { base_salary: value });
      const employeeData = updateEmployeePayload('employees', currentEmployee, { base_salary: value });

      const { data } = yield call(Api.payrollEmployees.update, currentPayrollEmployee, payrollData);
      yield call(Api.employees.update, currentEmployee, employeeData);

      yield put(actions.setCrewRow(data.data))

      yield call(setModified, false)

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* updateOnboard({ payload }) {
    try {
      const { value,  setModified } = payload;
      const { currentEmployee,  currentPayrollEmployee } = yield select(s => s.payrolls.item);

      const payrollData = updateEmployeePayload('payroll_employees', currentPayrollEmployee, { onboard: value });

      const { data } = yield call(Api.payrollEmployees.update, currentPayrollEmployee, payrollData);

      yield put(actions.setCrewRow(data.data))

      yield call(setModified, false)

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* updateArticleSum({ payload }) {
    try {
      const { value,  setModified, detailsId } = payload;
      const { currentPayrollEmployee, id } = yield select(s => s.payrolls.item);

      const detailsData = updateDetailsPayload(detailsId, { sum: value });

      const { data: payrollDetailsRow  } = yield call(Api.payrollDetails.update, detailsId, detailsData);
      const { data: payrollEmployeesRow  } = yield call(Api.payrollEmployees.show, currentPayrollEmployee);
      const { data: payroll } = yield call(Api.payrolls.show, id);

      yield put(actions.setDetailsRow(payrollDetailsRow.data))
      yield put(actions.setCrewRow(payrollEmployeesRow.data))
      yield put(actions.setPayroll(payroll))

      yield call(setModified, false)

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* updateBankDetailRow({ payload }) {
    try {
      const { currentBankDetail: bdRowId } = yield select(s => s.payrolls.item);

      const updatePayload = updateBankDetailsPayload(bdRowId, payload);
      const { data: bdRow } = yield call(Api.payrollBankDetails.update, bdRowId, updatePayload);
      yield put(actions.setAccountRow(bdRow.data));


    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* splitBankDetailRow({ payload: { options, cb } }) {
    try {
      const { currentBankDetail: bdRowId, id } = yield select(s => s.payrolls.item);

      const splitPayload = splitBankDetailsPayload(options);
      const { data: bdRow } = yield call(Api.payrollBankDetails.split, bdRowId, splitPayload);

      yield put(actions.loadBankDetailsRequest(id));
      yield put(actions.setAccountRow(bdRow.data));

      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }


  function* searchCrew({ payload }) {
    try {
      const { data: crewData  } = yield call(Api.employees.crew_search, {q: payload});
      yield put(actions.setSearchCrew(crewData.data))
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* addCrew({ payload }) {
    try {
      const { payrollId, employeeId } = payload;

      const employee = yield select(s => s.payrolls.crewSearch.list[employeeId])

      yield call(Api.payrolls.addEmployee, payrollId, { employee });
      yield* loadPayroll({payload: payrollId})
      yield call(toast, { type: 'success', title: 'Successfully added to Payroll!' })
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
      yield call(toast, { type: 'error', title: 'Something went wrong', description: e.message })
    }
  }

  function* updateCrew({ payload }) {
    try {
      const { id: payrollId } = yield select(s => s.payrolls.item);

      yield call(Api.payrolls.updateEmployee, payrollId, { user_id: payload });
      yield* loadPayroll({payload: payrollId})
      yield call(toast, { type: 'success', title: 'Successfully updated!' })
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
      yield call(toast, { type: 'error', title: 'Something went wrong', description: e.message })
    }
  }

  function* deleteCrew({ payload: id }) {
    try {
      const item = yield select(s => s.payrolls.item);

      yield call(Api.payrollEmployees.delete, id);
      yield* loadPayroll({payload: item.id})
      yield call(toast, { type: 'success', title: 'Successfully deleted from Payroll!' })
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
      yield call(toast, { type: 'error', title: 'Something went wrong', description: e.message })
    }
  }



  function* setNewAccountFormValues({ payload }) {
    try {

      yield put(change('bank_account', 'currency', 'USD'));
      yield put(change('bank_account', 'active', true));
      yield put(change('bank_account', 'beneficiary_name', payload.currentEmployeeName));
      yield put(change('bank_account', 'passport_no', payload.currentEmployeePassport));

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* fillInAccount({payload: {currentAccountId}}) {
    try {

      const { data: { data: employeeAccount }  } = yield call(Api.employeeBankAccounts.show, currentAccountId);

      const fields = yield select(s => s.form.bank_account.registeredFields);

      yield all(Object.keys(fields).map((field) => put(change('bank_account', field, employeeAccount.attributes[field]))));

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* createInvoice({ payload: {type, setLoading} }) {
    try {

      const { id } = yield select(s => s.payrolls.item);

      yield call(Api.payrolls.createInvoice, id, type);

      yield* loadPayroll({payload: id})
    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  function* splitCrew({ payload: {peId, form} }) {
    try {

      const { id } = yield select(s => s.payrolls.item);

      yield call(Api.payrollEmployees.split, peId, form);

      yield* loadPayroll({payload: id})

      const payloadForm = {
        showSplitForm: false,
        rowId: undefined,
        form: { role: '', new_salary: 0, new_onboard: 0 }
      }

      yield put(actions.splitCrewRequest(payloadForm));

    } catch (e) {
      console.error(e);
      yield put(actions.setError(e));
    }
  }

  return [
    takeEvery(constants.CREATE_PAYROLL_REQUEST, createPayroll),
    takeEvery(constants.LOAD_PAYROLL_REQUEST, loadPayroll),
    takeEvery(constants.SET_PAYROLL_STATUS, setPayrollStatus),
    takeEvery(constants.LOAD_PAYROLL_EMPLOYEES_REQUEST, loadPayrollEmployees),
    takeEvery(constants.LOAD_BANK_DETAILS_REQUEST, loadBankDetails),
    takeEvery(constants.LOAD_DETAILS_REQUEST, loadDetails),
    takeEvery(constants.CREATE_ACCOUNT_REQUEST, createBankAccount),
    takeEvery(constants.UPDATE_ACCOUNT_REQUEST, updateBankAccount),
    takeEvery(constants.UPDATE_BASE_SALARY, updateBaseSalary),
    takeEvery(constants.UPDATE_ONBOARD, updateOnboard),
    takeEvery(constants.UPDATE_ARTICLE_SUM, updateArticleSum),
    takeEvery(constants.UPDATE_BANK_DETAIL_ROW, updateBankDetailRow),
    takeEvery(constants.SPLIT_BANK_DETAILS_ROW, splitBankDetailRow),
    takeEvery(constants.SEARCH_CREW_REQUEST, searchCrew),
    takeEvery(constants.ADD_CREW_REQUEST, addCrew),
    takeEvery(constants.UPDATE_CREW_REQUEST, updateCrew),
    takeEvery(constants.DELETE_CREW_REQUEST, deleteCrew),
    takeEvery(constants.START_ADD_NEW_ACCOUNT, setNewAccountFormValues),
    takeEvery(constants.LOAD_EMPLOYEE_ACCOUNTS_REQUEST, loadEmployeeAccounts),
    takeEvery(constants.START_EDIT_ACCOUNT, fillInAccount),
    takeEvery(constants.CREATE_INVOICE_REQUEST, createInvoice),
    takeEvery(constants.SPLIT_CREW, splitCrew),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

// const getAttributes = (state) => state.payrolls.item.attributes;
const getYear = (state) => parseInt(state.payrolls.item.attributes.period.split('-')[0]);
const getMonth = (state) => parseInt(state.payrolls.item.attributes.period.split('-')[1]) - 1;
const getCompany = (state) => state.payrolls.item.attributes.company_id.toString();
const getCrewList = (state) => state.payrolls.item.crewList;
const getStep = (state) => state.payrolls.item.step;
const getCompaniesOptions = (state) => state.companies.data;
const getPayrollId = (state) => state.payrolls.item.id;
const getBanks = (state) => state.banks.data;
const getCurrencies = (state) => state.currencies.data;
const getIncomeTable = (state) => state.payrolls.item.details.incomeTable;
const getDeductionTable = (state) => state.payrolls.item.details.deductionTable;
const getBaseSalary = (state) => {
  const currentEmployeeId = state.payrolls.item.currentPayrollEmployee;
  if(!currentEmployeeId || !state.payrolls.item.crewList) { return 0 }
  const currentEmployee = state.payrolls.item.crewList[currentEmployeeId];
  if(!currentEmployee) { return 0 }
  return currentEmployee.attributes && currentEmployee.attributes.base_salary;
}
const getOnboard = (state) => {
  const currentEmployeeId = state.payrolls.item.currentPayrollEmployee;
  if(!currentEmployeeId || !state.payrolls.item.crewList) { return 0 }
  const currentEmployee = state.payrolls.item.crewList[currentEmployeeId];
  if(!currentEmployee) { return 0 }
  return currentEmployee.attributes && currentEmployee.attributes.onboard;
}
const getCrewSearch = (state) => state.payrolls.crewSearch;
const getCrewSplit = (state) => state.payrolls.crewSplit;

// const attributesSelector = createSelector([getYear], data => data);
const yearSelector = createSelector([getYear], data => data);
const monthSelector = createSelector([getMonth], data => data );
const companySelector = createSelector([getCompany], data => data );
const crewListSelector = createSelector([getCrewList], data => data );
const stepSelector = createSelector([getStep], data => data );
const companiesOptionsSelector = createSelector([getCompaniesOptions], data => (
  data && data.reduce((acc, { id, attributes }) => {
    (/*attributes.sc_group ||*/ attributes.is_vessel) && acc.push({ text: attributes.short_name, value: id });
    return acc;
  }, [])
  ) );
const payrollIdSelector = createSelector([getPayrollId], data => data);
const bankOptionsSelector = createSelector([getBanks], data =>
  data && data.map(({ id, attributes }) => ({ text: attributes.name, value: id }))
);
const currencyOptionsSelector = createSelector([getCurrencies], data =>
  data && data.map(({ attributes }) => ({ text: attributes.code, value: attributes.code }))
);
const incomeTableSelector = createSelector([getIncomeTable], data => data );
const deductionTableSelector = createSelector([getDeductionTable], data => data );
const baseSalarySelector = createSelector([getBaseSalary], data => data );
const onboardSelector = createSelector([getOnboard], data => data );
const crewSearchSelector = createSelector([getCrewSearch], data => data );
const crewSplitSelector = createSelector([getCrewSplit], data => data );


const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  companiesOptions: companiesOptionsSelector(state),
  // attributes: attributesSelector(state),
  year: yearSelector(state),
  month: monthSelector(state),
  company_id: companySelector(state),
  crewList: crewListSelector(state),
  step: stepSelector(state),
  payrollId: payrollIdSelector(state),
  bankOptions: bankOptionsSelector(state),
  currencyOptions: currencyOptionsSelector(state),
  incomeTable: incomeTableSelector(state),
  deductionTable: deductionTableSelector(state),
  baseSalary: baseSalarySelector(state),
  onboard: onboardSelector(state),
  crewSearch: crewSearchSelector(state),
  crewSplit: crewSplitSelector(state),
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
