import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/partner_schemes/periods';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-opening_date' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (pagination, sort, search, filter) => api.read({ type }, {
    query: {
      sort: sortOptions(sort),
      page: { size: 4, number: pagination.activePage },
      filter: {
        search,
        ...filter,
      }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

  updateData: (id) => api.update({ type, action: 'update_data', id }, {}, {data: {}}),

  exclude: (id, invoice_id) => api.update({ type, action: 'exclude', id }, {query: {invoice_id}}, {data: {}}),

  restore: (id, invoice_id) => api.update({ type, action: 'restore', id }, {query: {invoice_id}}, {data: {}}),
}
