import * as api from '../api/adapters/json_api';

const type = 'api_resources/users';

export default {
  index:  (pagination, sort, id) => api.read({ type, id }),
  create: (attributes) => api.create({ type }, attributes),
  update: (id, attributes) => api.update({ id, type }, attributes),
  delete: (id) => api.destroy({ id, type }),
}
