import { selectOptions as selectOptionsApi } from 'utils/resources';


const reduceData = (data) => data.reduce((acc, { value, label }) => [ ...acc, { key: value, value, text: label} ], [])

export const loadVessels = (setOptions) => {
  selectOptionsApi.index({ type: 'vessels', f_label: 'name', count: 9999 })
    .then((data) => setOptions(s => ({ ...s, vessels: reduceData(data) })))
}

export const loadCustomers = (setOptions) => {
  selectOptionsApi.index({ type: 'companies', f_label: 'short_name', skip_cache: true, count: 'all' })
    .then((data) => setOptions(s => ({ ...s, customers: reduceData(data) })))
}


