import moment from "moment";
import { DATE_FORMAT, FULL_JSON_DATE_FORMAT } from "constants/index";
import { store } from "components/App/Context";

export const DIGITS = 2;

export const parseDate = (value, withTime = false) => {
  if (!value) return moment().toDate();
  if (withTime) {
    return moment(value, FULL_JSON_DATE_FORMAT).toDate();
  } else {
    return moment(value, DATE_FORMAT).endOf("day").toDate();
  }
};

export const period = (year) => {
  const selectedYear = year || store.invoicesYear || new Date().getFullYear();
  return {
    from: moment({ year: parseInt(selectedYear, 10) }).format("YYYY-MM-DD"),
    to: moment({ year: parseInt(selectedYear, 10) })
      .endOf("year")
      .format("YYYY-MM-DD"),
  };
};

export const formatSumUsd = (sum) =>
  parseFloat(sum).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

export const formatSum = (sum, withNull = false) => {
  if (!withNull && !sum) return "";
  if (!sum) return "0.00";
  return parseFloat(sum).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const formatDate = (date) => {
  if (!date) return "";
  return moment(date).format(DATE_FORMAT);
};

export const setDateFields = (collection, columns) => {
  const parsedCollection = [];
  collection.forEach((row) => {
    const newRow = { ...row };
    columns.forEach((column) => {
      if (newRow[column]) {
        newRow[`${column}_asDate`] = parseDate(newRow[column]);
        newRow[`${column}_asDateTime`] = parseDate(newRow[column], true);
      }
    });
    parsedCollection.push(newRow);
  });
  return parsedCollection;
};

export const mapValuesToArray = (collection, fields) => {
  const processedCollection = [];
  collection.forEach((row) => {
    const newRow = { ...row };
    Object.keys(fields).forEach((key) => {
      if (row[fields[key].collection])
        newRow[key] = row[fields[key].collection].map(
          (el) => el[fields[key].value]
        );
    });
    processedCollection.push(newRow);
  });
  return processedCollection;
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const truncateTextInside = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text.slice(0, maxLength) + "..." + text.slice(-10);
  }
  return text;
};

export const humanizeText = (text) => {
  if (text) {
    return text
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_\s]+/g, " ")
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }
  return "";
};
