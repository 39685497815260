import {history} from "../../store";

export const modalStylesTender = {
  content: {maxHeight: '85vh', overflowY: 'auto', overflowX: 'auto'}
}

export const goBackActionTender = (e) => {
  e.preventDefault();
  history.push('/requisition_lists');
}
