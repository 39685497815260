import * as api from '../api/adapters/json_api';

const type = 'api_resources/company_owners';

export default {
  index: ({pagination = {}, sort, id, filter = {}} = {}) => api.read({ type, id }, {
      query: {
        sort: sort || 'short_name',
        filter
      }
    }),
  create: (attributes) => api.create({ type }, {}, {
    data: {
      type: type.split('/')[1],
      attributes
    }
  }),
  update: (id, attributes) => api.update({ id, type }, {}, attributes),
  delete: (id) => api.destroy({ id, type }),
  deleteQuery: (query) => api.create({ type: `${type}/delete_query` }, { query }, {}),
};
