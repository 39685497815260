import { handleActions, createAction } from 'redux-actions';

const featureName = 'STATUS_HISTORY';

export const constants = {
  LOAD_ITEMS_REQUEST: `@${featureName}/LOAD_ITEMS_REQUEST`,
  LOAD_ITEMS_SUCCESSFUL: `@${featureName}/LOAD_ITEMS_SUCCESSFUL`,
  LOAD_ITEMS_FAILED: `@${featureName}/LOAD_ITEMS_FAILED`,
};

export const actions = {
  loadItemsRequest: createAction(constants.LOAD_ITEMS_REQUEST),
  loadItemsSuccessful: createAction(constants.LOAD_ITEMS_SUCCESSFUL),
  loadItemsFailed: createAction(constants.LOAD_ITEMS_FAILED),
};

const initialState = {
  data: [],
  loading: false,
  errors: {},
  documentId: null,
  documentType: null,
};

export const actionHandlers = {
  [constants.LOAD_ITEMS_REQUEST]: (state, { payload }) => ({ ...state, ...payload, loading: true, data: [] }),
  [constants.LOAD_ITEMS_SUCCESSFUL]: (state, { payload: data }) => ({ ...state, data, loading: false }),
  [constants.LOAD_ITEMS_FAILED]:  (state, { payload: errors }) => ({ ...state, errors, loading: false }),
};

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state.statusHistory.data,
  loading: state.statusHistory.loading,
  errors: state.statusHistory.errors,
  documentId: state.statusHistory.documentId,
  documentType: state.statusHistory.documentType
})
