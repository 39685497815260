import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

import { piItemsToHeader } from '../converter/show'

const type = 'payments'
const path = `api_resources/${type}`;

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-paid_at' :
  `${ sort.direction === 'asc' ? '' : '-' }${ sort.field }`;

export default {
  /**
   * getPaymentInstruction
   * @returns {Promise<*>}
   */
  show: (id) => api.read({ type: path, id }, {}),

  /**
   * getPaymentInstructions
   * @returns {Promise<*>}
   */
  index: (pagination, sort, filter) => api.read({ type: path }, {
    query: {
      sort: sortOptions(sort),
      page: { size: pagination.itemsPerPage, number: pagination.activePage },
      filter: filter
    }
  }),
  /**
   * createPaymentInstruction
   * @param attributes
   * @returns {Promise<*>}
   */
  create: (attributes) => api.create(
    { type: path },
    {},
    {
      data: {
        type,
        attributes
      }
    }),
  /**
   * updatePaymentInstruction
   * @param id
   * @param attributes
   * @returns {Promise<*>}
   */
  update: (id, attributes) => api.update(
    { id, type: path },
    {} ,
    {
      data: {
        type,
        id,
        attributes
      }
    }),
  /**
   * deletePaymentInstruction
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: path }),
}
