import { handleActions, createAction } from 'redux-actions';

export const constants = {
  FETCH_DASHBOARD_REQUEST: "@DASHBOARD/FETCH_DASHBOARD_REQUEST",
  SET_DASHBOARD: "@DASHBOARD/SET_DASHBOARD",
  SET_LOADING: "@DASHBOARD/SET_LOADING",
  SET_ERRORS: "@DASHBOARD/SET_ERRORS",
  SET_BM: "@DASHBOARD/SET_BM",
  SET_USERS: "@DASHBOARD/SET_USERS",
  SET_SELECTED_USERS: "@DASHBOARD/SET_SELECTED_USERS",

};

export const actions = {
  fetchDashboard: createAction(constants.FETCH_DASHBOARD_REQUEST),
  setDashboard: createAction(constants.SET_DASHBOARD),
  setLoading: createAction(constants.SET_LOADING),
  setErrors: createAction(constants.SET_ERRORS),
  setBenchmark: createAction(constants.SET_BM),
  setSelectedUsers: createAction(constants.SET_SELECTED_USERS),
  setUsers: createAction(constants.SET_USERS)
};

export const initialState = {
  data: [],
  errors: [],
  loading: false,
  bm: false,
  users: [],
  selectedUsers: []
}

export const actionHandlers = {
  [constants.FETCH_DASHBOARD_REQUEST]: () => ({ ...initialState, loading: true }),
  [constants.SET_DASHBOARD]: (state, action) => ({ ...state, data: action.payload, loading: false, errors: [], bm: false }),
  [constants.SET_USERS]: (state, action) => ({ ...state, users: action.payload }),
  [constants.SET_LOADING]: (state, action) => ({ ...state, loading: action.payload }),
  [constants.SET_ERRORS]: (state, action) => ({ ...state, errors: [ ...state.errors, action.payload ] }),
  [constants.SET_BM]: (state, action) => ({ ...state, bm: action.payload }),
  [constants.SET_SELECTED_USERS]: (state, action) => ({ ...state, selectedUsers: action.payload }),
}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state['dashboard'].data,
  errors: state['dashboard'].errors,
  loading: state['dashboard'].loading,
  bm: state['dashboard'].bm,
  users: state['dashboard'].users,
  selectedUsers: state['dashboard'].selectedUsers,
});
