/**
 * This module exports constants, actions, an initial state, a reducer, and a selector for comments feature.
 * Uses utility functions from the reduxHelpers module to construct these exports.
 * @module comments
 */

import { handleActions } from "redux-actions";
import {
  buildFullPageConstants,
  buildFullPageActions,
  buildFullPageActionHandlers,
  buildSelector,
  baseInitialState,
} from "utils/reduxNew/reduxHelpers";

/**
 * Constant used in generating other constants, actions, reducer and selectors
 * @constant
 * @type {string}
 */
const featureConstantsName = "@COMMENTS";

/**
 * Constant used in generating the selector
 * @constant
 * @type {string}
 */
const featureReducerName = "comments";

/**
 * An object encompassing action type constants for the comments feature context
 * @constant
 * @type {Object}
 */
export const constants = buildFullPageConstants(featureConstantsName);

/**
 * An object encompassing action creators for the comments feature expected by redux
 * @constant
 * @type {Object}
 */
export const actions = buildFullPageActions(constants);

/**
 * The initial state used by the reducer of comments feature
 * @constant
 * @type {Object}
 */
export const initialState = baseInitialState;

/**
 * Redux reducer that listens to dispatched actions for the comments feature and modifies the state accordingly
 * @constant
 * @function
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} The updated state.
 */
export const reducer = handleActions(
  buildFullPageActionHandlers(constants),
  initialState,
);

/**
 * Selector function to retrieve the relevant state for the comments feature from a larger Redux state
 * @constant
 * @function
 * @param {Object} state - The Redux state.
 * @returns {Object} The comments feature state.
 */
export const selector = buildSelector(featureReducerName);
