import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/payroll_employees';

export default {
  show: (id) => api.read({ type, id }, {
  }),
  
  index: (filter) => api.read({ type }, {
    query: {
      filter: {
        ...filter,
      }
    }
  }
  ),
  
  create: (attributes) => api.create({ type }, {}, attributes),
  
  update: (id, attributes) => api.update({ id, type }, {}, attributes),
  
  delete: (id) => api.destroy({ id, type }),

  split: (id, attributes) => api.update({ id, type, action: 'split' }, {}, attributes),

}
