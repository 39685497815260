import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { actions, constants } from './index';
import { invoiceItems } from '../../../utils/resources';

export function* loadLinkedPO({ payload: {iiId, setLoading} }) {
  try {
    const response = yield call(invoiceItems.linkedPO, iiId);
    yield put(actions.setLinkedPOData(response.data));
    yield call(setLoading, false);
  } catch (error) {
    console.error(error)
    // yield put(actions.setError(error))
  }
}

export function* unlinkPO({ payload: {itemId, linkedItemId, setLoading} }) {
  try {
    yield call(setLoading, true);
    yield call(invoiceItems.unlinkPO, itemId, {query: { linked_item_id: linkedItemId }});
    yield* loadLinkedPO({ payload: {iiId: itemId, setLoading} })
  } catch (error) {
    console.error(error)
    // yield put(actions.setError(error))
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.LOAD_LINKED_PO_REQUEST
    ], loadLinkedPO),
    takeEvery([
      constants.UNLINK_PO
    ], unlinkPO),


  ];
}
