import { handleActions, createAction } from 'redux-actions';

export const constants = {
  LOAD_INVOICES: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/LOAD_LINKED_INVOICES_REQUEST",
  SET_INVOICES: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/SET_INVOICES",
  LOAD_INVOICE_ITEMS: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/LOAD_INVOICE_ITEMS",
  SET_INVOICE_ITEMS: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/SET_INVOICE_ITEMS",
  SET_CURRENT_INVOICE: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/SET_CURRENT_INVOICE",
  SHOW_SELECT_FORM: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/SHOW_SELECT_FORM",
  APPLY_FILTER: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/APPLY_FILTER",
  SET_FILTER: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/SET_FILTER",
  CHANGE_FILTER: "@PURCHASE_ORDERS/FILL_FROM_INVOICE/CHANGE_FILTER",
}

export const actions = {
  loadInvoices: createAction(constants.LOAD_INVOICES),
  setInvoices: createAction(constants.SET_INVOICES),
  loadInvoiceItems: createAction(constants.LOAD_INVOICE_ITEMS),
  setInvoiceItems: createAction(constants.SET_INVOICE_ITEMS),
  setCurrentInvoice: createAction(constants.SET_CURRENT_INVOICE),
  showSelectForm: createAction(constants.SHOW_SELECT_FORM),
  applyFilter: createAction(constants.APPLY_FILTER),
  setFilter: createAction(constants.SET_FILTER),
  changeFilter: createAction(constants.CHANGE_FILTER),
}

export const initialState = {
  data: [],
  dataItems: [],
  loadingData: false,
  loadingDataItems: false,
  currentInvoice: null,
  showForm: false,
  filters: {},
  pagination: {
    first: 1,
    activePage: 1,
    last: undefined,
    totalPages: undefined,
    itemsPerPage: 999,
  },
}

export const actionHandlers = {
  [constants.LOAD_INVOICES]: (state) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, loadingData: true  } }),
  [constants.APPLY_FILTER]: (state) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, loadingData: true  } }),
  [constants.SET_INVOICES]: (state, {payload: data}) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, loadingData: false, ...data  } }),
  [constants.LOAD_INVOICE_ITEMS]: (state) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, dataItems: [],loadingDataItems: true} }),
  [constants.SET_INVOICE_ITEMS]: (state, {payload: {data: dataItems}}) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, dataItems, loadingDataItems: false} }),
  [constants.SET_CURRENT_INVOICE]: (state, {payload: currentInvoice}) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, currentInvoice } }),
  [constants.SHOW_SELECT_FORM]: (state, {payload: showForm}) => ({ ...state, fillFromInvoice: { ...state.fillFromInvoice, showForm } }),
  [constants.SET_FILTER]: (state, { payload: filters }) => ({...state, fillFromInvoice: {...state.fillFromInvoice, filters}}),
  [constants.CHANGE_FILTER]: (state, { payload: { field, value } }) => ({
    ...state,
    fillFromInvoice: {
      ...state.fillFromInvoice,
      filters: {
        ...state.fillFromInvoice.filters,
        [field]: value,
      }
    }
  }),
}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state.purchaseOrders.fillFromInvoice.data,
  dataItems: state.purchaseOrders.fillFromInvoice.dataItems,
  loadingData: state.purchaseOrders.fillFromInvoice.loadingData,
  loadingDataItems: state.purchaseOrders.fillFromInvoice.loadingDataItems,
  currentInvoice: state.purchaseOrders.fillFromInvoice.currentInvoice,
  showForm: state.purchaseOrders.fillFromInvoice.showForm,
  filters: state.purchaseOrders.fillFromInvoice.filters,
})
