import { connect } from 'react-redux';

import { reducer as acBudgetItems } from './acBudgetItems';
import { reducer as companies } from './companies';
import { reducer as currencies } from './currencies';
import { reducer as countries } from './countries';
import { reducer as cities } from './cities';
import { reducer as banks } from './banks';
import { reducer as users } from './users';
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";

export const connectDictionary = (dicName, Control = undefined) => {
  const selector = getSelectorByFeatureName(dicName)
  if(Control) {
    return connect(selector)(Control);
  } else {
    return connect(selector);
  }
}

export default {
  acBudgetItems,
  companies,
  currencies,
  countries,
  cities,
  banks,
  users
};
