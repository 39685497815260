import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

import { piItemsToHeader } from '../converter/show'

const type = 'api_resources/payment_instructions';

const sortOptions = (sort) => !sort || isEmpty(sort.field) ?
  'status,-starred,due_date' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  /**
   * getPaymentInstruction
   * @returns {Promise<*>}
   */
  show: (id) => api.read({ type, id }, {
    query: { include: 'instruction_invoices' }
  }).then(piItemsToHeader),


  /**
   * This function is a method to read data from API with specific parameters.
   *
   * @param {Object} pagination - An object with pagination details.
   * @param {string} sort - The sort order.
   * @param {string} search - The search query.
   * @param {Object} filter - The filter applied.
   * @param {Object} options - An object with additional parameters.
   * @param {Object} options.global - An object with global parameters.
   * @param {Object} options.paymentInstructions - An object with payment instructions.
   * @return {Promise} Returns a promise with the API response data.
   */
  index: (pagination, sort, search, filter, { global = null, paymentInstructions } = {}, options = { fields: null }) => api.read({ type }, {
      query: {
        include: 'company',
        sort: sortOptions(sort),
        filter: {
          ...filter,
          ...searchOptions(search),
          company_id: (global?.piCompanies || []).join(','),
          period_filter: { ...paymentInstructions },
        },
        page: { size: pagination.itemsPerPage, number: pagination.activePage },
        fields: options.fields
      }
    }
  ),
  /**
   * createPaymentInstruction
   * @param attributes
   * @returns {Promise<*>}
   */
  create: (attributes) => api.create({ type }, {}, attributes),
  /**
   * updatePaymentInstruction
   * @param id
   * @param attributes
   * @returns {Promise<*>}
   */
  update: (id, attributes) => api.update({ id, type }, {}, attributes),
  /**
   * deletePaymentInstruction
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type }),
  /**
   * payPaymentInstruction
   * @returns {Promise<*>}
   */
  pay: (id, payload) => api.update({ type, id, action: 'pay' }, {}, payload),
  /**
   * starPaymentInstruction
   * @returns {Promise<*>}
   */
  /**
   * Updates an item with specified id by starring it.
   * @param {string|number} id - The identifier of the item.
   * @return {Promise} A promise that resolves with the updated item.
   */
  star: (id) => api.update({ type, id, action: 'star' }, {}, {}),

  /**
   * Sets a new number to an item with specified id.
   * @param {string|number} id - The identifier of the item.
   * @return {Promise} A promise that resolves with the updated item.
   */
  setNewNumber: (id) => api.update({ type, id, action: 'set_new_number' }, {}, {}),

  /**
   * Reads the content of a Paid PI Mailer for an item with specified id.
   * @param {string|number} id - The identifier of the item.
   * @return {Promise} A promise that resolves with the content of the Paid PI Mailer.
   */
  piPaidMailContent: (id) => api.read({ type, id, action: 'paid_pi_mailer_content' }, {}),

  /**
   * Sends a Mail using the Paid PI Mailer for an item with specified id and options.
   * @param {string|number} id - The identifier of the item.
   * @param {Object} options - Options for the Paid PI Mailer.
   * @return {Promise} A promise that resolves when the Mail is sent.
   */
  piPaidMailSend: (id, options) => api.update({ type, id, action: 'paid_pi_mailer_send_mail' }, {}, options, true),
}
