import { handleActions, createAction } from 'redux-actions';

export const constants = {
  DELETE_REQUEST: '@RELATED_DOCUMENTS/DELETE/DELETE_REQUEST',
  DELETE_ITEM: '@RELATED_DOCUMENTS/DELETE/DELETE_ITEM',
  DELETE_SUCCESS: '@RELATED_DOCUMENTS/DELETE/DELETE_SUCCESS',
  CANCEL_DELETE: '@RELATED_DOCUMENTS/DELETE/CANCEL_DELETE'
};

export const actions = {
  deleteRequest: createAction(constants.DELETE_REQUEST),
  deleteItem: createAction(constants.DELETE_ITEM),
  deleteSuccess: createAction(constants.DELETE_SUCCESS),
  cancelDelete: createAction(constants.CANCEL_DELETE),
};

const initialState = {
  showConfirm: false,
  type: undefined,
  pk: undefined,
  afterDestroy: undefined
};

export const actionHandlers = {
  [constants.DELETE_REQUEST]: (state, { payload: { type, pk, title, afterDestroy } }) => ({ type, pk, title, afterDestroy, showConfirm: true }),
  [constants.CANCEL_DELETE]: () => ({ ...initialState }),
  [constants.DELETE_SUCCESS]: () => ({ ...initialState })
};

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  showConfirm: state.relatedDocuments.delete.showConfirm,
  type: state.relatedDocuments.delete.type,
  pk: state.relatedDocuments.delete.pk,
  title: state.relatedDocuments.delete.title,
  afterDestroy: state.relatedDocuments.delete.afterDestroy
})
