import refetch from 'apollo-magic-refetch'

import client from 'apiClient';

const GQL_MODELS = ['Invoice', 'PurchaseOrder']

const clearSubscriptions = ({ subscriptions }, channel) => {
  subscriptions['subscriptions'].forEach(s => {
    if (s.identifier.includes(channel)) {
      subscriptions.remove(s);
    };
  })
}

const connected = () => console.log('subscribed for updates...')

const disconnected = () => console.log('unsubscribed for updates...')

const received = (data, options) => {
  const { model } = data;
  if (GQL_MODELS.includes(model)) {
    refetchGql(model);
  } else {
    const { fetchItems } = options;
    fetchItems();
  }
}

const refetchGql = (model) => refetch(client, model);

export default (cable, payload, options) => {
  const channel = 'UpdateChannel';
  return null;
  // clearSubscriptions(cable, channel);
  cable.subscriptions.create({
    channel,
    ...payload
  }, {
    connected: connected,
    disconnected: disconnected,
    received: data => received(data, options)
  });
}
