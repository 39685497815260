/**
 * A module that exposes default company mentions API operations.
 * @module companyMentionsAPI
 */

import * as api from "../api/adapters/json_api";
import isEmpty from "lodash/isEmpty";

/** @type {string} The type attribute for company mentions in API calls. */
const type = "api_resources/company_mentions";

/**
 * Transform a search keyword into a query option.
 * @function searchOptions
 * @param {string} search - The search keyword.
 * @return {Object} An object with the search keyword, or null if the search keyword is empty.
 * */
const searchOptions = (search) => (isEmpty(search) ? null : { search });

export default {
  /**
   * Fetch the company mentions data for a certain page with optional search and filters.
   * @function index
   * @param {Object} params - The pagination, search and filter parameters.
   * @param {Object} params.pagination - The pagination options.
   * @param {number} params.pagination.itemsPerPage - The number of items per page.
   * @param {number} params.pagination.activePage - The current active page.
   * @param {string} params.search - The search keyword.
   * @param {Object} params.filter - The filter options.
   * @return {Promise} Promise that resolves with the fetched data or errors.
   * */
  index: ({ pagination, search, filter }) =>
    api.read(
      { type },
      {
        query: {
          sort: "-mention_at",
          page: {
            size: pagination.itemsPerPage,
            number: pagination.activePage,
          },
          filter: {
            ...filter,
            ...searchOptions(search),
          },
        },
      },
    ),
};
