import { connect } from 'react-redux';
import component from './component';
import ModalAddChargesComponent from './component/ModalAddCharges'

import { actions, selector, constants } from './redux';
import sagas from './sagas';

const mapDispatchToProps = {
  setPaid: (payload) => actions.setPaidRequest(payload),
  addChargesOpen: (payload) => actions.addChargesOpen(payload),
  addChargesClose: actions.addChargesClose,
  addChargesRequest: (payload) => actions.addChargesRequest(payload)
};

export { actions, constants, sagas };

export const ModalAddCharges = connect(selector, mapDispatchToProps)(ModalAddChargesComponent);

export default connect(selector, mapDispatchToProps)(component);

