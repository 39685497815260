import { call, put, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import { toast } from "react-semantic-toasts";
import { statusHistories } from "utils/resources";
import { actions, constants } from "./redux";

export function* fetchItems({ payload: { statusType, documentType, documentId } }) {
  try {
    const filter = { status_type: statusType, document_type: documentType, document_id: documentId };
    const { data: { data } } = yield call(statusHistories.index, filter);
    yield put(actions.loadItemsSuccessful(data));
  } catch (error) {
    console.error({ error });
    yield put(actions.loadItemsFailed(error));
    const message = (get(error, "response.data.errors") || []).map((e) => e.title).join("\n");

    yield call(toast, {
      type: "error",
      title: "Something went wrong",
      description: message,
    });
  }
}

export default function getFeatureSagas() {
  return [takeEvery([constants.LOAD_ITEMS_REQUEST], fetchItems)];
}
