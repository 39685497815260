import * as api from '../api/adapters/json_api';
import isEmpty from "lodash/isEmpty";

const type = 'api_resources/companies';

const indexFields = {
  companies: "id,short_name,activity_status,activity_status_human,client_status,client_status_human," +
    "location,vat_number,tags,company_types,last_updated,docs_count,comments_count,mentions_count,creation," +
    "credit_term_human,deleted,connected_to_sellwisor"
};

const showFields ={
  companies: "id,city_id,sc_group,accounting_centre,name,short_name,address,email,site,created_at,updated_at,code," +
    "country_id,accounting_centre_company_id,vat_number,vessel_id,management_company_id,financial_email," +
    "bank_cgs_item_id,beneficiary_name,credit_term,partner_schemes,partner_incentive,partner_schemes_from," +
    "transfer_code,paid_by,owner_id,activity_status,client_status,person_in_charge,purchasing_email,banks,accounts," +
    "company_type_ids,phones,extra_data,logo_data,labels,due_payments_notification,due_payments_notification_frequency"
}

const sortOptions = (sort) => {
  if (!sort || isEmpty(sort.field)) return 'short_name';
  return `${ sort.direction === 'asc' ? '' : '-' }${ sort.field }`;
}

const searchOptions = (search) => isEmpty(search) ? null : { search };
export default {
  show: (id) => api.read({ type, id }, { query: { fields: showFields } }),
  index: (pagination, sort, search, filter, settings, options = {fields: null}) => api.read({ type }, {
      query: {
        fields: options.fields || indexFields,
        sort: sortOptions(sort),
        page: { size: pagination.itemsPerPage, number: pagination.activePage },
        filter: {
          ...filter,
          ...searchOptions(search),
        }
      }
    }),
  create: (attributes) => api.create({ type }, {}, attributes),
  update: (id, attributes) => api.update({ id, type }, {}, attributes),
  delete: (id) => api.destroy({ id, type }),
  owners: (id) => api.read({ type, id, action: 'owners' }, {}),
  vessels: (id, statement_id) => api.read({ type, id, action: 'vessels' }, {query: {statement_id}}),
  init_balance: (id, attributes) => api.update({ type, id, action: 'init_balance' }, {}, attributes),

  invitationLink: (id) => api.read({ type, id, action: 'invitation_links' }, {}),
  invitationLinkCreate: (id) => api.update({ type, id, action: 'create_link' }, {}, {}),
  invitedUsers: (id) => api.read({ type, id, action: 'invited_users' }, {}),
  deactivateInvitedUser: (id, userId) => api.update({ type, id, action: 'deactivate_invited_user' }, { query: { user_id: userId }}, {}),
};
