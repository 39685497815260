import mailerRedux from '../shared/redux';

export const {
  constants,
  actions,
  initialState,
  actionHandlers,
  reducer,
  selector
} = mailerRedux('PURCHASE_ORDER', 'purchaseOrders')
