import omit from 'lodash/omit';

export const indexDetailsPayload = (filter) => ({
  [filter.type]: true,
  payroll_id: filter.payrollId,
  payroll_employee_id: filter.payrollEmployeeId
});

export const createPayload = (data) => ({
  data: {
    type: "payrolls",
    attributes: {
      currency: "USD",
      sum: 0,
      status: 'draft',
      ...omit(data, 'company_name')
    }
  }
});

export const createBankAccountPayload = (item, form) => ({
  employee_id: item.currentEmployee,
  ...form
});

export const updateBankAccountPayload = (id, item, form) => ({
  data: {
    type: "employee_bank_accounts",
    id,
    attributes: {
      employee_id: item.currentEmployee,
      ...form
    }
  }
});

export const updateBankDetailsPayload = (id, account_id) => ({
  data: {
    type: "payroll_bank_details",
    id,
    attributes: {
      account_id
    }
  }
});

export const splitBankDetailsPayload = (options) => ( {options} )

export const updateEmployeePayload = (type, id, attributes) => ({
  data: {
    type,
    id,
    attributes
  }
})

export const updateDetailsPayload = (id, attributes) => ({
  data: {
    type: 'payroll_details',
    id,
    attributes
  }
})

export const updatePayrollPayload = (id, attributes) => ({
  data: {
    type: 'payrolls',
    id,
    attributes
  }
})

export const toHashMap = (collection, column = 'id') => (
  collection.reduce((acc, item) => ({
    ...acc,
    [item[column]]: item
  }), {})
);
