import axios from 'axios';
import zipObject from 'lodash/zipObject';

import { TOKEN_AUTH_HEADERS } from 'constants/index';

export const getApiBase = () => {
  if (process.env.REACT_APP_API_BASE) {
    return process.env.REACT_APP_API_BASE;
  }

  return process.env.NODE_ENV === 'production' ? 'https://api.finvisor.eu' : 'http://localhost:3131';
};

export const getWSBase = () => {
  const ws = getApiBase().replace('http', 'ws');
  return `${ws}/notifications`;
}


export const authHeaders = (contentType = 'application/json') => ({
  'token-type': 'Bearer',
  'Content-Type': contentType,
  ...zipObject(TOKEN_AUTH_HEADERS, TOKEN_AUTH_HEADERS.map((header) => localStorage.getItem(header)))
});

export const authRequestHeaders = (config) => {
  config.headers = authHeaders();
  return config;
};

const api = axios.create({
  baseURL: getApiBase()
});

api.interceptors.request.use(authRequestHeaders);

export default api;
