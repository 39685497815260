import * as api from '../api/adapters/json_api';

export default {

  index: async ({ search, type, ...rest }) => {
    const { data } = await api.read('select_options', {
      query: {
        q: search,
        type,
        ...rest,
      },
    });

    return data;
  },
};
