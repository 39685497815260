import gql from 'graphql-tag';

export const users = gql`
  {
    users {
      id
      name
      email
    }
  }
`;

export const user = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      name
      email
    }
  }
`;

export const me = gql`
  {
    me {
      id
      name
      email
      role
      avatar
      can_delete_models
    }
  }
`;
