import React, { useCallback, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect } from "react";
import {Dimmer, Loader, Segment} from 'semantic-ui-react'

const HTMLEditor = ({ content, changeContent, loading }) => {

  const [htmlContent, setHtmlContent] = useState(content);

  const handleEditorChange = useCallback((content, editor) => {
    setHtmlContent(content);
    changeContent(content);
  }, []);

  useEffect(() => {
    setHtmlContent(content);
  }, [content])

  return (
    <Segment>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>

      <Editor
        apiKey="nzabrdppf8vulmobh9l6n9lf3mj5k3n590s4xqi49v7s24mj"
        value={htmlContent}
        init={{
          height: 600,
          menubar: false,
          plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
          toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize  | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
          force_br_newlines: false,
          force_p_newlines: false,
          forced_root_block: '',
        }}
        onEditorChange={handleEditorChange}
      />
    </Segment>
  );
}

export default HTMLEditor;
