import * as api from '../api/adapters/json_api';


const type = 'api_resources/purchase_order_items';

export default {
  index: (id) => api.read({ type, id }),
  linkedInvoices: (id) => api.read({ type, id, action: 'invoice_items' }),
  unlinkInvoice: (id, override) => api.update({ type, id, action: 'unlink_invoice' }, override),
}
