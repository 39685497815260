import { handleActions } from "redux-actions";
import {buildRequestActions, buildRequestConstants} from "utils/reduxNew/reduxHelpers";
import {connect} from "react-redux";


export const constants = {
  ...buildRequestConstants("REQUISITION_LISTS", "CREATE_TENDER"),
  ...buildRequestConstants("REQUISITION_LISTS", "BULK_CREATE_TENDER_ITEM"),
  ...buildRequestConstants("REQUISITION_LISTS", "CREATE_TENDER_ITEM"),
  ...buildRequestConstants("REQUISITION_LISTS", "UPDATE_TENDER_ITEM"),
  ...buildRequestConstants("REQUISITION_LISTS", "DELETE_TENDER_ITEM"),
  ...buildRequestConstants("REQUISITION_LISTS", "LOAD_TENDER_TABLE"),
  ...buildRequestConstants("REQUISITION_LISTS", "GENERATE_PO"),
  ...buildRequestConstants("REQUISITION_LISTS", "REMOVE_SUPPLIER"),
}

export const actions = {
  ...buildRequestActions(constants, "createTender", "CREATE_TENDER"),
  ...buildRequestActions(constants, "bulkCreateTenderItem", "BULK_CREATE_TENDER_ITEM"),
  ...buildRequestActions(constants, "createTenderItem", "CREATE_TENDER_ITEM"),
  ...buildRequestActions(constants, "updateTenderItem", "UPDATE_TENDER_ITEM"),
  ...buildRequestActions(constants, "deleteTenderItem", "DELETE_TENDER_ITEM"),
  ...buildRequestActions(constants, "loadTenderTable", "LOAD_TENDER_TABLE"),
  ...buildRequestActions(constants, "generatePO", "GENERATE_PO"),
  ...buildRequestActions(constants, "removeSupplier", "REMOVE_SUPPLIER"),
}

const initialState = {
  loading: false,
  data: null,
  error: null,
  tenderTable: [],
}

export const reducer = handleActions({
  [constants.CREATE_TENDER_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [constants.CREATE_TENDER_SUCCESS]: (state, {payload}) => ({
    ...state,
    data: payload,
    loading: false,
  }),
  [constants.CREATE_TENDER_FAILED]: (state, {payload}) => ({
    ...state,
    loading: false,
    error: payload,
  }),
  [constants.LOAD_TENDER_TABLE_REQUEST]: (state) => ({
    ...state,
    loading: true,
  }),
  [constants.LOAD_TENDER_TABLE_SUCCESS]: (state, {payload}) => ({
    ...state,
    tenderTable: payload,
    loading: false,
  }),
  [constants.LOAD_TENDER_TABLE_FAILED]: (state, {payload}) => ({
    ...state,
    loading: false,
    error: payload,
  }),
}, initialState)


export const selectors = (state) => ({
  loading: state.requisitionListPage.tenders.loading,
  data: state.requisitionListPage.tenders.data,
  error: state.requisitionListPage.tenders.error,
  tenderTable: state.requisitionListPage.tenders.tenderTable,
});

const mapDispatchToProps = {
  createTender: actions.createTenderRequest,
  createTenderItem: actions.createTenderItemRequest,
  updateTenderItem: actions.updateTenderItemRequest,
  deleteTenderItem: actions.deleteTenderItemRequest,
  loadTenderTable: actions.loadTenderTableRequest,
  bulkCreateTenderItem: actions.bulkCreateTenderItemRequest,
  generatePO: actions.generatePORequest,
  removeSupplier: actions.removeSupplierRequest,
}

export const reduxConnect = (component) => connect(selectors, mapDispatchToProps)(component)

