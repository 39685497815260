import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { actions, constants } from './index';
import {invoiceItems, purchaseOrderItems} from '../../../utils/resources';
import {loadLinkedPO} from "../../invoices/linked-po/sagas";

export function* loadLinkedInvoices({ payload: {poiId, setLoading} }) {
  try {
    const response = yield call(purchaseOrderItems.linkedInvoices, poiId);
    yield put(actions.setLinkedInvoicesData(response.data));
    yield call(setLoading, false);
  } catch (error) {
    console.error(error)
    // yield put(actions.setError(error))
  }
}

export function* unlinkInvoice({ payload: {itemId, linkedItemId, setLoading} }) {
  try {
    yield call(setLoading, true);
    yield call(purchaseOrderItems.unlinkInvoice, itemId, {query: { linked_item_id: linkedItemId }});
    yield* loadLinkedInvoices({ payload: {poiId: itemId, setLoading} })
  } catch (error) {
    console.error(error)
    // yield put(actions.setError(error))
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.LOAD_LINKED_INVOICES_REQUEST
    ], loadLinkedInvoices),
    takeEvery([
      constants.UNLINK_INVOICE
    ], unlinkInvoice),


  ];
}
