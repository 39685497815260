import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { toast } from 'react-semantic-toasts';
import { actions, constants } from './index';
import { purchaseOrders } from '../../../utils/resources';

import client from 'apiClient';
import refetch from 'apollo-magic-refetch'

export function* getDocumentInfo({ payload: documentId }) {
  try {
    const response = yield call(purchaseOrders.show, documentId);
    yield put(actions.setDocument(response.data.data));
  } catch (error) {
    console.error(error)
    yield put(actions.setError(error))
  }
}

export function* getProcessInfo({ payload: documentId }) {
  try {
    const response = yield call(purchaseOrders.approve_process, documentId);
    yield put(actions.approveProcessSet(response.data.data));
  } catch (error) {
    console.error(error)
    yield put(actions.setError(error))
  }
}

export function* startApprove({ payload: { id, rounds } }) {
  try {
    yield call(purchaseOrders.start_approve, { id, rounds });
    yield call(refetch, client, 'PurchaseOrder');
    yield delay(500);
    yield put(actions.setStartApproveSuccess('Approve process started!'));
  } catch (error) {
    console.error(error)
    yield put(actions.setError(error))
  }

}

export function* approvePO({ payload: documentId }) {
  try {
    yield call(purchaseOrders.approve, documentId);
    yield call(refetch, client, 'PurchaseOrder');
    yield delay(500);
    yield put(actions.setStartApproveSuccess('Request APPROVED!'));
  } catch (error) {
    console.error(error)
    yield put(actions.setError(error))
  }
}

export function* rejectPO({ payload: documentId }) {
  try {
    yield call(purchaseOrders.reject, documentId);
    yield call(refetch, client, 'PurchaseOrder');
    yield delay(500);
    yield put(actions.setStartApproveSuccess('Request REJECTED!'));
  } catch (error) {
    console.error(error)
    yield put(actions.setError(error))
  }
}

export function* showError({ payload: error }) {
  yield call(toast, { type: 'error', title: 'Something went wrong', description: error.message })
}

export function* showSuccess({ payload: message }) {
  yield call(toast, { type: 'success', title: 'Well done!', description: message })
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.GET_DOCUMENT_REQUEST
    ], getDocumentInfo),
    takeEvery([
      constants.START_APPROVE_REQUEST
    ], startApprove),
    takeEvery([
      constants.APPROVE_PROCESS_REQUEST
    ], getProcessInfo),
    takeEvery([
      constants.APPROVE_PO
    ], approvePO),
    takeEvery([
      constants.REJECT_PO
    ], rejectPO),
    takeEvery([
      constants.SET_ERROR
    ], showError),
    takeEvery([
      constants.SET_START_APPROVE_SUCCESS
    ], showSuccess),

  ];
}
