import React from 'react';
import { Button } from 'semantic-ui-react'
import withSettings from 'features/settings';
import { withContext } from 'components/App/Context';

const styles = {
  paddingTop: '10px'
} 

const PeriodTypeSelect = ({ periodType, setPeriodType, context: { update } }) => { 
  
  const handleChangePeriodType = (e, data) => {
    setPeriodType(e, data);
    update();
  }; 
  
  return (
    <Button.Group size="small" style={ styles }>
      <Button primary={ periodType === 'date' } type="date" onClick={ handleChangePeriodType }>By Date</Button>
      <Button primary={ periodType === 'year' } type="year" onClick={ handleChangePeriodType }>By Budget year</Button>
    </Button.Group>
  )
}

export const InvoicesPeriodSelect = withSettings('invoices')(withContext(PeriodTypeSelect));
export const POPeriodSelect = withSettings('purchaseOrders')(withContext(PeriodTypeSelect));
export const PIPeriodSelect = withSettings('paymentInstructions')(withContext(PeriodTypeSelect));
