import { handleActions, createAction } from 'redux-actions';

export const constants = {
  LOAD_LINKED_INVOICES_REQUEST: "@PURCHASE_ORDERS/LINKED_INVOICES/LOAD_LINKED_INVOICES_REQUEST",
  SET_LINKED_INVOICES_DATA: "@PURCHASE_ORDERS/LINKED_INVOICES/SET_LINKED_INVOICES_DATA",
  UNLINK_INVOICE: "@PURCHASE_ORDERS/LINKED_INVOICES/UNLINK_INVOICE",
}

export const actions = {
  loadLinkedInvoices: createAction(constants.LOAD_LINKED_INVOICES_REQUEST),
  setLinkedInvoicesData: createAction(constants.SET_LINKED_INVOICES_DATA),
  unlinkInvoice: createAction(constants.UNLINK_INVOICE),
}

export const initialState = {
  data: [],
  poiId: undefined
}

export const actionHandlers = {
  [constants.LOAD_LINKED_INVOICES_REQUEST]: (state, { payload: {poiId} }) => ({ ...state, linkedInvoices: { ...state.linkedInvoices, poiId } }),
  [constants.SET_LINKED_INVOICES_DATA]: (state, {payload: data}) => ({ ...state, linkedInvoices: { ...state.linkedInvoices, data} }),
}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state.purchaseOrders.linkedInvoices.data,
  poiId: state.purchaseOrders.linkedInvoices.poiId,
})
