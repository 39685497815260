import { handleActions, createAction } from 'redux-actions';
import { createSelector } from 'reselect'

export const constants = {
  APPROVE_PO_REQUEST: "@PURCHASE_ORDERS/APPROVE/APPROVE_PO_REQUEST",
  APPROVE_PO_CANCEL: "@PURCHASE_ORDERS/APPROVE/APPROVE_PO_CANCEL",
  START_APPROVE_REQUEST: "@PURCHASE_ORDERS/APPROVE/START_APPROVE_REQUEST",
  SET_START_APPROVE_SUCCESS: "@PURCHASE_ORDERS/APPROVE/SET_START_APPROVE_SUCCESS",
  SET_LOADING: "@PURCHASE_ORDERS/APPROVE/SET_LOADING",
  SET_ERROR: "@PURCHASE_ORDERS/APPROVE/SET_ERROR",
  SET_DATA: "@PURCHASE_ORDERS/APPROVE/SET_DATA",
  GET_DOCUMENT_REQUEST: '@PURCHASE_ORDERS/APPROVE/GET_DOCUMENT_REQUEST',
  SET_DOCUMENT: '@PURCHASE_ORDERS/APPROVE/SET_DOCUMENT',
  APPROVE_PROCESS_REQUEST: "@PURCHASE_ORDERS/APPROVE/APPROVE_PROCESS_REQUEST",
  APPROVE_PROCESS_SET: "@PURCHASE_ORDERS/APPROVE/APPROVE_PROCESS_SET",
  APPROVE_PROCESS_CLOSE: "@PURCHASE_ORDERS/APPROVE/APPROVE_PROCESS_CLOSE",
  APPROVE_PO: "@PURCHASE_ORDERS/APPROVE/APPROVE_PO",
  REJECT_PO: "@PURCHASE_ORDERS/APPROVE/REJECT_PO",
};

export const actions = {
  approvePORequest: createAction(constants.APPROVE_PO_REQUEST),
  approvePOCancel: createAction(constants.APPROVE_PO_CANCEL),
  startApproveRequest: createAction(constants.START_APPROVE_REQUEST),
  setStartApproveSuccess: createAction(constants.SET_START_APPROVE_SUCCESS),
  setLoading: createAction(constants.SET_LOADING),
  setError: createAction(constants.SET_ERROR),
  setData: createAction(constants.SET_DATA),
  getDocumentRequest: createAction(constants.GET_DOCUMENT_REQUEST),
  setDocument: createAction(constants.SET_DOCUMENT),
  approveProcessRequest: createAction(constants.APPROVE_PROCESS_REQUEST),
  approveProcessSet: createAction(constants.APPROVE_PROCESS_SET),
  approveProcessClose: createAction(constants.APPROVE_PROCESS_CLOSE),
  approvePO: createAction(constants.APPROVE_PO),
  rejectPO: createAction(constants.REJECT_PO),
};

export const initialState = {
  documentId: undefined,
  document: undefined,
  showRequest: false,
  loading: false,
  error: undefined,
  data: undefined,
  processData: undefined
};

export const actionHandlers = {
  [constants.APPROVE_PO_REQUEST]: (state, { payload: documentId }) => ({ ...state, approve: { ...initialState, documentId, showRequest: true } }),
  [constants.APPROVE_PO_CANCEL]: (state) => ({ ...state, approve: { ...initialState } }),
  [constants.START_APPROVE_REQUEST]: (state) => ({ ...state, approve: { ...state.approve, loading: true } }),
  [constants.SET_START_APPROVE_SUCCESS]: (state) => ({ ...state, approve: { ...initialState } }),
  [constants.SET_LOADING]: (state, { payload: loading }) => ({ ...state, approve: { ...state.approve, loading } }),
  [constants.SET_ERROR]: (state, { payload: error }) => ({ ...state, approve: { ...state.approve, error, loading: false } }),
  [constants.SET_DATA]: (state, { payload: data }) => ({ ...state, approve: { ...state.approve, data, loading: false } }),
  [constants.GET_DOCUMENT_REQUEST]: (state) => ({ ...state, approve: { ...state.approve, loading: true } }),
  [constants.APPROVE_PO]: (state) => ({ ...state, approve: { ...state.approve, loading: true } }),
  [constants.REJECT_PO]: (state) => ({ ...state, approve: { ...state.approve, loading: true } }),
  [constants.SET_DOCUMENT]: (state, { payload: document }) => ({ ...state, approve: { ...state.approve, document, loading: false } }),
  [constants.APPROVE_PROCESS_REQUEST]: (state) => ({ ...state, approve: { ...state.approve, loading: true } }),
  [constants.APPROVE_PROCESS_SET]: (state, { payload: processData }) => ({ ...state, approve: { ...state.approve, processData, loading: false } }),
  [constants.APPROVE_PROCESS_CLOSE]: (state) => ({ ...state, approve: { ...state.approve, processData: undefined } })
}

export const reducer = handleActions(actionHandlers, initialState);

const getUsers = (state) => state.users.data;

const usersSelector = createSelector(
  [getUsers],
  (data) => data.reduce((acc, { id, attributes: { name } }) => ({ ...acc, [id]: name }), {})
);

export const selector = (state) => (
  {
    documentId: state.purchaseOrders.approve['documentId'],
    document: state.purchaseOrders.approve['document'],
    showRequest: state.purchaseOrders.approve['showRequest'],
    loading: state.purchaseOrders.approve['loading'],
    error: state.purchaseOrders.approve['error'],
    data: state.purchaseOrders.approve['data'],
    processData: state.purchaseOrders.approve['processData'],
    users: usersSelector(state)
  }
);
