import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from 'utils/resources/reports';

import { downloadStatementOfAccountReport } from 'utils/api/attachments';


export function* fetchTotalStatement({payload}) {
  try {
    yield put(actions.setLoading(true));
    yield put(actions.showStatementModal());

    const { data } = yield call(Api.show, 'statement', 'json', payload);

    yield put(actions.setData(data));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setErrors(error))
  }
}

export function* fetchCompanyStatement({payload}) {
  try {
    const { companyId, companyName, customerId, customerName, our } = payload;

    const options = {companyId, customerId, our};
    const fileName = `Statement ${companyName}|${customerName}`;

    yield call(downloadStatementOfAccountReport, options, fileName);

  } catch (error) {
    yield put(actions.setErrors(error))
  }
}



export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.TOTAL_STATEMENT_REQUEST,
    ], fetchTotalStatement),
    takeEvery([
      constants.COMPANY_STATEMENT_DOWNLOAD,
    ], fetchCompanyStatement),

  ];
}
