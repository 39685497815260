
import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import {createAction} from "redux-actions";
import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {actions as psStatementActions} from "../../Statements/utils/redux";
import {actions as psPeriodActions} from "../../Periods/utils/redux";
import {actions as psDetailsActions} from "../../Details/utils/redux";

import Api from "utils/resourcesApi";


const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  RESTORE_INVOICE: '@PARTNER_SCHEMES_EXCLUDED_INVOICE/RESTORE_INVOICE',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  restoreInvoice: createAction(constants.RESTORE_INVOICE),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,

});

const newSagas = (baseSagas, constants, actions) => {

  function* reloadData() {
    yield put(psStatementActions.loadItemsRequest());
    yield put(psPeriodActions.loadItemsRequest());
    yield put(psDetailsActions.loadItemsRequest());
    yield put(actions.loadItemsRequest());
  }


  function* restoreInvoice({ payload: {invoice_id, cb} }) {
    try {
      const period = yield select(s => s.partnerSchemesPeriods.currentPeriod);

      yield call(Api.partnerSchemesPeriods.restore, period.id, invoice_id);
      if (cb) yield call(cb);
      yield reloadData();
    } catch (error) {
      console.error(error);
    }
  }


  return [
    takeEvery(constants.RESTORE_INVOICE, restoreInvoice),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  currentStatement: state.partnerSchemesStatements.currentStatement,
  currentPeriod: state.partnerSchemesPeriods.currentPeriod,
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
