import { handleActions, createAction } from 'redux-actions';

export const constants = {
  SET_PAID_REQUEST: "@INVOICES/WIRED_STATUS_CELL/SET_PAID_REQUEST",
  SET_PAID_SUCCESS: "@INVOICES/WIRED_STATUS_CELL/SET_PAID_SUCCESS",
  SET_PAID_ERROR: "@INVOICES/WIRED_STATUS_CELL/SET_PAID_ERROR",
  ADD_CHARGES_OPEN: "@INVOICES/WIRED_STATUS_CELL/ADD_CHARGES_OPEN",
  ADD_CHARGES_CLOSE: "@INVOICES/WIRED_STATUS_CELL/ADD_CHARGES_CLOSE",
  ADD_CHARGES_REQUEST: "@INVOICES/WIRED_STATUS_CELL/ADD_CHARGES_REQUEST",
  ADD_CHARGES_SUCCESS: "@INVOICES/WIRED_STATUS_CELL/ADD_CHARGES_SUCCESS",
  ADD_CHARGES_ERROR: "@INVOICES/WIRED_STATUS_CELL/ADD_CHARGES_ERROR",
};

export const actions = {
  setPaidRequest: createAction(constants.SET_PAID_REQUEST),
  setPaidSuccess: createAction(constants.SET_PAID_SUCCESS),
  setPaidError: createAction(constants.SET_PAID_ERROR),
  addChargesOpen: createAction(constants.ADD_CHARGES_OPEN),
  addChargesClose: createAction(constants.ADD_CHARGES_CLOSE),
  addChargesRequest: createAction(constants.ADD_CHARGES_REQUEST),
  addChargesSuccess: createAction(constants.ADD_CHARGES_SUCCESS),
  addChargesError: createAction(constants.ADD_CHARGES_ERROR),
};

export const initialState = {
  invoiceId: null,
  errors: [],
  loading: false,
  dialogOpened: false,
  payments: [],
};

export const actionHandlers = {
  [constants.ADD_CHARGES_OPEN]: (state, actions) => ({ ...state, dialogOpened: true, ...actions.payload }),
  [constants.ADD_CHARGES_CLOSE]: (state) => ({ ...state, dialogOpened: false, invoiceId: null, payments: [] }),
  [constants.SET_PAID_REQUEST]: (state, action) => ({ ...state, invoiceId: action.payload.invoiceId, loading: true }),
  [constants.SET_PAID_SUCCESS]: () => ({ ...initialState }),
  [constants.SET_PAID_ERROR]: (state, actions) => ({ ...state, errors: actions.payload }),
  [constants.ADD_CHARGES_REQUEST]: (state, action) => ({ ...state, invoiceId: action.payload.invoiceId, loading: true }),
  [constants.ADD_CHARGES_SUCCESS]: () => ({ ...initialState }),
  [constants.ADD_CHARGES_ERROR]: (state, actions) => ({ ...state, errors: actions.payload }),
};

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  wiredStatusCell: state['invoices'].wiredStatusCell
});
