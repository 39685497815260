import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  showSelectForm: actions.showSelectForm,
  setFilter: actions.setFilter,
  changeFilter: actions.changeFilter,
  applyFilter: () => actions.applyFilter(),
  setCurrentInvoice: actions.setCurrentInvoice,
};


export default connect(selector, mapDispatchToProps);
