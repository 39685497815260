import React, { useState, useEffect, useCallback } from 'react'
import { Table, Button, Modal, Icon, Label, Message, Segment } from 'semantic-ui-react'

import UserSelect from './UserSelect';

import './styles.scss';

const initialFlowState = { 0: [[]] };
const initialTableState = [{ users: [[]] }]

const ApproveRequestForm = ({
  showRequest,
  documentId,
  document,
  error,
  approvePOCancel,
  getDocumentRequest,
  startApproveRequest,
  loading
}) => {

  const [flow, setFlow] = useState({ ...initialFlowState });
  const [tableData, setTableData] = useState([...initialTableState]);

  useEffect(() => {
    if (documentId) getDocumentRequest(documentId)
  }, [documentId])

  useEffect(() => {
    setFlow({ ...initialFlowState });
    setTableData([...initialTableState]);
  }, [documentId]);

  useEffect(() => {
    const newFlow = tableData.reduce((acc, row, index) => ({ ...acc, [index]: row.users }), {});
    setFlow(newFlow);
  }, [tableData])

  const handleAddRound = useCallback(() => {
    setTableData([...tableData, { users: [[]] }])
  })

  const handleAddGroup = useCallback((e, { 'data-round': round }) => {
    tableData[round].users.push([]);
    setTableData([...tableData]);
  })

  const handleRemoveRound = useCallback((e, { 'data-round': round }) => {
    delete tableData[round];
    setTableData(tableData.filter(Boolean));
  })

  const handleRemoveGroup = useCallback((e, data) => {
    const { 'data-group': group, 'data-round': round } = data;
    delete tableData[round].users[group];
    tableData[round].users = tableData[round].users.filter(Boolean)
    setTableData([...tableData]);
  })

  const handleChangeGroup = useCallback((e, data) => {
    const { 'data-group': group, 'data-round': round, value } = data;
    tableData[round].users[group] = value;
    setTableData([...tableData]);
  })

  const handleStartApprove = useCallback(() => {
    startApproveRequest({ id: documentId, rounds: flow })
  })

  return (
    <Modal closeIcon open={showRequest} onClose={approvePOCancel}>
      <Modal.Header>Create approve flow for {document && document.attributes.presentation}</Modal.Header>
      <Modal.Content>
        <Segment basic loading={loading}>
          {error &&
            <Message error>
              <pre>{error.message}</pre>
            </Message>
          }
          <Message>
            {document && <pre>{document.attributes.description}</pre>}
          </Message>
          <Table compact celled definition className="approve-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>Round</Table.HeaderCell>
                <Table.HeaderCell>Users</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                tableData.map((row, indexRound) =>
                  (
                    <Table.Row key={indexRound}>
                      <Table.Cell collapsing>
                        <Button basic circular secondary data-round={indexRound} icon="add user" onClick={handleAddGroup} />
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="center">
                        <Label circular>{indexRound + 1}</Label>
                      </Table.Cell>
                      <Table.Cell>
                        {
                          row.users.map((group, indexGroup) => (
                            <span key={indexGroup}>
                              {indexGroup > 0 && ' AND '}
                              <UserSelect
                                key={indexGroup}
                                value={group}
                                round={indexRound}
                                group={indexGroup}
                                onChange={handleChangeGroup}
                              />
                              <Icon name="remove" data-round={indexRound} data-group={indexGroup} onClick={handleRemoveGroup} className="icon-delete-group" />
                            </span>
                          ))
                        }
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Button basic circular color="red" data-round={indexRound} icon="remove circle" onClick={handleRemoveRound} />
                      </Table.Cell>
                    </Table.Row>
                  ))
              }
            </Table.Body>
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan='4'>
                  <Button
                    floated='right'
                    icon="add circle"
                    labelPosition='left'
                    primary
                    size='small'
                    onClick={handleAddRound}
                    content="Add Round"
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={approvePOCancel}>
          Cancel
      </Button>
        <Button
          positive
          loading={loading}
          icon='clipboard check'
          labelPosition='right'
          content='Request approve'
          onClick={handleStartApprove}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ApproveRequestForm;
