
export const newStep = (sId, newStep) => ({
  data: {
    id: sId,
    type: 'statements',
    attributes: {
      step: newStep,
    }
  }
})

export const submissions = (updateItem, sId) => ({
  owners: () => {
    updateItem({ id: sId, attributes: newStep(sId, 'owners')})
  },
  vessels: () => {
    updateItem({ id: sId, attributes: newStep(sId, 'completed')})
  }
});

export const createStetmentData = (form) => ({
  data: {
    type: 'statements',
    attributes: {
      company_id: form.company_id,
      date: `${form.year}-${form.month}-1`
    }
  }
})

export const addVesselData = (props) => {
  const {sId, vId, soId, attributes: {owner_id, owner_name, name: vessel_name}} = props;
  return {
    data: {
      type: 'statement_vessels',
      attributes: {
        statement_id: sId,
        statement_owner_id: soId,
        owner_id,
        owner_name,
        vessel_name,
        vessel_id: vId
      }
    }
  }
}
export const addOwnerData = (props) => {
  const {sId, oId, label} = props;
  return {
    data: {
      type: "statement_owners",
      attributes: {
        statement_id: sId,
        owner_id: oId,
        owner_name: label,
      }
    }
  }
}
