import React from 'react';
import { toast } from 'react-semantic-toasts';
import {Link} from "react-router-dom";
import {Button, Icon} from "semantic-ui-react";


// ToastType = 'info' | 'success' | 'warning' | 'error'

const ICONS = {
  info: 'info',
  success: 'check',
  warning: 'warning',
  error: 'times',
}

const Content = ({description, url, title}) => (
  <div>
    Document:<br/> <b>{title}</b>
    <Button icon basic attached='bottom' size="mini" as={Link} to={url} color="blue">
      <Icon name='search' />
      Open document
    </Button>
  </div>
);

const content = (description, url, title) => <Content description={description} url={url} title={title}/>;

export const notifyWithUrl = (title, description, url, toastType = 'info') => {

  toast({
    type: toastType,
    icon: ICONS[toastType],
    title: description,
    description: content(description, url, title),
    time: 7000,
  });
}

export const notifyInfo = (title, description) => {
  toast({
    type: 'info',
    icon: 'info',
    title,
    description,
    time: 5000,
  });
};

export const notifySuccess = (title, description) => {
  toast({
    type: 'success',
    icon: 'check',
    title,
    description,
    time: 5000,
  });
};

export const notifyWarning = (title, description) => {
  toast({
    type: 'warning',
    icon: 'warning',
    title,
    description,
    time: 5000,
  });
};

export const notifyError = (title, description) => {
  toast({
    type: 'error',
    icon: 'times',
    title,
    description,
    time: 5000,
  });
};

export const notifyUpdate = (data) => notifyAction('updated', data);

export const notifyCreate = (data) => notifyAction('created', data);

const notifyAction = (action, {attributes: { self_present = null } = {}}) => {
  if (!self_present) return;

  const {title, url} = self_present;

  notifyWithUrl(title, `Successfully ${action}`, url, 'success');
}

