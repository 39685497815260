import React from 'react';
import {object, func, oneOfType} from 'prop-types';
import { withRouter } from 'react-router-dom';

import { validateToken } from 'utils/api/auth';

class RequireLogInWrapper extends React.Component {
  state = {
    isTokenValid: false
  }

  componentDidMount() {
    validateToken()
      .then(() => this.setState({ isTokenValid: true }))
      .catch(() => this.props.history.push('/login'));
  }

  render() {
    const { Page, ...props } = this.props;

    return (
      this.state.isTokenValid && <div>
        <Page { ...props } />
      </div>
    );
  }
}

RequireLogInWrapper.propTypes = {
  history: object,
  Page: oneOfType([func.isRequired, object.isRequired])
};

const RequireLogInWithRouter = withRouter(RequireLogInWrapper);

export default (Page) => {
  return () => <RequireLogInWithRouter Page={ Page } />;
};
