import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions, constants } from "./redux";
import Api from "utils/resources/comments";

/**
 * Make a request to load the list of comments from the API and manage the response or the error
 * @generator
 * @yields {object} - The state of the comment module from the redux store
 * @yields {Promise} - Promise object represents the result of the API request to load the comments
 * @yields {object} - Redux action to update the comment list on success or to manage the error on failure
 */
export function* loadList() {
  try {
    const { pagination, sort, search, filter } = yield select(
      (state) => state.comments,
    );
    const {
      data: { data, meta },
    } = yield call(Api.index, { pagination, sort, search, filter });

    yield put(actions.loadListSuccess(data));
    yield put(actions.setPagesStat(meta));
  } catch (error) {
    yield put(actions.loadListFailure(error));
  }
}

/**
 * Make a request to create a new comment and manage the response or the error
 * @generator
 * @param {object} action - The action object received by the generator function, contains a callback and payload property
 * @yields {Promise} - Promise object represents the result of the API request to create a new comment
 * @yields {object} - Redux action to add the new comment on success or to manage the error on failure
 * @yields {any} - The result of invoking the provided callback function on success
 */
export function* createItem({ payload: { cb, ...payload } }) {
  try {
    const { data } = yield call(Api.create, payload);

    yield put(actions.createItemSuccess(data));
    if (cb) yield call(cb);
  } catch (error) {
    yield put(actions.createItemFailure(error));
  }
}

/**
 * Register Redux-Saga watchers for the comment list and new comment creation saga
 * @returns {Array} - An array of Redux-Saga watchers for loadList and createItem actions
 */
export default function getFeatureSagas() {
  return [
    takeEvery([constants.LOAD_LIST_REQUEST], loadList),
    takeEvery([constants.CREATE_ITEM_REQUEST], createItem),
  ];
}
