import { handleActions, createAction } from 'redux-actions';

export const constants = {
  LOAD_LINKED_PO_REQUEST: "@INVOICES/LINKED_PO/LOAD_LINKED_PO_REQUEST",
  SET_LINKED_PO_DATA: "@INVOICES/LINKED_PO/SET_LINKED_PO_DATA",
  UNLINK_PO: "@INVOICES/LINKED_PO/UNLINK_PO",
}

export const actions = {
  loadLinkedPO: createAction(constants.LOAD_LINKED_PO_REQUEST),
  setLinkedPOData: createAction(constants.SET_LINKED_PO_DATA),
  unlinkPO: createAction(constants.UNLINK_PO),
}

export const initialState = {
  data: [],
  iiId: undefined
}

export const actionHandlers = {
  [constants.LOAD_LINKED_PO_REQUEST]: (state, { payload: {iiId} }) => ({ ...state, iiId }),
  [constants.SET_LINKED_PO_DATA]: (state, {payload: data}) => ({ ...state, data} ),
}

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  data: state.invoices.linkedPO.data,
  iiId: state.invoices.linkedPO.iiId,
})
