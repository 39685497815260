import React  from 'react'
import { Card, Placeholder} from 'semantic-ui-react'

const LoadingCard = ({ color }) => {

  return (
    <Card color={ color } className="dashboard-card">
      <Card.Header textAlign="right" className="card-header">
        O
      </Card.Header>
      <Card.Content className="header">
        <Card.Header>
          Resource header   
        </Card.Header>
      </Card.Content>
      <Card.Content 
        textAlign="center" 
        description={ <h4>$ 999 999.99</h4> } 
      />
      <Card.Content extra className="footer">
        Details
      </Card.Content>
    </Card>
  )
}

export default LoadingCard;
