import React from "react";
import {Button} from "semantic-ui-react";

const styles = {
  button: {
    fontFamily: 'monospace',
    minWidth: '450px',
    textAlign: 'left',
    fontSize: '90%'
  }
};

const RelatedDocItem = ({doc}) => {

  return (
    <Button
      circular
      basic
      as={!doc.no_link && 'a'}
      href={!doc.no_link && doc.url}
      target="_blank"
      content={doc.title}
      icon={doc.no_link ? 'info' : 'external alternate'}
      labelPosition="right"
      style={styles.button}
    />
  )
}

export default RelatedDocItem;