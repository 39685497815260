/**
 * The "api_resources/comments" module exports methods to deal with API resources related to comments.
 * These methods include show, index, create, update and delete.
 *
 * @module api_resources/comments
 */

import * as api from "../api/adapters/json_api";
import isEmpty from "lodash/isEmpty";

/**
 * @constant type
 * @type {string}
 * @description The type of API resource.
 */
const type = "api_resources/comments";

/**
 * @function searchOptions
 * @param {string} search - The search term.
 * @returns {Object|null} - Returns an object with 'search' as key and given search term as value. If search term is empty then it returns null.
 * @description This function is used to form the query param for the search term.
 */
const searchOptions = (search) => (isEmpty(search) ? null : { search });

export default {
  /**
   * @function show
   * @param {string|number} id - The ID of the comment.
   * @returns {Promise} Promise that resolves with a comment object or rejects with an error.
   * @description This function retrieves a single comment by its ID.
   */
  show: (id) => api.read({ type, id }, {}),

  /**
   * @function index
   * @param {Object} pagination - The pagination information object.
   * @param {Object} sort - The sort information object.
   * @param {string} search - The search term.
   * @param {Object} filter - The filter information object.
   * @param {Object} settings - The settings object.
   * @returns {Promise} Promise that resolves with an array of comment objects or rejects with an error.
   * @description this function retrieves a list of comments with sorting, search and filter parameters.
   */
  index: ({ pagination, sort, search, filter, settings }) =>
    api.read(
      { type },
      {
        query: {
          sort: "-created_at",
          page: {
            size: pagination.itemsPerPage,
            number: pagination.activePage,
          },
          filter: {
            ...filter,
            ...searchOptions(search),
          },
        },
      },
    ),

  /**
   * @function create
   * @param {Object} attributes - The attributes of the new comment.
   * @returns {Promise} Promise that resolves with the created comment object or rejects with an error.
   * @description this function creates a new comment.
   */
  create: (attributes) =>
    api.create({ type }, {}, { data: { type: "comments", attributes } }),

  /**
   * @function update
   * @param {string|number} id - The ID of the comment that needs to be updated.
   * @param {Object} attributes - The updated attributes of the comment.
   * @returns {Promise} Promise that resolves with the updated comment object or rejects with an error.
   * @description this function updates an existing comment.
   */
  update: (id, attributes) =>
    api.update(
      { id, type },
      {},
      { data: { id, type: "comments", attributes } },
    ),


  /**
   * @function delete
   * @param {string|number} id - The ID of the comment to be deleted.
   * @returns {Promise} - Promise that resolves if deletion is successful or rejects with an error.
   * @description This function deletes a comment.
   */
  delete: (id) => api.destroy({ id, type }),
};
