import { all } from 'redux-saga/effects';

import dictionaries from './features/dictionaries/sagas';

import invoicesWiredSagas from './features/invoices/wired-status-cell/sagas';
import invoicesAttachmentsSagas from './features/invoices/attachment-cell/sagas';
import invoicesLinkedPOSagas from './features/invoices/linked-po/sagas';
import { sagas as piSagas } from './features/payment-instructions/redux';
import { sagas as poSagas } from './features/purchase-orders/redux';
import { sagas as invSagas } from './features/invoices/redux';
import { sagas as dnSagas } from './features/delivery-notes/redux';
import { sagas as sfSagas } from './features/service-fees/redux';

import { sagas as psStatementsSagas } from './features/partner-schemes/component/Statements/utils/redux';
import { sagas as psPeriodsSagas } from './features/partner-schemes/component/Periods/utils/redux';
import { sagas as psDetailsSagas } from './features/partner-schemes/component/Details/utils/redux';
import { sagas as psExcludedInvoicesSagas } from './features/partner-schemes/component/ExcludedInvoices/utils/redux';

import { sagas as payrollSagas } from './features/payrolls/redux';
import { sagas as poSagasTotal } from './features/components/totals/purchase_orders';
import { sagas as attachmentSagas } from './features/attachments/redux';
import { sagas as requisitionListsSagas } from './features/requisition-lists/redux';
import deleteRelatedDocumentsSagas from './features/components/related-documents/delete/sagas';
import poApproveSagas from './features/purchase-orders/approve/sagas';
import dashboardSagas from './features/dashboard/sagas';
import poMailSagas from './features/components/mailers/purchase_order/sagas';
import poLinkedInvoicesSagas from './features/purchase-orders/linked-invoices/sagas';
import poFillFromInvoiceSagas from './features/purchase-orders/fill-from-invoice/sagas';

import { sagas as companiesSagas } from "./features/companies/redux";
import companiesStatementSagas from './features/companies/statement/sagas';
import assignOwnerCompanySagas from './features/assign-owner-company/sagas';
import companyBalanceSagas from './features/companies/balance/sagas';
import companyInvitationLinksSagas from './features/companies/Form/Tabs/Partnership/InvitationLinks/sagas';

import tagSagas from './features/components/tags/sagas';
import commentSagas from './features/components/comments/sagas';
import companyMentionsSagas from './features/components/mentions/sagas';

import statusHistoriesSagas from './features/components/timeline-status-history/sagas';

import requisitionListsTenderSagas from './features/requisition-lists/tenders/sagas';


function* initApp() {
  for (var dictionary of dictionaries) {
    yield dictionary.fetchDictionary();
  }
}

/**
 * rootSaga
 * @returns {IterableIterator<*>}
 */
export default function* root() {
  yield all([
    ...dictionaries.flatMap(saga => saga.getSagas()),
    ...piSagas(),
    ...invSagas(),
    ...dnSagas(),
    ...sfSagas(),
    ...psStatementsSagas(),
    ...psPeriodsSagas(),
    ...psDetailsSagas(),
    ...psExcludedInvoicesSagas(),
    ...poSagasTotal(),
    ...poSagas(),
    ...poLinkedInvoicesSagas(),
    ...payrollSagas(),
    ...poApproveSagas(),
    ...invoicesWiredSagas(),
    ...invoicesAttachmentsSagas(),
    ...invoicesLinkedPOSagas(),
    ...dashboardSagas(),
    ...deleteRelatedDocumentsSagas(),
    ...poMailSagas(),
    ...poFillFromInvoiceSagas(),
    ...companiesStatementSagas(),
    ...attachmentSagas(),
    ...companiesSagas(),
    ...assignOwnerCompanySagas(),
    ...companyBalanceSagas(),
    ...requisitionListsSagas(),
    ...statusHistoriesSagas(),
    ...tagSagas(),
    ...commentSagas(),
    ...companyMentionsSagas(),
    ...companyInvitationLinksSagas(),
    ...requisitionListsTenderSagas(),
  ]);

  yield initApp();
}
