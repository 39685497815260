import capitalize from 'lodash/capitalize';

export const setPageTitle = (model, id, ref, date) => {
  let title = 'Finvisor |';
  if (!id) {
    title = `${title} New ${model} ...`;
  } else {
    title = `${title} ${model} ${ref}`;
    if (date) {
      title = `${title} from ${date}`;
    }
  }

  document.title = title;
};

export const urlResource = (path) => {
  const resource = path.split('/')[1]; // eslint-disable-line no-magic-numbers

  if (resource === '') {
    return 'dashboard';
  }

  return resource;
};

export default (path) => {
  return capitalize(urlResource(path));
};
