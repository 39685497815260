
import * as commonActionHandlers from 'utils/redux/commonActionHandlers';
import {createAction} from "redux-actions";
import get from "lodash/get";
import {put, takeEvery, call, select} from "@redux-saga/core/effects";
import { actions as periodsActions } from '../../Periods/utils/redux';
import { actions as detailsActions } from '../../Details/utils/redux';
import Api from 'utils/resourcesApi';


const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  UPDATE_ALL: '@PARTNER_SCHEMES_STATEMENTS/UPDATE_ALL',
  UPDATE_DATA: '@PARTNER_SCHEMES_STATEMENTS/UPDATE_DATA',
  SET_TOTALS: '@PARTNER_SCHEMES_STATEMENTS/SET_TOTALS',
  SET_CURRENT_STATEMENT: '@PARTNER_SCHEMES_STATEMENTS/SET_CURRENT_STATEMENT',
  SET_PERIODS_FILTER: '@PARTNER_SCHEMES_STATEMENTS/SET_PERIODS_FILTER',
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  updateAll: createAction(constants.UPDATE_ALL),
  updateData: createAction(constants.UPDATE_DATA),
  setTotals: createAction(constants.SET_TOTALS),
  setCurrentStatement: createAction(constants.SET_CURRENT_STATEMENT),
  setPeriodsFilter: createAction(constants.SET_PERIODS_FILTER),
});

const extendedInitialState = {
  ...commonActionHandlers.initialState,
  filter: {
    ...commonActionHandlers.initialState.filter,
    more_500: true,
  },
  totals: {
    total: 0
  },
  currentStatement: {
    id: null,
    company_id: null,
    company_name: null,
  }
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.SET_TOTALS]: (state, { payload }) => ({
    ...state,
    totals: payload
  }),
  [constants.SET_CURRENT_STATEMENT]: (state, { payload }) => ({
    ...state,
    currentStatement: payload
  }),

});

const newSagas = (baseSagas, constants, actions) => {

  function* setTotals({ payload }) {
    try {
      const totals = get(payload, 'data.meta.totals');
      yield put(actions.setTotals(totals));
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* setPeriodsFilter({ payload: id }) {
    try {
      yield put(periodsActions.changeFilter('statement_id', id));
      yield put(periodsActions.applyFilter());
      yield put(periodsActions.setCurrentPeriod({id: null}));
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* updateAll({payload: cb }) {
    try {
      const currentPeriod = yield select(state => state.partnerSchemesPeriods.currentPeriod);
      const currentStatement = yield select(state => state.partnerSchemesStatements.currentStatement);

      yield call(Api.partnerSchemesStatement.updateAll)
      yield put(actions.loadItemsRequest());

      if(currentStatement) {
        yield put(actions.setCurrentStatement(currentStatement));
        yield put(periodsActions.loadItemsRequest());
      }
      if(currentPeriod) {
        yield put(periodsActions.setCurrentPeriod(currentPeriod));
        yield put(detailsActions.loadItemsRequest());
      }

      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }

  function* updateData({payload: {id, cb} }) {
    try {
      const currentPeriod = yield select(state => state.partnerSchemesPeriods.currentPeriod);
      const currentStatement = yield select(state => state.partnerSchemesStatements.currentStatement);

      yield put(periodsActions.setCurrentPeriod({id: null}));
      yield put(actions.setCurrentStatement({id: null}));

      yield call(Api.partnerSchemesStatement.updateData, id)

      yield put(actions.loadItemsRequest());
      yield put(periodsActions.loadItemsRequest());

      if(currentStatement) yield put(actions.setCurrentStatement(currentStatement));
      if(currentPeriod) yield put(periodsActions.setCurrentPeriod(currentPeriod));

      if (cb) yield call(cb);
    } catch (e) {
      console.error(e);
      yield put(actions.starItemError(e));
    }
  }



  return [
    takeEvery(constants.ITEMS_LOADED_SUCCESSFULLY, setTotals),
    takeEvery(constants.SET_PERIODS_FILTER, setPeriodsFilter),
    takeEvery(constants.UPDATE_ALL, updateAll),
    takeEvery(constants.UPDATE_DATA, updateData),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas,
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  totals: state.partnerSchemesStatements.totals,
  currentStatement: state.partnerSchemesStatements.currentStatement,
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
