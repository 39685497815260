/**
 * Module dealing with api_resources/company_balances type
 * @module CompanyBalances
 */
import * as api from '../api/adapters/json_api';

/** @constant
 *  @type {string}
 *  @default
 *  Default type for the api resource
 */
const type = 'api_resources/company_balances';

export default {
  /**
   * Function to read the company balances
   * @method index
   * @param {Object} params - The parameters for the function
   * @param {number} params.id - The company id
   * @param {Object} params.filter - The filter for the request (default {})
   * @returns {Promise} Promise object representing the company balances
   */
  index: ({ id, filter = {}} = {}) => api.read({ type, id }, {
    query: {
      sort: '-date',
      filter
    }
  }),
  /**
   * Function to create the company balances
   * @method create
   * @param {Object} attributes - The attributes for the company balance to be created
   * @returns {Promise} Promise object representing the created company balance
   */
  create: (attributes) => api.create({ type }, {}, attributes),
  /**
   * Function to update the company balances
   * @method update
   * @param {number} id - The id of the company balance to be updated
   * @param {Object} attributes - The attributes for the company balance to be updated
   * @returns {Promise} Promise object representing the updated company balance
   */
  update: (id, attributes) => api.update({ id, type }, {}, attributes),
  /**
   * Function to delete the company balances
   * @method delete
   * @param {number} id - The id of the company balance to be deleted
   * @returns {Promise} Promise object representing the deletion status
   */
  delete: (id) => api.destroy({ id, type }),
  /**
   * Function to recalculate the company balances
   * @method recalculateBalance
   * @param {number} id - The id of the company balance to be recalculated
   * @returns {Promise} Promise object representing the recalculation status
   */
  recalculateBalance: (id) => api.update({ type, action: 'recalculate_balance' }, {query: { company_id: id }}, {})
};
