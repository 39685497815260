import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { actions, constants } from './index';
import {invoiceItems, invoices} from '../../../utils/resources';

export function* loadInvoices() {
  try {
    const pagination  = yield select(s => s.purchaseOrders.fillFromInvoice.pagination)
    const filter  = yield select(s => s.purchaseOrders.fillFromInvoice.filters)
    const response = yield call(invoices.index, pagination, {}, '', filter);
    yield put(actions.setInvoices(response.data));
  } catch (error) {
    console.error(error)
    // yield put(actions.setError(error))
  }
}

export function* loadInvoiceItems() {
  try {
    const invoiceId  = yield select(s => s.purchaseOrders.fillFromInvoice.currentInvoice)
    const response = yield call(invoiceItems.index, {invoice_id: invoiceId});
    yield put(actions.setInvoiceItems(response.data));
  } catch (error) {
    console.error(error)
    // yield put(actions.setError(error))
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.LOAD_INVOICES,
      constants.APPLY_FILTER,
    ], loadInvoices),
    takeEvery([
      constants.LOAD_INVOICES,
      constants.SET_CURRENT_INVOICE,
    ], loadInvoiceItems),


  ];
}
