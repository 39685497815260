import moment from 'moment';

import { store } from 'components/App/Context';
import { period } from 'utils/fieldsConversion';

export const TOKEN_AUTH_HEADERS = ['access-token', 'client', 'expiry', 'uid'];

export const DATE_FORMAT = 'DD.MM.YYYY';

export const JSON_DATE_FORMAT = 'YYYY-MM-DD';

export const FULL_JSON_DATE_FORMAT = moment.ISO_8601;

export const invoicesQueryVariables = (periodType) => (
  {
    companyId: store.company === undefined ? null : store.company.id,
    direction: store.invoicesDirection === undefined ? 'outgoing' : store.invoicesDirection,
    periodType,
    year: store.invoicesYear.toString()
  }
);

export const getClientId = () => {
  if (process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    return process.env.REACT_APP_GOOGLE_CLIENT_ID;
  }

  return '13350620930-s6jidq9743i3cn99ugtbrs7csgohtqjm.apps.googleusercontent.com';
};
