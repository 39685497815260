
/**
 * Serializes a given object into a URL-friendly string format.
 *
 * @param {Object} obj - The object to be serialized
 * @param {string} prefix - An optional prefix for nested properties
 * @return {string} A URL-friendly string representation of the input object
 */
export default function serialize(obj, prefix) {
  var str = [], p;
  for(p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
      if (v === null || v === undefined || v === "") continue;
      if (Array.isArray(v)) {
        for (var i = 0; i < v.length; i++) {
          str.push(encodeURIComponent(k + "[]") + "=" + encodeURIComponent(v[i]));
        }
      } else if (v !== null && typeof v === "object") {
        str.push(serialize(v, k));
      } else {
        str.push(encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
  }
  return str.join("&");
}
