import React, {useState} from "react";
import {List, Loader} from "semantic-ui-react";
import RelatedDocItem from 'components/Elements/RelatedDocuments/RelatedDocItem';
import LinkedRowItem from "./LinkedRowItem";

import './styles.scss';

const Content = ({loading, data = [], setLoading, unlinkRow, itemId}) => {

  return (
    <div>
      {
        loading ?
          <Loader active inline='centered'/> :
          <List>
            {
              data.map(docRow =>
                <List.Item>
                  <List.Content>
                    <RelatedDocItem doc={docRow.document} />
                    <List.List>
                      {
                        docRow.rows.map(itemRow =>
                          <LinkedRowItem
                            itemRow={itemRow}
                            setLoading={setLoading}
                            unlinkRow={unlinkRow}
                            itemId={itemId}
                          />
                        )
                      }
                    </List.List>
                  </List.Content>
                </List.Item>
              )
            }
          </List>
      }
    </div>
  )
}

export default Content;