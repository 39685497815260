/**
 * Returns base paths object for passed resource string.
 *
 * @function
 * @param {string} resource - The string representing resource.
 * @returns {Object} - Paths object with index and edit methods.
 */
const getBasePaths = (resource) => ({
  /**
   * Returns the resource.
   * @returns {string} resource
   */
  index() {
    return resource;
  },
  /**
   * Returns the edit path for the given ID.
   * @param {number|string} id
   * @returns {string} edit path
   */
  edit(id) {
    return `${this.index()}/${id}/edit`;
  },
  /**
   * Creates a new resource.
   *
   * @returns {string} The URL path for creating a new resource.
   */
  create() {
    return `${this.index()}/create`;
  },
});

/**
 * Returns an object with paths methods for invoices.
 * @param {boolean} [isNew=false] - Determines if the invoice is new.
 * @returns {Object} - Paths object with index, edit, pay and copy methods.
 */
export const invoices = (isNew = false) => ({
  /**
   * Returns the index path based on the isNew flag.
   * @returns {string} index path
   */
  index() {
    return isNew ? '/invoices_new' : '/invoices';
  },
  /**
   * Returns the edit path for the given ID.
   * @param {number|string} id
   * @returns {string} edit path
   */
  edit(id) {
    return `${this.index()}/${id}`;
  },
  /**
   * Returns the pay path for the given ID.
   * @param {number|string} id
   * @returns {string} pay path
   */
  pay(id) {
    return `${this.index()}/pay/${id}`;
  },
  /**
   * Returns the copy path for the given ID.
   * @param {number|string} id
   * @returns {string} copy path
   */
  copy(id) {
    return `${this.index()}/copy/${id}`;
  },
});

/**
 * An object representing purchase orders.
 *
 * {@link https://jsdoc.app/}
 *
 * @namespace
 * @function purchaseOrders
 * @returns {object} - An object with the base paths of 'purchase_orders'.
 * @property {string} index - The path to index purchase orders.
 * @property {string} create - The path to create purchase orders.
 * @property {string} edit - The path to edit purchase orders.
 * @example
 * // Returns {
 * //  "index": "/purchase_orders",
 * //  "create": "/purchase_orders/create",
 * //  "edit": "/purchase_orders/:id/edit",
 * // }
 * purchaseOrders();
 */
export const purchaseOrders = () => ({
  ...getBasePaths('/purchase_orders'),
});

/**
 * @see purchaseOrders
  */
export const deliveryNotes = () => ({
  ...getBasePaths('/delivery_notes'),
});

/**
 * @see purchaseOrders
 */
export const serviceFees = () => ({
  ...getBasePaths('/service_fees'),
});

/**
 * @see purchaseOrders
 */
export const payrolls = () => ({
  ...getBasePaths('/payrolls'),
});

/**
 * @see purchaseOrders
 */
export const paymentInstructions = () => ({
  ...getBasePaths('/payment_instructions'),
});

export const companies = () => ({
  ...getBasePaths('/companies'),
})


/**
 * Returns the path for a given resource.
 *
 * @param {string} resource - The resource to get the path for.
 * @returns {string} The path for the given resource.
 */
export const pathsByResource = (resource) => {
  let resourceName;
  if (resource.startsWith('/')) {
    const [_bl, rsName, ...rest] = resource.split('/');
    resourceName = rsName;
  } else {
    resourceName = resource.replace('/', '');
  }

  return {
    invoices: invoices(),
    invoices_new: invoices(true),
    purchase_orders: purchaseOrders(),
    delivery_notes: deliveryNotes(),
    service_fees: serviceFees(),
    payrolls: payrolls(),
    payment_instructions: paymentInstructions(),
    companies: companies(),
  }[resourceName];
};
