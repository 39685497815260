import { handleActions, createAction } from 'redux-actions';

const getJsonItem = (key) => {
  const value = localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};

const setJsonItem = (key, value) => {
  const data = JSON.stringify(value);
  localStorage.setItem(key, data);
}

const storeName = (str) => str.replace('/SET_', '/');


export default () => {

  const constants = {
    SET_COMPANY: `@SETTINGS/GLOBAL/SET_COMPANY`,
    SET_PI_COMPANIES: `@SETTINGS/GLOBAL/SET_PI_COMPANIES`,
    SET_DN_COMPANIES: `@SETTINGS/GLOBAL/SET_DN_COMPANIES`,
    SET_PO_COMPANIES: `@SETTINGS/GLOBAL/SET_PO_COMPANIES`,
    SET_INV_COMPANIES: `@SETTINGS/GLOBAL/SET_INV_COMPANIES`,
    SET_CURRENT_USER: `@SETTINGS/GLOBAL/SET_CURRENT_USER`
  };

  const actions = {
    setCompany: createAction(constants.SET_COMPANY),
    setPiCompanies: createAction(constants.SET_PI_COMPANIES),
    setDnCompanies: createAction(constants.SET_DN_COMPANIES),
    setPoCompanies: createAction(constants.SET_PO_COMPANIES),
    setInvCompanies: createAction(constants.SET_INV_COMPANIES),
    setCurrentUser: createAction(constants.SET_CURRENT_USER),
  };

  const initialState = {
    company: getJsonItem(storeName(constants.SET_COMPANY)),
    piCompanies: getJsonItem(storeName(constants.SET_PI_COMPANIES)) || [],
    dnCompanies: getJsonItem(storeName(constants.SET_DN_COMPANIES)) || [],
    poCompanies: getJsonItem(storeName(constants.SET_PO_COMPANIES)) || [],
    invCompanies: getJsonItem(storeName(constants.SET_INV_COMPANIES)) || [],
    currentUser: getJsonItem(storeName(constants.SET_CURRENT_USER)) || [],
  }

  const actionHandlers = {
    [constants.SET_COMPANY]: (state, action) => {
      setJsonItem(storeName(constants.SET_COMPANY), action.payload);
      return { ...state, company: action.payload }
    },
    [constants.SET_PI_COMPANIES]: (state, action) => {
      setJsonItem(storeName(constants.SET_PI_COMPANIES), action.payload);
      return { ...state, piCompanies: action.payload }
    },
    [constants.SET_DN_COMPANIES]: (state, action) => {
      setJsonItem(storeName(constants.SET_DN_COMPANIES), action.payload);
      return { ...state, dnCompanies: action.payload }
    },
    [constants.SET_PO_COMPANIES]: (state, action) => {
      setJsonItem(storeName(constants.SET_PO_COMPANIES), action.payload);
      return { ...state, poCompanies: action.payload }
    },
    [constants.SET_INV_COMPANIES]: (state, action) => {
      setJsonItem(storeName(constants.SET_INV_COMPANIES), action.payload);
      return {...state, invCompanies: action.payload}
    },
    [constants.SET_CURRENT_USER]: (state, action) => {
      setJsonItem(storeName(constants.SET_CURRENT_USER), action.payload);
      return { ...state, currentUser: action.payload }
    },

  }

  const reducer = handleActions(actionHandlers, initialState);

  const selector = (state) => ({
    company: state.settings.global.company,
    piCompanies: state.settings.global.piCompanies,
    dnCompanies: state.settings.global.dnCompanies,
    poCompanies: state.settings.global.poCompanies,
    invCompanies: state.settings.global.invCompanies,
    currentUser: state.settings.global.currentUser,
  });

  const mapDispatchToProps = {
    setCompany: (data) => actions.setCompany(data),
    setPiCompanies: (data) => actions.setPiCompanies(data),
    setDnCompanies: (data) => actions.setDnCompanies(data),
    setPoCompanies: (data) => actions.setPoCompanies(data),
    setInvCompanies: (data) => actions.setInvCompanies(data),
    setCurrentUser: (data) => actions.setCurrentUser(data),
    fetchPOTotals: createAction("@TOTALS/PURCHASE_ORDERS/FETCH_TOTALS_REQUEST")
  };

  return { constants, actions, initialState, actionHandlers, reducer, selector, mapDispatchToProps };

}
