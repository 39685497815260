import React, { createContext, useContext, useState, useEffect } from 'react';

const LocalStorageContext = createContext(undefined);

export const useLocalStorage = (key, initialValue) => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error('useLocalStorage must be used within a LocalStorageProvider');
  }

  // Attempt to get the existing value when the hook is first used
  const [storedValue, setStoredValue] = context[key] ?? [initialValue, () => {}];

  useEffect(() => {
    context.initializeValue(key, initialValue);
  }, [context, key, initialValue]);

  return [storedValue, (newValue) => context.setValue(key, newValue)];
};

export const LocalStorageProvider = ({ children }) => {
  const [storage, setStorage] = useState({});

  const initializeValue = (key, initialValue) => {
    const valueFromStorage = window.localStorage.getItem(key);
    const parsed = valueFromStorage ? JSON.parse(valueFromStorage) : initialValue;
    if (!storage[key]) {
      setStorage(prev => ({
        ...prev,
        [key]: [parsed, (newValue) => setValue(key, newValue)]
      }));
    }
  };

  useEffect(() => {
    // Listen for storage changes to update state accordingly
    const handleStorageChange = (event) => {
      if (storage[event.key]) {
        const newValue = JSON.parse(event.newValue);
        setStorage(prev => ({
          ...prev,
          [event.key]: [newValue, prev[event.key][1]]
        }));
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [storage]);

  const setValue = (key, value) => {
    const newValue = typeof value === 'function' ? value(storage[key][0]) : value;
    setStorage(prev => ({
      ...prev,
      [key]: [newValue, prev[key][1]]
    }));
    window.localStorage.setItem(key, JSON.stringify(newValue));
  };

  return (
    <LocalStorageContext.Provider value={{ ...storage, setValue, initializeValue }}>
      {children}
    </LocalStorageContext.Provider>
  );
};
