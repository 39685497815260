import React from 'react';
import { Table } from 'semantic-ui-react'

import sortBy from 'lodash/sortBy';
import styled from 'styled-components';

import { withPopup } from '../utils';

const StyledCell = styled(Table.Cell)`
  ${({ value }) => value > 2 ? 'text-shadow: 1px 1px 1px black, 0 0 1em red' : '' }
`;

const DetailTable = ({ vessels }) => (
  <Table compact size="small">
    <Table.Body>
      { sortBy(Object.keys(vessels)).map(vessel => (
        <Table.Row key={ vessel }>
          <Table.Cell width="6">{ vessel }</Table.Cell>
          {
            Object.keys(vessels[vessel].values).map(key => (
              <Table.Cell 
                key={ key } 
                textAlign="center" 
                className={ `table-card-value ${key}` } 
                data-value-gt={ vessels[vessel].values[key] > 2 ? '2' : null } 
              >
                { withPopup(vessels[vessel].values[key], key.toUpperCase()) }
              </Table.Cell>
            ))
          }
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default DetailTable;
