import { handleActions, createAction } from 'redux-actions';

export const constants = {
  SET_LOADING: "@INVOICES/ATTACHMENT_CELL/SET_LOADING",
  SET_ATTACHMENTS: "@INVOICES/ATTACHMENT_CELL/SET_ATTACHMENTS",
  LOAD_ATTACHMENTS_REQUEST: "@INVOICES/ATTACHMENT_CELL/LOAD_ATTACHMENTS_REQUEST",
  CLEAR_ATTACHMENTS: "@INVOICES/ATTACHMENT_CELL/CLEAR_ATTACHMENTS",
};

export const actions = {
  setLoading: createAction(constants.SET_LOADING),
  loadAttachmentsRequest: createAction(constants.LOAD_ATTACHMENTS_REQUEST),
  clearAttachments: createAction(constants.CLEAR_ATTACHMENTS),
  setAttachments: createAction(constants.SET_ATTACHMENTS),
};

export const initialState = {
  data: [],
  errors: [],
  loading: false,
};

export const actionHandlers = {
  [constants.SET_LOADING]: (state, actions) => ({ ...state, loading: actions.payload }),
  [constants.SET_ATTACHMENTS]: (state, actions) => ({ ...state, data: actions.payload }),
  [constants.CLEAR_ATTACHMENTS]: (state) => ({ ...state, data: [] }),
};

export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  attachmentCell: state['invoices'].attachmentCell
});
