import {call, put, takeEvery} from 'redux-saga/effects';
import {actions, constants} from './redux';
import {companies} from 'utils/resources';
import Api from "../../../utils/resourcesApi";
import {toast} from "react-semantic-toasts";


const indexFields = { companies: 'short_name,balance' }

export function* loadItems() {
  try {
    const filter = {sc_group: true, with_balance: true};
    const pagination = {activePage: 1, itemsPerPage: 100};
    const data = yield call(companies.index, pagination, null, null, filter, null, { fields: indexFields });
    yield put(actions.addItems(data));

  } catch (error) {
    console.error(error);
    yield put(actions.setErrors(error))
  }
}

function* updateItem({payload: {id, payload, cb}}) {
  try {
    yield call(companies.init_balance, id, payload);
    yield loadItems();
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));
  }

}

function* loadDetails({payload: {cb, ...filter}}) {

  try {
    const {data: {data}} = yield call(Api.companyBalances.index, {filter})
    yield put(actions.loadDetailsSuccess(data));
    if (cb) yield call(cb);
  } catch (error) {
    console.error(error);
    yield put(actions.loadDetailsFailure(error));
    if (cb) yield call(cb);
  }

}

function* recalculateBalance({payload: {id, cb}}) {
  try {
    yield call(Api.companyBalances.recalculateBalance, id);
    yield loadItems();
    yield call(toast, {type:'success', title: 'Balance recalculated'});
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
    yield put(actions.recalculateBalanceFailure(e));
    if (cb) yield call(cb);
  }
}


export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, loadItems),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.LOAD_DETAILS_REQUEST, loadDetails),
    takeEvery(constants.RECALCULATE_BALANCE_REQUEST, recalculateBalance),
  ];
}
