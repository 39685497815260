import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

const type = 'api_resources/partner_schemes/details';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-invoice_date' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (pagination, sort, search, filter) => api.read({ type }, {
      query: {
        sort: sortOptions(sort),
        page: { size: 20, number: pagination.activePage },
        filter: {
          search,
          ...filter,
        }
      }
    }
  ),

  indexXlsx: (pagination, sort, search, filter) => api.read({ type, action: 'index_xlsx' }, {
      query: {
        sort: sortOptions(sort),
        page: { size: 9999, number: 1 },
        filter: {
          search,
          ...filter,
        }
      }
    }
  ),



}
