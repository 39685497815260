import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';

import { piItemsToHeader } from '../converter/show'

const type = 'api_resources/service_fee/statements';

const sortOptions = (sort) => isEmpty(sort.field) ?
  'status,-starred,due_date' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }, {}),

  index: (pagination, sort, search, filter, { global, paymentInstructions }) => api.read({ type }, {
    query: {
      sort: '-date',
      page: { size: pagination.itemsPerPage, number: pagination.activePage }
    }
  }
  ),

  create: (attributes) => api.create({ type }, {}, attributes),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

  owners: (id) => api.read({ type, id, action: 'owners' }, {}),

  vessels: (id) => api.read({ type, id, action: 'vessels' }, {}),

  createInvoices: (id) => api.update({ type, id, action: 'create_invoices' }, {}, {}),

  updateFromCW: (id) => api.update({ type, id, action: 'update_from_cw' }, {}, {}),
}
