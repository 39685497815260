import React from 'react';
import { Statistic, Popup } from 'semantic-ui-react';

import { formatSumUsd } from 'utils/fieldsConversion';

import styled from 'styled-components';


const BudgetedLabel = styled(Statistic.Label)`
  text-align: right !important;
`;

const DescriptionSum = ({ item: { actual: { sum_usd: sum }, budgeted: { sum_usd: sum_budgeted } }}) => {

  return (
    <Statistic size="tiny">
      <Statistic.Value>{ formatSumUsd(sum) }</Statistic.Value>
      <BudgetedLabel>
        <Popup 
          content="Budgeted"
          position="bottom center"
          trigger={ <span>{ formatSumUsd(sum_budgeted) }</span> }
        />
      </BudgetedLabel>
    </Statistic>
  )
}

export default (DescriptionSum);
