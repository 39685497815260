import PropTypes from "prop-types"
import React, {useCallback, useState, useMemo, useEffect} from "react";

import {Popup, Icon} from 'semantic-ui-react';
import Content from "features/components/linked-documents-popup-content/PopupContent";

const LinkedInvoices = ({purchaseOrderItem = {id: null}, loadLinkedInvoices, data, poiId, unlinkInvoice, hasAttributes = false}) => {

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const trigPopup = useCallback(() => {
    setShow(!show)
  }, [show, setShow]);

  const hasInvoices = useMemo(() => {
    if (!purchaseOrderItem.id) return false;
    return hasAttributes ? purchaseOrderItem.attributes.has_invoices : purchaseOrderItem.hasInvoices;
  }, [purchaseOrderItem, hasAttributes]);

  const trigger = useMemo(() => {
    return hasInvoices ?
      <span onClick={trigPopup} className="popup-linked-documents-trigger">✔</span> :
      '';
  }, [trigPopup, hasInvoices]);

  useEffect(() => {
    if (show && purchaseOrderItem.id) {
      setLoading(true);
      loadLinkedInvoices({
        poiId: purchaseOrderItem.id,
        setLoading
      });
    }
  }, [show, purchaseOrderItem.id])

  useEffect(() => {
    if (poiId && poiId !== purchaseOrderItem.id) {
      setShow(false);
    }
  }, [poiId, purchaseOrderItem.id])

  return (
    <Popup
      className="popup-linked-documents"
      trigger={trigger}
      open={show}
    >
      <Popup.Header style={{textAlign: 'right'}}>
        <Icon name="close" onClick={trigPopup}/>
      </Popup.Header>
      <Popup.Content>
        <Content
          loading={loading}
          trigPopup={trigPopup}
          data={data}
          setLoading={setLoading}
          unlinkRow={unlinkInvoice}
          itemId={purchaseOrderItem.id}
        />
      </Popup.Content>
    </Popup>
  )
}

LinkedInvoices.propTypes = {
  data: PropTypes.any,
  hasAttributes: PropTypes.bool,
  loadLinkedInvoices: PropTypes.func,
  poiId: PropTypes.any,
  purchaseOrderItem: PropTypes.shape({
    attributes: PropTypes.shape({
      has_invoices: PropTypes.any
    }),
    hasInvoices: PropTypes.any,
    id: PropTypes.any
  }),
  unlinkInvoice: PropTypes.any
}

export default LinkedInvoices;
