import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';


const type = 'api_resources/employee_bank_accounts';

const sortOptions = (sort) => isEmpty(sort.field) ?
  '-period' :
  `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  show: (id) => api.read({ type, id }),

  index: (filter) => api.read({ type }, {
    query: { filter: { ...filter, }, }
  }),

  create: (attributes) => api.create({ type }, {}, {
    data: {
      type: 'employee_bank_accounts',
      attributes
    }
  }),

  update: (id, attributes) => api.update({ id, type }, {}, attributes),

  delete: (id) => api.destroy({ id, type }),

}
