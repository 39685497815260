import isEmpty from 'lodash/isEmpty';
import * as api from '../api/adapters/json_api';
import get from 'lodash/get'

const type = 'invoices'
const path = `api_resources/${type}`;

const sortOptions = (sort) => (!sort || isEmpty(sort.field)) ?
  '-issued_at' :
  `${ sort.direction === 'asc' ? '' : '-' }${ sort.field }`;

const searchOptions = (search) => isEmpty(search) ? null : { search };

export default {
  /**
   * getPaymentInstruction
   * @returns {Promise<*>}
   */
  show: (id) => api.read({ type, id }, {}),

  indexCommon: (pagination, sort, filter) => api.read({ type: path }, {
  query: {
    sort: sortOptions(sort),
    page: { size: pagination.itemsPerPage, number: pagination.activePage },
    filter: filter
  }
}),


  index: (pagination, sort, search, filter, settings, options = {fields: null}) => {
    return api.read({ type: path }, {
      query: {
        sort: sortOptions(sort),
        page: { size: pagination.itemsPerPage, number: pagination.activePage },
        filter: {
          ...filter,
          ...searchOptions(search),
          year: get(settings, 'invoices.budgetYear'),
          period_type: get(settings, 'invoices.periodType'),
          company_id: filter.company_id || (get(settings, 'global.invCompanies') || []).join(','),
          direction: get(settings, 'invoices.direction')
        },
        fields: options.fields
      }
    })
  },
  /**
   * createPaymentInstruction
   * @param attributes
   * @returns {Promise<*>}
   */
  create: (attributes) => api.create(
    { type: path },
    {},
    {
      data: {
        type,
        attributes
      }
    }),
  /**
   * updatePaymentInstruction
   * @param id
   * @param attributes
   * @returns {Promise<*>}
   */
  update: (id, attributes) => api.update(
    { id, type: path },
    {},
    {
      data: {
        id,
        type,
        attributes
      }
    }),
  /**
   * deletePaymentInstruction
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: path }),
}
