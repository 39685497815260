import store from 'components/App/Context/store';
import client from 'apiClient';
import { me } from '../constants/queries/users';
import get from 'lodash/get';

/**
 * Checks if the user has access to payroll based on their email address.
 * @param {Object} user - The user object to check.
 * @param {string} user.email - The email address of the user.
 * @returns {boolean} - Returns true if the user has access to payroll, false otherwise.
 */
export const canAccessToPayroll = (user) => {
  return user &&
    [
      'tatyana.garnyk@scglobal.group', 'olga.gruzdeva@scglobal.group',
      "stanislav.sergeychik@scglobal.group", "yuri.valigursky@gmail.com",
      "alexander@kosachov.com", 'yuriy.krylov@scglobal.group',
      'larysa.sikirytska@scglobal.group', 'maksym.piatkivskyi@scglobal.group',
      'nataliia.perekhrest@scglobal.group', 'kateryna.valihurska@softo.net'
    ].includes(get(user, 'email'));
}

export class CurrentUser {
  constructor() {
    this.user = store.user;
    this.role = get(this.user, 'role');
    this.setRemoteUser();
  }

  setRemoteUser() {
    client.query({ query: me })
      .then((response) => {
        this.user = response.data.me;
        this.role = get(this.user, 'role');
        store.user = this.user;
      })
      .catch(() => {
        this.user = undefined;
        this.role = undefined;
      });
  }

  isViewer() {
    return this.role === undefined || this.role === 'viewer' || this.role === 'auditor';
  }

  isAdmin() {
    return this.role === 'admin' || this.role === 'fin_admin';
  }

  isFullAdmin() {
    return this.role === 'admin';
  }

  canDelete() {
    return get(this.user, 'can_delete_models');
  }

  canDeletePO(resource) {
    if (resource !== 'purchase_orders') return false;
    return !this.isViewer();
  }

  canAccessToPayroll() {
    return canAccessToPayroll(this.user);
  }

}
